import Vue from "vue";

/**
 * @desc transform a string or number into currency format
 * @param value the string or number to be formated
 * @param currency the currency to be used in the format, see Intl documentation for more information
 */
const money = function (val, currency, digits) {
  let valueFormated = "";

  if (val === "") {
    return 0;
  }

  switch (currency) {
    case "MXN":
      if (!val) return "$0";

      valueFormated = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }).format(val);
      break;
    default:
      break;
  }
  return valueFormated;
};

Vue.filter("money", money);

export default {
  money,
};
