import axios from "axios";
import { returnAxiosHeaders } from "./fns";

async function saveAction(
  context,
  payload,
  url,
  saveCommand,
  extraParameters = null
) {
  try {
    context.commit("SET_LOADING", true, { root: true });
    // Deep clone of payload
    const payloadObject = JSON.parse(JSON.stringify(payload));
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };
    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    if (response.status === 200) {
      payloadObject._id = response.data;
      payloadObject.context = context;
      context.commit(saveCommand, payloadObject);
      context.commit("SET_LOADING", false, { root: true });
      return "Success";
    } else {
      return response.data;
    }
  } catch (error) {
    context.commit("SET_LOADING", false);
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return error;
  }
}

async function saveActionComplete(
  context,
  payload,
  url,
  saveCommand,
  extraParameters = null
) {
  try {
    context.commit("SET_LOADING", true);
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };

    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    if (response.status === 200) {
      context.commit(saveCommand, response.data);
      context.commit("SET_LOADING", false);
      return "Success";
    } else {
      return response.data;
    }
  } catch (error) {
    context.commit("SET_LOADING", false);
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return error;
  }
}

async function saveActionCompleteResponse(
  context,
  payload,
  url,
  saveCommand,
  extraParameters = null
) {
  try {
    context.commit("SET_LOADING", true);
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };

    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    if (response.status === 200) {
      payload.context = context;
      context.commit(saveCommand, response.data);
      context.commit("SET_LOADING", false);
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    context.commit("SET_LOADING", false);
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return error;
  }
}

async function saveActionCompleteResponseContext(
  context,
  payload,
  url,
  saveCommand,
  extraParameters = null
) {
  try {
    context.commit("SET_LOADING", true);
    // Deep clone of payload
    const payloadObject = JSON.parse(JSON.stringify(payload));
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };

    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    if (response.status === 200) {
      payloadObject._id = response.data;
      payloadObject.context = context;
      context.commit(saveCommand, payloadObject);
      context.commit("SET_LOADING", false);
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    context.commit("SET_LOADING", false);
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return error;
  }
}

async function getAction(context, url, saveCommand, extraParameters = null) {
  try {
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let payload = {
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };
    if (extraParameters) {
      payload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      payload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    if (response.status === 200) {
      context.commit(saveCommand, response.data);
      return "Success";
    } else {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    console.log(error);

    if (error.response) return error.response.data;
    return "Error";
  }
}

async function getActionLoading(
  context,
  url,
  saveCommand,
  extraParameters = null
) {
  try {
    context.commit("SET_LOADING", true);
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let payload = {
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };
    if (extraParameters) {
      payload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      payload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    if (response.status === 200) {
      context.commit("SET_LOADING", false);
      context.commit(saveCommand, response.data);
      return "Success";
    } else {
      return response.data;
    }
  } catch (error) {
    context.commit("SET_LOADING", false);
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return "Error";
  }
}

async function genericAction(context, payload, url, extraParameters = null) {
  try {
    context.commit("SET_LOADING", true);
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };
    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    if (response.status === 200) {
      context.commit("SET_LOADING", false);
      return "Success";
    } else {
      return response.data;
    }
  } catch (error) {
    context.commit("SET_LOADING", false);
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return "Error";
  }
}

async function genericActionNoLoading(
  context,
  payload,
  url,
  extraParameters = null
) {
  try {
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };
    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    if (response.status === 200) {
      return "Success";
    } else {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return "Error";
  }
}

async function genericActionNoMutation(
  context,
  payload,
  url,
  extraParameters = null,
  logoutFunction = false
) {
  try {
    context.commit("SET_LOADING", true);
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };
    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    context.commit("SET_LOADING", false);
    if (response.status === 200) {
      context.commit("SET_LOADING", false);
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    context.commit("SET_LOADING", false);
    if (error.response && error.response.data === "Logout" && !logoutFunction) {
      await context.dispatch("LOGOUT");
      await context.dispatch("LOGOUT_DATABASE");
      return;
    }
    return error;
  }
}

async function genericActionNoMutationNoLoading(
  context,
  payload,
  url,
  extraParameters = null
) {
  try {
    /**
     * Set all the required information in the payload of the call
     * so the security token can be validated
     */
    let savePayload = {
      objectSave: payload,
      companyMainName: context.rootState.Main.user.companyMainName,
      email: context.rootState.Main.user.email,
    };
    if (extraParameters) {
      savePayload.extraParameters = extraParameters;
    }
    let response = await axios.post(
      url,
      savePayload,
      returnAxiosHeaders(context.rootState.Main.user.token)
    );
    /**
     * Check status of the response and save data on success
     */
    if (response.status === 200) {
      return response.data;
    } else {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.data === "Logout") {
      await context.dispatch("LOGOUT_DATABASE");
      await context.dispatch("LOGOUT");
      return;
    }
    if (error.response) return error.response.data;
    return "Error";
  }
}

export {
  saveAction,
  getAction,
  genericAction,
  saveActionComplete,
  saveActionCompleteResponse,
  saveActionCompleteResponseContext,
  genericActionNoMutation,
  genericActionNoLoading,
  genericActionNoMutationNoLoading,
  getActionLoading,
};
