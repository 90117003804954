let mutations = {
  RESTORE_ACCOUNTING_STATES: (state) => {
    Object.assign(state, {
      incomeDocuments: [],
      paymentDocuments: [],
      salaryDocuments: [],
      expenseDocuments: [],
      transferDocuments: [],
      invoices: [],
      creditNotesInvoices: [],
      paymentInvoices: [],
      porteCards: [],
      weightUnits: [],
      dangerousMaterials: [],
      packagings: [],
      transportationPermissions: [],
      transportationConfiguration: [],
      trailerTypes: [],
      dailyInvoices: [],
      salesXMLDifferences: [],
    });
  },
  SYNCHDOCUMENTS: (state, payload) => {
    let currentIncomeDocuments = [...state.incomeDocuments];
    let currentPaymentDocuments = [...state.paymentDocuments];
    let currentSalaryDocuments = [...state.salaryDocuments];
    let currentExpenseDocuments = [...state.expenseDocuments];
    let currentTransferDocument = [...state.transferDocuments];

    for (const singleDocument of payload.documents) {
      if (singleDocument.TIPO_DOCUMENTO === "Ingreso") {
        currentIncomeDocuments.push(singleDocument);
      }

      if (singleDocument.TIPO_DOCUMENTO === "Egreso") {
        currentExpenseDocuments.push(singleDocument);
      }

      if (singleDocument.TIPO_DOCUMENTO === "Nómina") {
        currentSalaryDocuments.push(singleDocument);
      }

      if (singleDocument.TIPO_DOCUMENTO === "Pago") {
        currentPaymentDocuments.push(singleDocument);
      }

      if (singleDocument.TIPO_DOCUMENTO === "Traslado") {
        currentTransferDocument.push(singleDocument);
      }
    }

    state.incomeDocuments = currentIncomeDocuments;
    state.paymentDocuments = currentPaymentDocuments;
    state.salaryDocuments = currentSalaryDocuments;
    state.expenseDocuments = currentExpenseDocuments;
    state.transferDocuments = currentTransferDocument;
  },
  SAVE_INVOICE: (state, payload) => {
    const currentInvoices = [...state.invoices];
    const currentSales = [...payload.context.rootState.Sales.sales];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };
    let stampsToRest = 0;

    for (const singleInvoice of payload._id.invoices) {
      if (singleInvoice) {
        currentInvoices.push(singleInvoice.invoice);
        stampsToRest += 1;
      }
    }

    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id === payload._id.saleId) {
        for (const singleInvoice of payload._id.invoices) {
          if (singleInvoice && singleInvoice.success)
            currentSales[currentSaleIndex].FACTURAS.push(
              singleInvoice.data.invoice
            );
        }
        if (stampsToRest > 0) {
          currentSales[currentSaleIndex].STATUS_FAC = "TI";
        }
      }
    }

    // Decrease one stamp for each invoice
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= stampsToRest;
    } else {
      companyInformation.availableStamps -= stampsToRest;
    }

    payload.context.rootState.Sales.sales = currentSales;
    state.invoices = currentInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SAVE_INVOICE_MATERIAL_DELIVERY: (state, payload) => {
    const currentInvoices = [...state.invoices];
    const currentSales = [...payload.context.rootState.Sales.sales];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };
    let stampsToRest = 0;

    if (payload._id.invoice) {
      currentInvoices.push(payload._id.invoice);
      stampsToRest += 1;
    }

    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id.toString() === payload._id.saleId.toString()) {
        if (payload._id.invoice)
          currentSales[currentSaleIndex].FACTURAS.push(payload._id.invoice);

        if (stampsToRest > 0) {
          currentSales[currentSaleIndex].STATUS_FAC = "PF";
        }
      }

      for (const [
        singleDeliveryIndex,
        singleDelivery,
      ] of currentSale.ENVIOS_MATERIAL.entries()) {
        if (
          singleDelivery._id.toString() === payload._id.deliveryId.toString()
        ) {
          currentSales[currentSaleIndex].ENVIOS_MATERIAL[
            singleDeliveryIndex
          ].FACTURA = payload._id.invoice;
        }
      }
    }

    // Decrease one stamp for each invoice
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= stampsToRest;
    } else {
      companyInformation.availableStamps -= stampsToRest;
    }

    payload.context.rootState.Sales.sales = currentSales;
    state.invoices = currentInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SAVE_PROFESSIONAL_SERVICES_INVOICE: (state, payload) => {
    const currentInvoices = [...state.invoices];
    const currentSales = [
      ...payload.context.rootState.Sales.porofesionalServicesSales,
    ];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };
    let stampsToRest = 0;

    for (const singleInvoice of payload._id.invoices) {
      if (singleInvoice) {
        currentInvoices.push(singleInvoice.invoice);
        stampsToRest += 1;
      }
    }

    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id === payload._id.saleId) {
        for (const singleInvoice of payload._id.invoices) {
          if (singleInvoice && singleInvoice.success)
            currentSales[currentSaleIndex].FACTURAS.push(
              singleInvoice.data.invoice
            );
        }
        if (stampsToRest > 0) {
          currentSales[currentSaleIndex].STATUS_FAC = "TI";
        }
      }
    }

    // Decrease one stamp for each invoice
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= stampsToRest;
    } else {
      companyInformation.availableStamps -= stampsToRest;
    }

    payload.context.rootState.Sales.porofesionalServicesSales = currentSales;
    state.invoices = currentInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SAVE_INVOICE_CREDIT_NOTE: (state, payload) => {
    const creditNotesInvoices = [...state.creditNotesInvoices];
    const currentSales = [...payload.context.rootState.Sales.sales];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    if (payload._id.creditNoteInvoice) {
      creditNotesInvoices.push(payload._id.creditNoteInvoice);

      for (const singleSale of currentSales) {
        if (singleSale._id === payload._id.sale) {
          for (const singleInvoice of singleSale.FACTURAS) {
            if (singleInvoice._id === payload._id.invoice) {
              for (const singleCreditNote of singleInvoice.NOTAC) {
                if (singleCreditNote._id === payload._id.creditNote) {
                  singleCreditNote.STATUS_FAC = "TI";
                }
              }
            }
          }
        }
      }

      // Decrease one stamp
      if (companyInformation.availableMonthlyStamps > 0) {
        companyInformation.availableMonthlyStamps -= 1;
      } else {
        companyInformation.availableStamps -= 1;
      }
    }

    payload.context.rootState.Sales.sales = currentSales;
    state.creditNotesInvoices = creditNotesInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SAVE_GLOBAL_INVOICE_CREDIT_NOTE: (state, payload) => {
    const creditNotesInvoices = [...state.creditNotesInvoices];
    const currentDailyInvoices = [...state.dailyInvoices];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    if (payload._id.creditNoteInvoice) {
      creditNotesInvoices.push(payload._id.creditNoteInvoice);

      for (const [
        singleDailyInvoiceIndex,
        singleDailyInvoice,
      ] of currentDailyInvoices.entries()) {
        if (singleDailyInvoice._id === payload._id.invoice) {
          for (const [
            singleCreditNoteIndex,
            singleCreditNote,
          ] of singleDailyInvoice.NOTAC.entries()) {
            if (singleCreditNote._id === payload._id.creditNote) {
              currentDailyInvoices[singleDailyInvoiceIndex].NOTAC[
                singleCreditNoteIndex
              ].STATUS_FAC = "TI";
            }
          }
        }
      }

      // Decrease one stamp
      if (companyInformation.availableMonthlyStamps > 0) {
        companyInformation.availableMonthlyStamps -= 1;
      } else {
        companyInformation.availableStamps -= 1;
      }
    }

    state.dailyInvoices = currentDailyInvoices;
    state.creditNotesInvoices = creditNotesInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SAVE_INVOICE_PAYMENT: (state, payload) => {
    const currentPaymentInvoices = [...state.paymentInvoices];
    const currentSales = [...payload.context.rootState.Sales.sales];
    const currentProfessionalServicesSales = [
      ...payload.context.rootState.Sales.porofesionalServicesSales,
    ];
    const currentIncomes = [...payload.context.rootState.Treasury.incomes];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };
    const currentAdvancedPayments = [
      ...payload.context.rootState.Treasury.advancedPayments,
    ];

    currentPaymentInvoices.push(payload._id.invoiceId);

    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id === payload._id.saleId) {
        currentSales[currentSaleIndex].N_PACRIALIDAD += 1;
        break;
      }
    }

    for (const [singleIncomeIndex, singleIncome] of currentIncomes.entries()) {
      if (
        (singleIncome.CLAVE_VENTA &&
          singleIncome.CLAVE_VENTA._id === payload._id.saleId) ||
        (singleIncome.CLAVE_VENTA_SERVICIO &&
          singleIncome.CLAVE_VENTA_SERVICIO._id === payload._id.saleId)
      ) {
        for (const [
          singlePaymentIndex,
          singlePayment,
        ] of singleIncome.PAGOS.entries()) {
          if (singlePayment._id === payload._id.paymentId) {
            currentIncomes[singleIncomeIndex].PAGOS[
              singlePaymentIndex
            ].STATUS_FAC = "TI";

            currentIncomes[singleIncomeIndex].PAGOS[
              singlePaymentIndex
            ].PAGO_CFDI = payload._id.invoice;
          }
        }
        break;
      }
    }

    for (const singleAdvancedPayment of currentAdvancedPayments) {
      if (singleAdvancedPayment._id === payload._id.paymentId) {
        singleAdvancedPayment.PAGO_CFDI = payload._id.invoice;
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Treasury.incomes = currentIncomes;
    payload.context.rootState.Sales.sales = currentSales;
    payload.context.rootState.Sales.porofesionalServicesSales =
      currentProfessionalServicesSales;
    state.currentPaymentInvoices = currentPaymentInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SAVE_INVOICE_ADVANCED_PAYMENT: (state, payload) => {
    const currentPaymentInvoices = [...state.paymentInvoices];
    const currentAdvancedPayments = [
      ...payload.context.rootState.Treasury.advancedPayments,
    ];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    currentPaymentInvoices.push(payload._id.invoiceId);

    for (const [
      singlePaymentIndex,
      singlePayment,
    ] of currentAdvancedPayments.entries()) {
      if (singlePayment._id === payload._id.paymentId) {
        currentAdvancedPayments[singlePaymentIndex].STATUS_FAC = "TI";
        break;
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Treasury.advancedPayments =
      currentAdvancedPayments;
    state.currentPaymentInvoices = currentPaymentInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SAVE_PORTE_CARD: (state, payload) => {
    const currentPorteCards = [...state.porteCards];
    const currentSales = [...payload.context.rootState.Sales.sales];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    currentPorteCards.push(payload._id.invoice);

    for (const singleSale of currentSales) {
      if (singleSale._id === payload._id.sale) {
        for (const singleInvoice of singleSale.FACTURAS) {
          if (singleInvoice._id === payload._id.invoice) {
            singleInvoice.CARTAS_PORTE.push(payload._id.porteCardInvoice);
          }
        }
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Sales.sales = currentSales;
    state.porteCards = currentPorteCards;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_INVOICE: (state, payload) => {
    const currentSales = [...payload.context.rootState.Sales.sales];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    // Cancel incomes
    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id === payload._id.sale) {
        currentSales[currentSaleIndex].STATUS_FAC = "CA";
        for (const [
          currentInvoiceIndex,
          currentInvoice,
        ] of currentSale.FACTURAS.entries()) {
          if (currentInvoice._id === payload._id.invoice) {
            currentSales[currentSaleIndex].FACTURAS[
              currentInvoiceIndex
            ].STATUS = "CA";
          }
        }
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Sales.sales = currentSales;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_PROFESSIONAL_SERVICES_INVOICE: (state, payload) => {
    const currentSales = [
      ...payload.context.rootState.Sales.porofesionalServicesSales,
    ];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    // Cancel incomes
    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id === payload._id.sale) {
        currentSales[currentSaleIndex].STATUS_FAC = "CA";
        for (const [
          currentInvoiceIndex,
          currentInvoice,
        ] of currentSale.FACTURAS.entries()) {
          if (currentInvoice._id === payload._id.invoice) {
            currentSales[currentSaleIndex].FACTURAS[
              currentInvoiceIndex
            ].STATUS = "CA";
          }
        }
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Sales.porofesionalServicesSales = currentSales;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_DAILY_INVOICE: (state, payload) => {
    const currentSales = [...payload.context.rootState.Sales.sales];
    const currentDailyInvoices = [...state.dailyInvoices];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    // Cancel daily invoice
    for (const [
      singleDailyInvoiceIndex,
      singleDailyInvoice,
    ] of currentDailyInvoices.entries()) {
      if (singleDailyInvoice._id === payload._id.invoice._id) {
        currentDailyInvoices[singleDailyInvoiceIndex].STATUS = "CA";
        for (const [
          singleSaleIndex,
          singleSale,
        ] of singleDailyInvoice.FAC_DET.entries()) {
          currentDailyInvoices[singleDailyInvoiceIndex].FAC_DET[
            singleSaleIndex
          ].CLAVE_VENTA.STATUS_FAC = "SF";
        }
      }
    }

    // Change sale invoice status
    for (const singleDetail of payload._id.invoice.FAC_DET) {
      for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
        if (currentSale._id === singleDetail.CLAVE_VENTA._id) {
          currentSales[currentSaleIndex].STATUS_FAC = "SF";
        }
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Sales.sales = currentSales;
    state.dailyInvoices = currentDailyInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_CREDIT_NOTE: (state, payload) => {
    const currentSales = [...payload.context.rootState.Sales.sales];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id === payload._id.sale) {
        for (const [
          singleCreditNoteIndex,
          singleCreditNote,
        ] of currentSale.NOTAC.entries()) {
          if (singleCreditNote._id === payload._id.creditNote) {
            currentSales[currentSaleIndex].NOTAC[
              singleCreditNoteIndex
            ].STATUS_FAC = "CA";
          }
        }
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Sales.sales = currentSales;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_PAYMENT: (state, payload) => {
    const currentSales = [...payload.context.rootState.Sales.sales];
    const currentIncomes = [...payload.context.rootState.Treasury.incomes];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    for (const [currentSaleIndex, currentSale] of currentSales.entries()) {
      if (currentSale._id === payload._id.sale) {
        currentSales[currentSaleIndex].N_PACRIALIDAD -= 1;
        break;
      }
    }

    for (const [singleIncomeIndex, singleIncome] of currentIncomes.entries()) {
      if (
        singleIncome.CLAVE_VENTA &&
        singleIncome.CLAVE_VENTA._id === payload._id.sale
      ) {
        for (const [
          singlePaymentIndex,
          singlePayment,
        ] of singleIncome.PAGOS.entries()) {
          if (singlePayment._id === payload._id.payment) {
            currentIncomes[singleIncomeIndex].PAGOS[
              singlePaymentIndex
            ].STATUS_FAC = "CA";
          }
        }
        break;
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Treasury.incomes = currentIncomes;
    payload.context.rootState.Sales.sales = currentSales;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_ADVANCED_PAYMENT: (state, payload) => {
    const currentAdvancedPayments = [
      ...payload.context.rootState.Treasury.advancedPayments,
    ];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    for (const [
      singlePaymentIndex,
      singlePayment,
    ] of currentAdvancedPayments.entries()) {
      if (singlePayment._id === payload._id.payment) {
        currentAdvancedPayments[singlePaymentIndex].STATUS_FAC = "CA";
        break;
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Treasury.advancedPayments =
      currentAdvancedPayments;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_PORTE_CARD: (state, payload) => {
    const currentSales = [...payload.context.rootState.Sales.sales];
    const currentPorteCards = [...state.porteCards];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    if (payload._id.sale) {
      for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
        if (singleSale._id === payload._id.sale._id) {
          currentSales[singleSaleIndex] = payload._id.sale;
        }
      }
    } else {
      for (const [
        singlePorteCardIndex,
        singlePorteCard,
      ] of currentPorteCards.entries()) {
        if (singlePorteCard._id === payload._id.porteCardInformation._id) {
          currentPorteCards[singlePorteCardIndex] =
            payload._id.porteCardInformation;
        }
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    state.porteCards = currentPorteCards;
    payload.context.rootState.Sales.sales = currentSales;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SET_WEIGHT_UNITS: (state, payload) => {
    state.weightUnits = payload;
  },
  SET_PACKAGINGS: (state, payload) => {
    state.packagings = payload;
  },
  SET_TRANSPORTATION_PERMISSIONS: (state, payload) => {
    state.transportationPermissions = payload;
  },
  SET_DANGEROUS_MATERIALS: (state, payload) => {
    state.dangerousMaterials = payload;
  },
  SET_TRASNPORTATION_CONFIGURATION: (state, payload) => {
    state.transportationConfiguration = payload;
  },
  SET_TRAILER_TYPE: (state, payload) => {
    state.trailerTypes = payload;
  },
  SET_DAILY_INVOICES: (state, payload) => {
    state.dailyInvoices = payload;
  },
  SAVE_DAILY_INVOICE: (state, payload) => {
    const currentSales = [...payload.context.rootState.Sales.sales];
    const currentDailyInvoices = [...state.dailyInvoices];

    currentDailyInvoices.push(payload._id.invoice);
    for (const singleInvoiceSale of payload._id.invoice.FAC_DET) {
      for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
        if (singleSale._id === singleInvoiceSale.CLAVE_VENTA._id) {
          currentSales[singleSaleIndex].STATUS_FAC = "FD";
        }
      }
    }

    state.dailyInvoices = currentDailyInvoices;
    payload.context.rootState.Sales.sales = currentSales;
  },
  SET_SALES_XML_DIFFERENCES: (state, payload) => {
    state.salesXMLDifferences = payload;
  },
  SET_PORTE_CARDS: (state, payload) => {
    state.porteCards = payload;
  },
  SAVE_PORTE_CARD_NO_SALE: (state, payload) => {
    const currentPorteCards = [...state.porteCards];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    currentPorteCards.push(payload._id.porteCardInvoice);

    // Decrease one stamp for each invoice
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    state.porteCards = currentPorteCards;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SET_CONTABLE_ACCOUNTS: (state, payload) => {
    state.contableAccounts = payload;
  },
  EDIT_CONTABLE_ACCOUNTS: (state, payload) => {
    const currentContableAccounts = [...state.contableAccounts];

    for (const [
      singleContableAccountIndex,
      singleContableAccount,
    ] of currentContableAccounts.entries()) {
      if (singleContableAccount._id === payload._id._id) {
        currentContableAccounts[singleContableAccountIndex] = payload._id;
      }
    }

    state.contableAccounts = currentContableAccounts;
  },
  SAVE_CONTABLE_ACCOUNT: (state, payload) => {
    state.contableAccounts = payload._id;
  },
  IMPORT_CONTABLE_ACCOUNTS: (state, payload) => {},
  SET_CONTABLE_GUIDELINES: (state, payload) => {
    state.contableGuidelines = payload;
  },
  EDIT_CONTABLE_GUIDELINES: (state, payload) => {
    const currentContableGuidelines = [...state.contableGuidelines];

    for (const [
      singleContableGuidelineIndex,
      singleContableGuideline,
    ] of currentContableGuidelines.entries()) {
      if (singleContableGuideline._id === payload._id._id) {
        currentContableGuidelines[singleContableGuidelineIndex] = payload._id;
      }
    }

    state.contableGuidelines = currentContableGuidelines;
  },
};

export default mutations;
