let state = {
  banks: [],
  bankMovementTypes: [],
  bankAccounts: [],
  // Total balance
  totalBalance: 0,
  // Consolidated total balance
  totalBalanceCon: 0,
};

export default state;
