import axios from "axios";
import { returnAxiosHeaders } from "../../../utils/fns";
import { serverUrl } from "../../../utils/env-variables-fns";

let actions = {
  //Boards module
  GETTASKBOARDS: (context) => {
    return new Promise((resolve) => {
      let payload = {
        companyMainName: context.rootState.Main.user.companyMainName,
        email: context.rootState.Main.user.email,
      };
      context.commit("SET_LOADING", true);
      axios
        .post(
          `${serverUrl}/boards/get-task-boards`,
          payload,
          returnAxiosHeaders(context.rootState.Main.user.token)
        )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SETTASKBOARDS", response.data);
            context.commit("SET_LOADING", false);
            resolve("Success");
          } else {
            resolve("Error");
          }
        })
        .catch((e) => {
          context.commit("SET_LOADING", false);
          resolve("Error");
        });
    }).catch((err) => {
      context.commit("SET_LOADING", false);
    });
  },

  SAVETASKBOARDS: (context, payload) => {
    return new Promise((resolve) => {
      payload.companyMainName = context.rootState.Main.user.companyMainName;
      payload.email = context.rootState.Main.user.email;
      context.commit("SET_LOADING", true);
      axios
        .post(
          `${serverUrl}/boards/add-task-boards`,
          payload,
          returnAxiosHeaders(context.rootState.Main.user.token)
        )
        .then((response) => {
          if (response.status == 200) {
            payload._id = response.data;
            (payload.stages = [
              "Sin estado",
              "Pendientes",
              "En progreso",
              "Finalizados",
            ]),
              (payload.blocks = []);
            context.commit("ADDTASKBOARDS", payload);
            context.commit("SET_LOADING", false);
            resolve("Success");
          } else {
            resolve("Error");
          }
        })
        .catch((e) => {
          console.log(e);
          context.commit("SET_LOADING", false);
          resolve("Error");
        });
    }).catch((err) => {
      console.log(err);
      context.commit("SET_LOADING", false);
    });
  },

  EDITTASKBOARDS: (context, payload) => {
    return new Promise((resolve) => {
      payload.companyMainName = context.rootState.Main.user.companyMainName;
      payload.email = context.rootState.Main.user.email;
      context.commit("SET_LOADING", true);
      axios
        .post(
          `${serverUrl}/boards/edit-task-boards`,
          payload,
          returnAxiosHeaders(context.rootState.Main.user.token)
        )
        .then((response) => {
          if (response.status == 200) {
            let tempTaskBoards = context.getters.TASKBOARDS;
            tempTaskBoards.forEach((board, i) => {
              if (board._id == payload._id) {
                tempTaskBoards[i] = payload;
              }
            });
            context.commit("SETTASKBOARDS", tempTaskBoards);
            context.commit("SET_LOADING", false);
            resolve("Success");
          } else {
            resolve("Error");
          }
        })
        .catch((e) => {
          console.log(e);
          context.commit("SET_LOADING", false);
          resolve("Error");
        });
    }).catch((err) => {
      console.log(err);
      context.commit("SET_LOADING", false);
    });
  },
  //Boards module
};
export default actions;
