<template>
  <div id="app">
    <div class="loading-container" v-if="isLoading">
      <div class="loading-overlay-custom"></div>
      <ClipLoader color="#7957d5" size="5rem"></ClipLoader>
      <h2>{{ loadingMessage }}</h2>
    </div>

    <v-tour name="initialTour" :steps="steps" :callbacks="stepsCallback">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :step="tour.steps[tour.currentStep]"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :skip="tour.skip"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="stepsLabels"
          >
          </v-step>
        </transition>
      </template>
    </v-tour>
    <router-view />
  </div>
</template>

<script>
import moment from "moment";
import SecureLS from "secure-ls";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { initialSteps } from "./data/initial-steps";

export default {
  components: {
    ClipLoader,
  },
  data() {
    return {
      showingPaymentDialog: false,
      steps: initialSteps,
      stepsLabels: {
        buttonSkip: "Saltar tutorial",
        buttonPrevious: "Paso anterior",
        buttonNext: "Siguiente paso",
        buttonStop: "Terminar tutorial",
      },
      stepsCallback: {
        onNextStep: this.nextStep,
        onSkip: this.finishTutorial,
        onFinish: this.finishTutorial,
      },
      moment: moment,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.LOADING;
    },
    loadingMessage() {
      return this.$store.getters.LOADINGMESSAGE;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  watch: {
    companyInformation() {
      if (this.companyInformation.name) {
        this.showNextPaymentDateDialog();
      }
    },
    async isIdle() {
      if (this.isIdle && this.companyInformation) {
        const allModals = document.querySelectorAll(".modal");
        for (const singleModal of allModals) {
          singleModal.__vue__.$vnode.context.close();
        }
        this.$store.dispatch("LOGOUT_DATABASE");
        this.$store.dispatch("LOGOUT");
        this.$router.push({ path: "/Login" }).catch(() => {});
      }
    },
  },
  async mounted() {
    //Get initial data from the store, only for super admin users
    let currentUserRespond = await this.$store.dispatch("CHECKUSER");
    if (currentUserRespond === "Success") {
      await this.$store.dispatch("GETCOMPANYINFORMATION");
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
      allPromises.push(this.$store.dispatch("GETARTICLES"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(
        this.$store.dispatch("GETPOSNOSALES", {
          warehouses: [],
        })
      );
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETUSERS"));
      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETLASTEXPENSENUMBER"));
      allPromises.push(this.$store.dispatch("GETLASTWAREHOUSETRANSFERNUMBER"));
      allPromises.push(this.$store.dispatch("GETCOMPANYEMAILS"));
      allPromises.push(this.$store.dispatch("GETLASTPAYMENTNUMBER"));
      allPromises.push(this.$store.dispatch("GETLASTSALENUMBER"));
      allPromises.push(this.$store.dispatch("GETUSERNOTIFICATIONS"));
      allPromises.push(this.$store.dispatch("GETCATEGORIES"));

      Promise.all(allPromises);
    }
  },
  created() {},
  methods: {
    nextStep(currentStep) {
      if (currentStep === 3) {
        this.$router.push({ path: "/General/Warehouses" }).catch(() => {});
        this.$store.dispatch(
          "CHANGENEWHINT",
          "En esta pantalla podrás controlar los almacenes, recuerda que puedes agregar distintos tipos de almacenes como por ejemplo uno virtual"
        );
      }
    },
    async finishTutorial() {
      const response = await this.$store.dispatch("COMPLETETUTORIAL", {});
      if (response === "Success") {
        const ls = new SecureLS({ isCompression: false });
        const currentUserInformation = JSON.parse(
          ls.get(this.userInformation.companyMainName + "ERPFCBUser")
        );
        ls.set(
          this.userInformation.companyMainName + "ERPFCBUser",
          JSON.stringify({ ...currentUserInformation, tourCompleted: true })
        );
      }
    },
    async showNextPaymentDateDialog() {
      // Get next payment date
      let response = await this.$store.dispatch("GETSUBSCRIPTIONEND", {});
      // Save next payment date in store
      if (response.endDate) {
        // Get today date
        const today = moment();
        // Format next payment date
        const formattedDate = moment
          .unix(response.endDate)
          .format("MM/DD/YYYY");
        this.$store.commit("SET_NEXT_PAYMENT_DATE", formattedDate);
        // Get the amount of days to next payment date
        const daysToPayment = moment(formattedDate).diff(today, "days");
        // Check if payment date is in the past
        const isInPast = moment(today).isAfter(formattedDate);
        const ls = new SecureLS({ isCompression: false });
        const modalAlreadyViewed = ls.get(
          this.userInformation.companyMainName + "ERPFCBModalViewed"
        );
        ls.set(
          this.userInformation.companyMainName + "ERPFCBModalViewed",
          true
        );
        if (
          daysToPayment <= 3 &&
          !isInPast &&
          !this.showingPaymentDialog &&
          !modalAlreadyViewed
        ) {
          this.showingPaymentDialog = true;
          this.$buefy.dialog.confirm({
            title: `Próxima fecha de pago en ${daysToPayment} días`,
            message: `Este mensaje es un recordatorio para avisar que su próxima fecha de pago en ${daysToPayment} días, también hemos enviado un correo a la cuenta del administrador con información más detallada`,
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
          });
        }
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
}

.loading-container h2 {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: #7957d5;
}

.loading-overlay-custom {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
}

.spinner.spinner--double-bounce {
  width: 5rem !important;
  height: 5rem !important;
}

.v-step {
  background: rgba(121, 87, 213, 1) !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.v-step strong {
  color: white !important;
  font-weight: 500 !important;
}

.v-step__button {
  border-radius: 4px !important;
  padding: 0.6rem !important;
  height: auto !important;
  font-size: 0.9rem !important;
  border-width: 1.5px !important;
  font-weight: 500 !important;
  margin-bottom: 0.6rem !important;
}

body {
  width: 100%;
  overflow-x: hidden;
}

.footer {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  position: static;
  bottom: 0;
}
</style>
