let mutations = {
  RESTORE_GLOBAL_STATES: (state) => {
    Object.assign(state, {
      categories: [],
      subCategories: [],
      subSubCategories: [],
      subSubSubCategories: [],
      brands: [],
      companiesEmails: [],
      permissions: [],
      users: [],
      vehicules: [],
      hint: "No olvides que hay campos de ayuda y recordatorios en el sistema, si tienes alguna duda no olvides consultar estas secciones",
      active: "",
      assets: [],
    });
  },
  SET_HINT: (state, payload) => {
    state.hint = payload;
  },
  SAVE_CATEGORY: (state, payload) => {
    const currentCategories = [...state.categories];
    currentCategories.push(payload._id);
    state.categories = currentCategories;
  },
  SET_CATEGORIES: (state, payload) => {
    state.categories = payload;
  },
  EDIT_CATEGORY: (state, payload) => {
    const currentCategories = [...state.categories];
    for (const [
      singleCategoryIndex,
      singleCategory,
    ] of currentCategories.entries()) {
      if (singleCategory._id === payload._id._id) {
        currentCategories[singleCategoryIndex] = payload._id;
      }
    }
    state.categories = currentCategories;
  },
  SAVE_SCATEGORY: (state, payload) => {
    const currentCategories = [...state.subCategories];
    currentCategories.push(payload._id);
    state.subCategories = currentCategories;
  },
  SET_SCATEGORIES: (state, payload) => {
    state.subCategories = payload;
  },
  SAVE_SSCATEGORY: (state, payload) => {
    const currentCategories = [...state.subSubCategories];
    currentCategories.push(payload._id);
    state.subSubCategories = currentCategories;
  },
  SET_SSCATEGORIES: (state, payload) => {
    state.subSubCategories = payload;
  },
  SAVE_SSSCATEGORY: (state, payload) => {
    const currentCategories = [...state.subSubSubCategories];
    currentCategories.push(payload._id);
    state.subSubSubCategories = currentCategories;
  },
  SET_SSSCATEGORIES: (state, payload) => {
    state.subSubSubCategories = payload;
  },
  EDIT_SCATEGORY: (state, payload) => {
    let currentCategories = [];
    // The type defines the level of subcategory to edit
    if (payload._id.type === 1) {
      currentCategories = [...state.subCategories];
    } else if (payload._id.type === 2) {
      currentCategories = [...state.subSubCategories];
    } else if (payload._id.type === 3) {
      currentCategories = [...state.subSubSubCategories];
    }

    for (const [
      singleCategoryIndex,
      singleCategory,
    ] of currentCategories.entries()) {
      if (singleCategory._id === payload._id.categoryInformation._id) {
        currentCategories[singleCategoryIndex] =
          payload._id.categoryInformation;
      }
    }
    // set correct level of subcategory
    if (payload._id.type === 1) {
      state.subCategories = currentCategories;
    } else if (payload._id.type === 2) {
      state.subSubCategories = currentCategories;
    } else if (payload._id.type === 3) {
      state.subSubSubCategories = currentCategories;
    }
  },
  SAVE_BRAND: (state, payload) => {
    const currentBrands = [...state.brands];
    currentBrands.push(payload._id);
    state.brands = currentBrands;
  },
  SET_BRANDS: (state, payload) => {
    state.brands = payload;
  },
  EDIT_BRAND: (state, payload) => {
    const currentBrands = [...state.brands];
    for (const [singleBrandIndex, singleBrand] of currentBrands.entries()) {
      if (singleBrand._id === payload._id._id) {
        currentBrands[singleBrandIndex] = payload._id;
      }
    }
    state.brands = currentBrands;
  },
  SET_COMPANIES_EMAILS: (state, payload) => {
    state.companiesEmails = payload;
  },
  SAVE_COMPANY_EMAIL: (state, payload) => {
    state.companiesEmails.push(payload._id);
  },
  DELETE_COMPANY_EMAIL: (state, payload) => {
    const currentEmail = [...state.companiesEmails];
    for (const singleDeletedEmail of payload._id) {
      for (const [singleEmailIndex, singleEmail] of currentEmail.entries()) {
        if (singleEmail._id === singleDeletedEmail) {
          currentEmail.splice(singleEmailIndex, 1);
          break;
        }
      }
    }
    state.companiesEmails = currentEmail;
  },
  SAVE_PERMISSIONS: (state, payload) => {
    state.permissions.push(payload._id);
  },
  SET_PERMISSIONS: (state, payload) => {
    state.permissions = payload;
  },
  UPDATE_PERMISSIONS: (state, payload) => {
    const currentPermissions = [...state.permissions];
    for (const [
      currentPermissionIndex,
      currentPermission,
    ] of currentPermissions.entries()) {
      // If current user is the one that was edited
      if (currentPermission._id === payload._id._id) {
        currentPermissions[currentPermissionIndex] = payload._id;
      }
    }
    state.permissions = currentPermissions;
  },
  DELETE_PERMISSIONS: (state, payload) => {
    const currentPermissions = [...state.permissions];
    for (const deleteObject of payload._id) {
      for (const [
        currentPermissionIndex,
        currentPermission,
      ] of currentPermissions.entries()) {
        // If current user is the one that was edited
        if (currentPermission._id === deleteObject) {
          currentPermissions.splice(currentPermissionIndex, 1);
        }
      }
    }
    state.permissions = currentPermissions;
  },
  SET_USERS: (state, payload) => {
    state.users = payload;
  },
  SAVE_USER: (state, payload) => {
    state.users.push(payload._id);
  },
  ACTIVATE_USER: (state, payload) => {
    const currentUsers = [...state.users];
    for (const [currentUserIndex, currentUser] of currentUsers.entries()) {
      // If current user is the one that was edited
      if (currentUser._id === payload._id) {
        currentUsers[currentUserIndex].STATUS = "AC";
      }
    }
    state.users = currentUsers;
  },
  DISABLE_USER: (state, payload) => {
    const currentUsers = [...state.users];
    for (const [currentUserIndex, currentUser] of currentUsers.entries()) {
      // If current user is the one that was edited
      if (currentUser._id === payload._id) {
        currentUsers[currentUserIndex].STATUS = "PE";
      }
    }
    state.users = currentUsers;
  },
  UPDATE_USER: (state, payload) => {
    const currentUsers = [...state.users];
    for (const [currentUserIndex, currentUser] of currentUsers.entries()) {
      // If current user is the one that was edited
      if (currentUser._id === payload._id._id) {
        currentUsers[currentUserIndex] = payload._id;
      }
    }
    state.users = currentUsers;
  },
  DELETE_USER: (state, payload) => {
    const currentUsers = [...state.users];
    for (const deleteObject of payload._id) {
      for (const [currentUserIndex, currentUser] of currentUsers.entries()) {
        // If current user is the one that was edited
        if (currentUser._id === deleteObject) {
          currentUsers.splice(currentUserIndex, 1);
        }
      }
    }
    state.users = currentUsers;
  },
  SET_VEHICULES: (state, payload) => {
    state.vehicules = payload;
  },
  SAVE_VEHICULES: (state, payload) => {
    const currentVehicules = [...state.vehicules];
    currentVehicules.push(payload._id);
    state.vehicules = currentVehicules;
  },
  EDIT_VEHICULE: (state, payload) => {
    const currentVehicules = [...state.vehicules];
    for (const [
      singleVehiculeIndex,
      singleVehicule,
    ] of currentVehicules.entries()) {
      if (singleVehicule._id === payload._id._id) {
        currentVehicules[singleVehiculeIndex] = payload._id;
      }
    }
    state.vehicules = currentVehicules;
  },
  SET_ACTIVE: (state, payload) => {
    state.active = payload;
  },

  SET_FORMULAS: (state, payload) => {
    state.formulas = payload;
  },
  SAVE_FORMULA: (state, payload) => {
    const currentFormulas = [...state.formulas];
    currentFormulas.push(payload._id);
    state.formulas = currentFormulas;
  },
  EDIT_FORMULA: (state, payload) => {
    const currentFormulas = [...state.formulas];
    for (const [
      singleFormulaIndex,
      singleFormula,
    ] of currentFormulas.entries()) {
      if (singleFormula._id === payload._id._id) {
        currentFormulas[singleFormulaIndex] = payload._id;
      }
    }
    state.formulas = currentFormulas;
  },
  DELETE_FORMULA: (state, payload) => {
    const currentFormulas = [...state.formulas];

    for (const [
      singleFormulaIndex,
      singleFormula,
    ] of currentFormulas.entries()) {
      // If current user is the one that was edited
      if (singleFormula._id === payload._id) {
        currentFormulas.splice(singleFormulaIndex, 1);
      }
    }

    state.formulas = currentFormulas;
  },
  SET_ARTICLES_TRANSFORMATION: (state, payload) => {
    state.articleTransformations = payload;
  },
  SAVE_ARTICLE_TRANSFORMATION: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];
    let finalWarehouses = [...payload.context.rootState.Purchases.warehouses];
    let finalArticleTransformation = [...state.articleTransformations];

    finalArticleTransformation.push(payload._id.transformation);

    for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
          if (singleArticle.CANT_ALMACENES) {
            let found = false;
            for (const [
              singleWarehouseQuantityIndex,
              singleWarehouseQuantity,
            ] of singleArticle.CANT_ALMACENES.entries()) {
              if (singleWarehouseQuantity._id === singleArticleWarehouse._id) {
                found = true;
                if (singleArticleWarehouse.CANTIDAD) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
                }

                if (singleArticleWarehouse.CANTIDAD_VIRTUAL) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD_VIRTUAL += Number(
                    singleArticleWarehouse.CANTIDAD_VIRTUAL
                  );
                }
              }
            }
            if (found === false) {
              finalArticles[singleArticleIndex].CANT_ALMACENES.push({
                ...singleArticleWarehouse,
              });
            }
          } else {
            finalArticles[singleArticleIndex].CANT_ALMACENES = [
              { ...singleArticleWarehouse },
            ];
          }
          break;
        }
      }
    }

    let allTransfersDone = [...payload._id.transfersDone];
    for (const [
      singleTrasferIndex,
      singleTransfer,
    ] of allTransfersDone.entries()) {
      for (const [
        singleWarehouseindex,
        singleWarehouse,
      ] of payload.context.rootState.Purchases.warehouses.entries()) {
        for (let currentWarehouse of payload.context.rootState.Purchases
          .warehouses) {
          if (currentWarehouse._id === singleTransfer.ORIGEN) {
            allTransfersDone[singleTrasferIndex].ORIGEN = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentWarehouse of payload.context.rootState.Purchases
          .warehouses) {
          if (currentWarehouse._id === singleTransfer.DESTINO) {
            allTransfersDone[singleTrasferIndex].DESTINO = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentArticle of payload.context.rootState.Purchases
          .articles) {
          if (currentArticle._id === singleTransfer.CLAVE_ART) {
            allTransfersDone[singleTrasferIndex].CLAVE_ART = {
              _id: currentArticle._id,
              CLAVE_ART: currentArticle.CLAVE_ART,
              DESCRIPCION: currentArticle.DESCRIPCION,
            };
            break;
          }
        }

        if (
          finalWarehouses[singleWarehouseindex]._id === singleTransfer.CLAVE_ALM
        ) {
          if (finalWarehouses[singleWarehouseindex].CLAVE_TRANS) {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS.push(
              allTransfersDone[singleTrasferIndex]
            );
          } else {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS = [
              allTransfersDone[singleTrasferIndex],
            ];
          }
        }
      }
    }

    payload.context.rootState.Purchases.articles = finalArticles;
    payload.context.rootState.Purchases.warehouses = finalWarehouses;
    state.articleTransformations = finalArticleTransformation;
    state.lastWarehouseTransferNumber = payload._id.lastWarehouseTransferNumber;
  },
  CANCEL_ARTICLE_TRANSFORMATION: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];
    let finalWarehouses = [...payload.context.rootState.Purchases.warehouses];
    let finalArticleTransformation = [...state.articleTransformations];

    for (const [
      singleTransformationIndex,
      singleTransformation,
    ] of finalArticleTransformation.entries()) {
      if (
        singleTransformation._id.toString() ===
        payload._id.transformation._id.toString()
      ) {
        finalArticleTransformation[singleTransformationIndex].STATUS = "CA";
      }
    }

    for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
          if (singleArticle.CANT_ALMACENES) {
            let found = false;
            for (const [
              singleWarehouseQuantityIndex,
              singleWarehouseQuantity,
            ] of singleArticle.CANT_ALMACENES.entries()) {
              if (singleWarehouseQuantity._id === singleArticleWarehouse._id) {
                found = true;
                if (singleArticleWarehouse.CANTIDAD) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
                }

                if (singleArticleWarehouse.CANTIDAD_VIRTUAL) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD_VIRTUAL += Number(
                    singleArticleWarehouse.CANTIDAD_VIRTUAL
                  );
                }
              }
            }
            if (found === false) {
              finalArticles[singleArticleIndex].CANT_ALMACENES.push({
                ...singleArticleWarehouse,
              });
            }
          } else {
            finalArticles[singleArticleIndex].CANT_ALMACENES = [
              { ...singleArticleWarehouse },
            ];
          }
          break;
        }
      }
    }

    let allTransfersDone = [...payload._id.transfersDone];
    for (const [
      singleTrasferIndex,
      singleTransfer,
    ] of allTransfersDone.entries()) {
      for (const [
        singleWarehouseindex,
        singleWarehouse,
      ] of payload.context.rootState.Purchases.warehouses.entries()) {
        for (let currentWarehouse of payload.context.rootState.Purchases
          .warehouses) {
          if (currentWarehouse._id === singleTransfer.ORIGEN) {
            allTransfersDone[singleTrasferIndex].ORIGEN = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentWarehouse of payload.context.rootState.Purchases
          .warehouses) {
          if (currentWarehouse._id === singleTransfer.DESTINO) {
            allTransfersDone[singleTrasferIndex].DESTINO = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentArticle of payload.context.rootState.Purchases
          .articles) {
          if (currentArticle._id === singleTransfer.CLAVE_ART) {
            allTransfersDone[singleTrasferIndex].CLAVE_ART = {
              _id: currentArticle._id,
              CLAVE_ART: currentArticle.CLAVE_ART,
              DESCRIPCION: currentArticle.DESCRIPCION,
            };
            break;
          }
        }

        if (
          finalWarehouses[singleWarehouseindex]._id === singleTransfer.CLAVE_ALM
        ) {
          if (finalWarehouses[singleWarehouseindex].CLAVE_TRANS) {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS.push(
              allTransfersDone[singleTrasferIndex]
            );
          } else {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS = [
              allTransfersDone[singleTrasferIndex],
            ];
          }
        }
      }
    }

    payload.context.rootState.Purchases.articles = finalArticles;
    payload.context.rootState.Purchases.warehouses = finalWarehouses;
    state.articleTransformations = finalArticleTransformation;
    state.lastWarehouseTransferNumber = payload._id.lastWarehouseTransferNumber;
  },
  SAVE_ASSET: (state, payload) => {
    const currentAssets = [...state.assets];
    currentAssets.push(payload._id);
    state.assets = currentAssets;
  },
  SET_ASSETS: (state, payload) => {
    state.assets = payload;
  },
  EDIT_ASSET: (state, payload) => {
    const currentAssets = [...state.assets];
    for (const [singleAssetIndex, singleAsset] of currentAssets.entries()) {
      if (singleAsset._id === payload._id._id) {
        currentAssets[singleAssetIndex] = payload._id;
      }
    }
    state.assets = currentAssets;
  },
  DELETE_ASSET: (state, payload) => {
    const currentAssets = [...state.assets];

    for (const [singleAssetIndex, singleAsset] of currentAssets.entries()) {
      if (singleAsset._id === payload._id._id) {
        currentAssets.splice(singleAssetIndex, 1);
        break;
      }
    }

    state.assets = currentAssets;
  },
};

export default mutations;
