import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";

let humanResources = {
  state,
  actions,
  mutations,
  getters,
};

export default humanResources;
