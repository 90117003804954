let state = {
  articles: [],
  articlesPaginated: [],
  articlesPaginatedTotal: 0,
  providers: [],
  warehouses: [],
  purchaseOrders: [],
  requestOrders: [],
  paymentPeriods: [],
  typesDelivery: [],
  lastPurchaseOrderNumber: 1,
  lastWarehouseTransferNumber: 1,
  satArticlesDivision: [],
};

export default state;
