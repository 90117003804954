let mutations = {
  RESTORE_TREASURY_STATES: (state) => {
    Object.assign(state, {
      lastPaymentNumber: 0,
      lastExpenseNumber: 0,
      expenses: [],
      expensesPayments: [],
      payments: [],
      incomesSales: [],
      incomes: [],
      advancedPayments: [],
    });
  },
  SET_LAST_PURCHASE_NUMBER: (state, payload) => {
    state.lastPaymentNumber = payload.lastPaymentNumber;
  },
  SET_LAST_EXPENSE_NUMBER: (state, payload) => {
    state.lastExpenseNumber = payload.lastExpenseNumber;
  },
  SET_PAYMENTS: (state, payload) => {
    state.payments = payload;
  },
  SET_EXPENSES: (state, payload) => {
    state.expenses = payload;
  },
  SET_INCOMES: (state, payload) => {
    state.incomes = payload;
  },
  ADD_EXPENSE: (state, payload) => {
    const currentExpenses = [...state.expenses];
    const currentProviders = payload.context.rootState.Purchases.providers;

    currentExpenses.push(payload._id.newExpense);
    for (const [
      singleProviderIndex,
      singleProvider,
    ] of currentProviders.entries()) {
      if (singleProvider._id === payload._id.newExpense.CLAVE_PRV._id) {
        currentProviders[singleProviderIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!currentProviders[singleProviderIndex].BALANCES) {
          currentProviders[singleProviderIndex].BALANCES = [
            payload._id.newBalance,
          ];
        } else {
          currentProviders[singleProviderIndex].BALANCES.push(
            payload._id.newBalance
          );
        }
      }
    }

    payload.context.rootState.Purchases.providers = currentProviders;
    state.expenses = currentExpenses;
    state.lastExpenseNumber += 1;
  },
  ADD_MANUAL_MOVEMENT_EXPENSE: (state, payload) => {
    const currentExpenses = [...state.expenses];
    const currentProviders = payload.context.rootState.Purchases.providers;

    for (const [
      singleExpenseIndex,
      singleExpense,
    ] of currentExpenses.entries()) {
      if (singleExpense._id === payload._id.expense._id) {
        currentExpenses[singleExpenseIndex] = payload._id.expense;
      }
    }

    for (const [
      singleProviderIndex,
      singleProvider,
    ] of currentProviders.entries()) {
      if (singleProvider._id === payload._id.expense.CLAVE_PRV._id) {
        currentProviders[singleProviderIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!currentProviders[singleProviderIndex].BALANCES) {
          currentProviders[singleProviderIndex].BALANCES = [
            payload._id.newBalance,
          ];
        } else {
          currentProviders[singleProviderIndex].BALANCES.push(
            payload._id.newBalance
          );
        }
      }
    }

    state.expenses = currentExpenses;
    payload.context.rootState.Purchases.providers = currentProviders;
  },
  EDIT_EXPENSE_BASIC_INFORMATION: (state, payload) => {
    const currentExpenses = [...state.expenses];

    for (const [
      singleExpenseIndex,
      singleExpense,
    ] of currentExpenses.entries()) {
      if (singleExpense._id === payload._id.expense._id) {
        currentExpenses[singleExpenseIndex] = payload._id.expense;
      }
    }

    state.expenses = currentExpenses;
  },
  EDIT_EXPENSE: (state, payload) => {
    const currentExpenses = [...state.expenses];
    const currentProviders = payload.context.rootState.Purchases.providers;

    // Edit expense
    for (const [
      singleExpenseIndex,
      singleExpense,
    ] of currentExpenses.entries()) {
      if (singleExpense._id === payload._id.expense._id) {
        currentExpenses[singleExpenseIndex] = payload._id.expense;
      }
    }

    // Edit provider expenses
    if (payload._id.newExpense) {
      for (const [
        singleProviderIndex,
        singleProvider,
      ] of currentProviders.entries()) {
        if (singleProvider._id === payload._id.newExpense.CLAVE_PRV._id) {
          currentProviders[singleProviderIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentProviders[singleProviderIndex].BALANCES) {
            currentProviders[singleProviderIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentProviders[singleProviderIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }
    }

    payload.context.rootState.Purchases.providers = currentProviders;
    state.expenses = currentExpenses;
  },
  CANCEL_EXPENSE: (state, payload) => {
    try {
      const currentExpenses = [...state.expenses];
      const currentProviders = payload.context.rootState.Purchases.providers;

      // Cancel expense
      for (const [
        singleExpenseIndex,
        singleExpense,
      ] of currentExpenses.entries()) {
        if (singleExpense._id === payload._id.expenseId) {
          currentExpenses[singleExpenseIndex].STATUS = "CA";
        }
      }

      for (const [
        singleProviderIndex,
        singleProvider,
      ] of currentProviders.entries()) {
        if (
          singleProvider._id === payload._id.expenseInformation.CLAVE_PRV._id
        ) {
          currentProviders[singleProviderIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentProviders[singleProviderIndex].BALANCES) {
            currentProviders[singleProviderIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentProviders[singleProviderIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }

      payload.context.rootState.Purchases.providers = currentProviders;
      state.expenses = currentExpenses;
    } catch (error) {
      console.log(error);
    }
  },
  ADD_PAYMENT_EXPENSE: (state, payload) => {
    let finalExpenses = [...state.expenses];
    let currentBankAccounts = [...payload.context.rootState.Banks.bankAccounts];
    let currentProviders = [...payload.context.rootState.Purchases.providers];

    // Update expense
    for (const [singleExpenseIndex, singleExpense] of finalExpenses.entries()) {
      if (singleExpense._id === payload._id.expense._id) {
        finalExpenses[singleExpenseIndex] = payload._id.expense;
      }
    }

    // Update bank accounts
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.bankAccount) {
        // Update consolidated balance of used bank account
        if (!currentBankAccounts[singleBankAccountIndex].SALDOS_CON) {
          currentBankAccounts[singleBankAccountIndex].SALDOS_CON = [
            payload._id.consolidatedFinalBalance,
          ];
        } else {
          currentBankAccounts[singleBankAccountIndex].SALDOS_CON.push(
            payload._id.consolidatedFinalBalance
          );
        }
        currentBankAccounts[singleBankAccountIndex].SALDO_CON_ACTUAL =
          payload._id.consolidatedFinalBalance;

        // If needed update bank account balance
        if (payload._id.affectBalance) {
          // Update balance of used bank account
          if (!currentBankAccounts[singleBankAccountIndex].SALDOS) {
            currentBankAccounts[singleBankAccountIndex].SALDOS = [
              payload._id.finalBalance,
            ];
          } else {
            currentBankAccounts[singleBankAccountIndex].SALDOS.push(
              payload._id.finalBalance
            );
          }
          currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
            payload._id.finalBalance;
        }

        // If needed add movement to bankaccount
        if (payload._id.createMovement) {
          // Update movements of used bank account
          if (!currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS) {
            currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS = [
              payload._id.bankMovement,
            ];
          } else {
            currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS.push(
              payload._id.bankMovement
            );
          }
        }

        // If needed search for movement and update remaining balance
        if (payload._id.payment.CLAVE_MOVIMIENTO) {
          for (const [
            singleMovementIndex,
            singleMovement,
          ] of singleBankAccount.MOVIMIENTOS.entries()) {
            if (
              singleMovement._id === payload._id.payment.CLAVE_MOVIMIENTO._id
            ) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleMovementIndex
              ].RESTA -= payload._id.payment.PAGO_PARC;
            }
          }
        }

        break;
      }
    }

    // update provider balance
    for (const [
      singleProviderIndex,
      singleProvider,
    ] of currentProviders.entries()) {
      if (singleProvider._id === payload._id.payment.CLAVE_PRV) {
        currentProviders[singleProviderIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!currentProviders[singleProviderIndex].BALANCES) {
          currentProviders[singleProviderIndex].BALANCES = [
            payload._id.newBalance,
          ];
        } else {
          currentProviders[singleProviderIndex].BALANCES.push(
            payload._id.newBalance
          );
        }
      }
    }

    state.expenses = finalExpenses;
    state.lastPaymentNumber = payload._id.lastPaymentNumber;
    payload.context.rootState.Purchases.providers = currentProviders;
    payload.context.rootState.Banks.bankAccounts = currentBankAccounts;
  },
  CANCEL_PAYMENT_EXPENSE: (state, payload) => {
    let finalExpenses = [...state.expenses];
    let finalAdvancedPayments = [...state.advancedPayments];
    let currentBankAccounts = [...payload.context.rootState.Banks.bankAccounts];
    let currentProviders = [...payload.context.rootState.Purchases.providers];

    // Update expense
    for (const [singleExpenseIndex, singleExpense] of finalExpenses.entries()) {
      if (singleExpense._id === payload._id.expense._id) {
        finalExpenses[singleExpenseIndex] = payload._id.expense;
        break;
      }
    }

    // Update advanced payment balance if needed
    if (payload._id.paymentInformation.CLAVE_ANTICIPO) {
      for (const singleAdvancedPayment of finalAdvancedPayments) {
        if (
          singleAdvancedPayment._id.toString() ===
          payload._id.paymentInformation.CLAVE_ANTICIPO.toString()
        ) {
          singleAdvancedPayment.RESTA += Number(
            payload._id.paymentInformation.PAGO_PARC
          );
        }
      }
    }

    // Update bank accounts
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.paymentInformation.CUENTA) {
        // Update consolidated balance of used bank account
        if (!currentBankAccounts[singleBankAccountIndex].SALDOS_CON) {
          currentBankAccounts[singleBankAccountIndex].SALDOS_CON = [
            payload._id.consolidatedFinalBalance,
          ];
        } else {
          currentBankAccounts[singleBankAccountIndex].SALDOS_CON.push(
            payload._id.consolidatedFinalBalance
          );
        }
        currentBankAccounts[singleBankAccountIndex].SALDO_CON_ACTUAL =
          payload._id.consolidatedFinalBalance;

        // If needed update bank account balance
        if (payload._id.paymentInformation.affectBalance) {
          // Update balance of used bank account
          if (!currentBankAccounts[singleBankAccountIndex].SALDOS) {
            currentBankAccounts[singleBankAccountIndex].SALDOS = [
              payload._id.finalBalance,
            ];
          } else {
            currentBankAccounts[singleBankAccountIndex].SALDOS.push(
              payload._id.finalBalance
            );
          }
          currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
            payload._id.finalBalance;
        }

        // If needed change bank movement to cancelled
        if (payload._id.createMovement) {
          for (const [
            singleBankMovementIndex,
            singleBankMovement,
          ] of currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS) {
            if (singleBankMovement.PAGO_ID === payload._id.paymentInformation) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleBankMovementIndex
              ].STATUS = "CA";
              break;
            }
          }
        }

        // Update bank movement balance if needed
        if (payload._id.paymentInformation.CLAVE_MOVIMIENTO) {
          for (const [
            singleBankMovementIndex,
            singleBankMovement,
          ] of currentBankAccounts[
            singleBankAccountIndex
          ].MOVIMIENTOS.entries()) {
            if (
              singleBankMovement._id.toString() ===
              payload._id.paymentInformation.CLAVE_MOVIMIENTO.toString()
            ) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleBankMovementIndex
              ].RESTA += Number(payload._id.paymentInformation.PAGO_PARC);
              break;
            }
          }
        }

        break;
      }
    }

    // update provider balance
    for (const [
      singleProviderIndex,
      singleProvider,
    ] of currentProviders.entries()) {
      if (singleProvider._id === payload._id.paymentInformation.CLAVE_PRV) {
        if (payload._id.newBalance) {
          currentProviders[singleProviderIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentProviders[singleProviderIndex].BALANCES) {
            currentProviders[singleProviderIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentProviders[singleProviderIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }
    }

    state.expenses = finalExpenses;
    state.advancedPayments = finalAdvancedPayments;
    payload.context.rootState.Purchases.providers = currentProviders;
    payload.context.rootState.Banks.bankAccounts = currentBankAccounts;
  },
  ADD_PAYMENT_INCOME: (state, payload) => {
    let finalIncomes = [...state.incomes];
    let currentBankAccounts = [...payload.context.rootState.Banks.bankAccounts];
    let currentClients = [...payload.context.rootState.Sales.clients];

    // Update income and income details
    for (const [singleIncomeIndex, singleIncome] of finalIncomes.entries()) {
      if (singleIncome._id === payload._id.income._id) {
        finalIncomes[singleIncomeIndex] = payload._id.income;
      }
    }

    // Update bank accounts
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (payload._id.bankAccount) {
        if (singleBankAccount._id === payload._id.bankAccount) {
          // If needed update bank account balance
          if (payload._id.affectBalance) {
            // Update balance of used bank account
            if (!currentBankAccounts[singleBankAccountIndex].SALDOS) {
              currentBankAccounts[singleBankAccountIndex].SALDOS = [
                payload._id.finalBalance,
              ];
            } else {
              currentBankAccounts[singleBankAccountIndex].SALDOS.push(
                payload._id.finalBalance
              );
            }
            currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
              payload._id.finalBalance;
          }

          // If needed add movement to bankaccount
          if (payload._id.createMovement) {
            // Update movements of used bank account
            if (!currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS = [
                payload._id.bankMovement,
              ];
            } else {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS.push(
                payload._id.bankMovement
              );
            }
          }

          // If needed search for movement and update remaining balance
          if (payload._id.payment.CLAVE_MOVIMIENTO) {
            for (const [
              singleMovementIndex,
              singleMovement,
            ] of singleBankAccount.MOVIMIENTOS.entries()) {
              if (
                singleMovement._id === payload._id.payment.CLAVE_MOVIMIENTO._id
              ) {
                currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                  singleMovementIndex
                ].RESTA -= payload._id.payment.PAGO_PARC;
              }
            }
          }

          break;
        }
      }
    }

    // update client balance
    for (const [singleClientIndex, singleClient] of currentClients.entries()) {
      if (singleClient._id === payload._id.payment.CLAVE_CLIENTE) {
        if (payload._id.newBalance) {
          currentClients[singleClientIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentClients[singleClientIndex].BALANCES) {
            currentClients[singleClientIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentClients[singleClientIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }

        if (payload._id.updatedBalance && payload._id.updatedBalance.changed) {
          if (payload._id.updatedBalance.clientCreditGroup) {
            for (const singleClientGroup of payload._id.clientCreditGroup
              .CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of currentClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  currentClients[singleCurrentClientIndex].BALANCE_CRED +=
                    payload._id.updatedBalance.total;
                }
              }
            }
          }
        } else {
          currentClients[singleClientIndex].BALANCE_CRED +=
            payload._id.updatedBalance.total;
        }
      }
    }

    state.incomes = finalIncomes;
    state.lastPaymentNumber = payload._id.lastPaymentNumber;
    payload.context.rootState.Sales.clients = currentClients;
    payload.context.rootState.Banks.bankAccounts = currentBankAccounts;

    // Commit save invoice mutation
    if (payload._id.invoice) {
      payload.context.commit("SAVE_INVOICE_PAYMENT", payload);
    }
  },
  ADD_PAYMENT_MULTIPLE_INCOME: (state, payload) => {
    for (const singlePayment of payload._id) {
      payload.context.commit("ADD_PAYMENT_INCOME", {
        ...payload,
        _id: singlePayment,
      });
    }
  },
  ADD_PAYMENT_MULTIPLE_EXPENSE: (state, payload) => {
    for (const singleExpense of payload._id) {
      payload.context.commit("ADD_PAYMENT_EXPENSE", {
        ...payload,
        _id: singleExpense,
      });
    }
  },
  CANCEL_PAYMENT_INCOME: (state, payload) => {
    let finalIncomes = [...state.incomes];
    let currentBankAccounts = [...payload.context.rootState.Banks.bankAccounts];
    let currentClients = [...payload.context.rootState.Sales.clients];
    let finalAdvancedPayments = [...state.advancedPayments];

    // Update advanced payment balance if needed
    if (payload._id.paymentInformation.CLAVE_ANTICIPO) {
      for (const singleAdvancedPayment of finalAdvancedPayments) {
        if (
          singleAdvancedPayment._id.toString() ===
          payload._id.paymentInformation.CLAVE_ANTICIPO.toString()
        ) {
          singleAdvancedPayment.RESTA += Number(
            payload._id.paymentInformation.PAGO_PARC
          );
        }
      }
    }

    // Update income
    for (const [singleIncomeIndex, singleIncome] of finalIncomes.entries()) {
      if (singleIncome._id === payload._id.incomeInformation._id) {
        finalIncomes[singleIncomeIndex] = payload._id.incomeInformation;
      }
    }

    // Update bank accounts
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.paymentInformation.CUENTA) {
        // If needed update bank account balance
        if (payload._id.paymentInformation.affectBalance) {
          // Update balance of used bank account
          if (!currentBankAccounts[singleBankAccountIndex].SALDOS) {
            currentBankAccounts[singleBankAccountIndex].SALDOS = [
              payload._id.finalBalance,
            ];
          } else {
            currentBankAccounts[singleBankAccountIndex].SALDOS.push(
              payload._id.finalBalance
            );
          }
          currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
            payload._id.finalBalance;
        }

        // If needed change bank movement to cancelled
        if (payload._id.createMovement) {
          for (const [
            singleBankMovementIndex,
            singleBankMovement,
          ] of currentBankAccounts[
            singleBankAccountIndex
          ].MOVIMIENTOS.entries()) {
            if (singleBankMovement.PAGO_ID === payload._id.paymentInformation) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleBankMovementIndex
              ].STATUS = "CA";
            }

            // Update bank movement balance if needed
            if (payload._id.paymentInformation.CLAVE_MOVIMIENTO) {
              if (
                singleBankAccount._id.toString() ===
                payload._id.paymentInformation.CLAVE_MOVIMIENTO._id.toString()
              ) {
                currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                  singleBankMovementIndex
                ].RESTA += Number(payload._id.paymentInformation.PAGO_PARC);
              }
            }
          }
        }

        // Update bank movement balance if needed
        if (payload._id.paymentInformation.CLAVE_MOVIMIENTO) {
          for (const [
            singleBankMovementIndex,
            singleBankMovement,
          ] of currentBankAccounts[
            singleBankAccountIndex
          ].MOVIMIENTOS.entries()) {
            if (
              singleBankMovement._id.toString() ===
              payload._id.paymentInformation.CLAVE_MOVIMIENTO.toString()
            ) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleBankMovementIndex
              ].RESTA += Number(payload._id.paymentInformation.PAGO_PARC);
              break;
            }
          }
        }

        break;
      }
    }

    // update client balance
    for (const [singleClientIndex, singleClient] of currentClients.entries()) {
      if (singleClient._id === payload._id.paymentInformation.CLAVE_CLIENTE) {
        if (payload._id.newBalance) {
          currentClients[singleClientIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentClients[singleClientIndex].BALANCES) {
            currentClients[singleClientIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentClients[singleClientIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }

        if (payload._id.updatedBalance && payload._id.updatedBalance.changed) {
          if (payload._id.updatedBalance.clientCreditGroup) {
            for (const singleClientGroup of payload._id.updatedBalance
              .clientCreditGroup.CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of currentClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  currentClients[singleCurrentClientIndex].BALANCE_CRED +=
                    payload._id.updatedBalance.total;
                }
              }
            }
          }
        } else {
          currentClients[singleClientIndex].BALANCE_CRED +=
            payload._id.updatedBalance.total;
        }
      }
    }

    state.incomes = finalIncomes;
    state.advancedPayments = finalAdvancedPayments;
    payload.context.rootState.Sales.clients = currentClients;
    payload.context.rootState.Banks.bankAccounts = currentBankAccounts;
  },
  CANCEL_ADVANCED_INCOME: (state, payload) => {
    try {
      const currentAdvancedPayments = [...state.advancedPayments];
      let currentBankAccounts = [
        ...payload.context.rootState.Banks.bankAccounts,
      ];
      let currentClients = [...payload.context.rootState.Sales.clients];

      //Update payment status and invoice status if needed
      for (const [
        singlePaymentIndex,
        singlePayment,
      ] of currentAdvancedPayments.entries()) {
        if (singlePayment._id === payload._id.paymentInformation._id) {
          currentAdvancedPayments[singlePaymentIndex].STATUS = "CA";
          if (
            payload._id.invoiceCancellations &&
            payload._id.invoiceCancellations.length > 0
          ) {
            currentAdvancedPayments[singlePaymentIndex].STATUS_FAC = "CA";
          }
        }
      }

      // Update bank accounts
      for (const [
        singleBankAccountIndex,
        singleBankAccount,
      ] of currentBankAccounts.entries()) {
        if (singleBankAccount._id === payload._id.paymentInformation.CUENTA) {
          // If needed update bank account balance
          if (payload._id.paymentInformation.affectBalance) {
            // Update balance of used bank account
            if (!currentBankAccounts[singleBankAccountIndex].SALDOS) {
              currentBankAccounts[singleBankAccountIndex].SALDOS = [
                payload._id.finalBalance,
              ];
            } else {
              currentBankAccounts[singleBankAccountIndex].SALDOS.push(
                payload._id.finalBalance
              );
            }
            currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
              payload._id.finalBalance;
          }

          // If needed change bank movement to cancelled
          if (payload._id.createMovement) {
            for (const [
              singleBankMovementIndex,
              singleBankMovement,
            ] of currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS
              .entries) {
              if (
                singleBankMovement.PAGO_ID === payload._id.paymentInformation
              ) {
                currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                  singleBankMovementIndex
                ].STATUS = "CA";
                break;
              }
            }
          }

          // Update bank movement balance if needed
          if (payload._id.paymentInformation.CLAVE_MOVIMIENTO) {
            for (const [
              singleBankMovementIndex,
              singleBankMovement,
            ] of currentBankAccounts[
              singleBankAccountIndex
            ].MOVIMIENTOS.entries()) {
              if (
                singleBankMovement._id.toString() ===
                payload._id.paymentInformation.CLAVE_MOVIMIENTO.toString()
              ) {
                currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                  singleBankMovementIndex
                ].RESTA += Number(payload._id.paymentInformation.PAGO_PARC);
                break;
              }
            }
          }

          break;
        }
      }

      // update client balance
      for (const [
        singleClientIndex,
        singleClient,
      ] of currentClients.entries()) {
        if (singleClient._id === payload._id.paymentInformation.CLAVE_CLIENTE) {
          currentClients[singleClientIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentClients[singleClientIndex].BALANCES) {
            currentClients[singleClientIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentClients[singleClientIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }

      state.advancedPayments = currentAdvancedPayments;
      payload.context.rootState.Sales.clients = currentClients;
      payload.context.rootState.Banks.bankAccounts = currentBankAccounts;
    } catch (error) {
      console.log(error);
    }
  },
  ADD_INCOME: (state, payload) => {
    const currentIncome = [...state.incomes];
    const currentClients = [...payload.context.rootState.Sales.clients];

    currentIncome.push(payload._id.newIncome);
    for (const [singleClientIndex, singleClient] of currentClients.entries()) {
      if (singleClient._id === payload._id.newIncome.CLAVE_CLIENTE._id) {
        currentClients[singleClientIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!currentClients[singleClientIndex].BALANCES) {
          currentClients[singleClientIndex].BALANCES = [payload._id.newBalance];
        } else {
          currentClients[singleClientIndex].BALANCES.push(
            payload._id.newBalance
          );
        }
      }
    }

    payload.context.rootState.Sales.clients = currentClients;
    state.incomes = currentIncome;
    payload.context.rootState.Sales.lastIncomeNumber += 1;
  },
  ADD_MANUAL_MOVEMENT_INCOME: (state, payload) => {
    const currentIncome = [...state.incomes];
    const currentClients = [...payload.context.rootState.Sales.clients];

    for (const [singleIncomeIndex, singleIncome] of currentIncome.entries()) {
      if (singleIncome._id === payload._id.income._id) {
        currentIncome[singleIncomeIndex] = payload._id.income;
      }
    }

    for (const [singleClientIndex, singleClient] of currentClients.entries()) {
      if (singleClient._id === payload._id.income.CLAVE_CLIENTE._id) {
        currentClients[singleClientIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!currentClients[singleClientIndex].BALANCES) {
          currentClients[singleClientIndex].BALANCES = [payload._id.newBalance];
        } else {
          currentClients[singleClientIndex].BALANCES.push(
            payload._id.newBalance
          );
        }
      }
    }

    state.incomes = currentIncome;
    payload.context.rootState.Sales.clients = currentClients;
  },
  EDIT_INCOME_BASIC_INFORMATION: (state, payload) => {
    const currentIncome = [...state.incomes];

    for (const [singleIncomeIndex, singleIncome] of currentIncome.entries()) {
      if (singleIncome._id === payload._id.income._id) {
        currentIncome[singleIncomeIndex] = payload._id.income;
      }
    }

    state.incomes = currentIncome;
  },
  CANCEL_INCOME: (state, payload) => {
    try {
      const currentIncome = [...state.incomes];
      const currentClients = [...payload.context.rootState.Sales.clients];

      // Cancel income
      for (const [singleIncomeIndex, singleIncome] of currentIncome.entries()) {
        if (singleIncome._id === payload._id.incomeId) {
          currentIncome[singleIncomeIndex].STATUS = "CA";
        }
      }

      // Update client balance
      for (const [
        singleClientIndex,
        singleClient,
      ] of currentClients.entries()) {
        if (
          singleClient._id === payload._id.incomeInformation.CLAVE_CLIENTE._id
        ) {
          currentClients[singleClientIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentClients[singleClientIndex].BALANCES) {
            currentClients[singleClientIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentClients[singleClientIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }

      payload.context.rootState.Sales.clients = currentClients;
      state.incomes = currentIncome;
    } catch (error) {
      console.log(error);
    }
  },
  SET_ADVANCED_PAYMENTS: (state, payload) => {
    state.advancedPayments = payload;
  },
  ADD_ADVANCED_PAYMENT: (state, payload) => {
    let currentBankAccounts = [...payload.context.rootState.Banks.bankAccounts];
    let currentClients = [...payload.context.rootState.Sales.clients];
    let currentProviders = [...payload.context.rootState.Purchases.providers];

    // Update bank accounts
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.bankAccount) {
        // If needed update bank account balance
        if (payload._id.affectBalance) {
          // Update balance of used bank account
          if (!currentBankAccounts[singleBankAccountIndex].SALDOS) {
            currentBankAccounts[singleBankAccountIndex].SALDOS = [
              payload._id.finalBalance,
            ];
          } else {
            currentBankAccounts[singleBankAccountIndex].SALDOS.push(
              payload._id.finalBalance
            );
          }
          currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
            payload._id.finalBalance;
        }

        // If needed add movement to bankaccount
        if (payload._id.createMovement) {
          // Update movements of used bank account
          if (!currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS) {
            currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS = [
              payload._id.bankMovement,
            ];
          } else {
            currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS.push(
              payload._id.bankMovement
            );
          }
        }

        // If needed search for movement and update remaining balance
        if (payload._id.payment.CLAVE_MOVIMIENTO) {
          for (const [
            singleMovementIndex,
            singleMovement,
          ] of singleBankAccount.MOVIMIENTOS.entries()) {
            if (
              singleMovement._id === payload._id.payment.CLAVE_MOVIMIENTO._id
            ) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleMovementIndex
              ].RESTA -= payload._id.payment.PAGO_PARC;
            }
          }
        }

        break;
      }
    }

    if (payload._id.payment.CLAVE_CLIENTE) {
      // update client balance
      for (const [
        singleClientIndex,
        singleClient,
      ] of currentClients.entries()) {
        if (singleClient._id === payload._id.payment.CLAVE_CLIENTE._id) {
          currentClients[singleClientIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentClients[singleClientIndex].BALANCES) {
            currentClients[singleClientIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentClients[singleClientIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }
    }

    if (payload._id.payment.CLAVE_PRV) {
      // update provider balance
      for (const [
        singleProviderIndex,
        singleProvider,
      ] of currentProviders.entries()) {
        if (singleProvider._id === payload._id.payment.CLAVE_PRV._id) {
          currentProviders[singleProviderIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!currentProviders[singleProviderIndex].BALANCES) {
            currentProviders[singleProviderIndex].BALANCES = [
              payload._id.newBalance,
            ];
          } else {
            currentProviders[singleProviderIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }
    }

    currentProviders;

    let newPaymentSave = { ...payload._id.payment };
    state.advancedPayments.push(payload._id.payment);
    state.lastPaymentNumber = payload._id.lastPaymentNumber;
    payload.context.rootState.Sales.clients = currentClients;
    payload.context.rootState.Purchases.providers = currentProviders;
    payload.context.rootState.Banks.bankAccounts = currentBankAccounts;

    // Commit save invoice credit note mutation
    if (payload._id.invoice) {
      payload.context.commit("SAVE_INVOICE_PAYMENT", payload);
    }
  },
  EDIT_ADVANCED_PAYMENT: (state, payload) => {
    let currentBankAccounts = [...payload.context.rootState.Banks.bankAccounts];
    let currentAdvancedPayments = [...state.advancedPayments];

    // Update bank accounts
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.payment.CUENTA) {
        // If needed search for movement and update remaining balance
        if (payload._id.payment.CLAVE_MOVIMIENTO) {
          for (const [
            singleMovementIndex,
            singleMovement,
          ] of singleBankAccount.MOVIMIENTOS.entries()) {
            if (
              singleMovement._id === payload._id.payment.CLAVE_MOVIMIENTO._id
            ) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleMovementIndex
              ].RESTA -= payload._id.payment.PAGO_PARC;
            }
          }
        }

        break;
      }
    }

    for (const [
      singlePaymentIndex,
      singlePayment,
    ] of currentAdvancedPayments.entries()) {
      if (singlePayment._id === payload._id.payment._id) {
        currentAdvancedPayments[singlePaymentIndex] = payload._id.payment;
        break;
      }
    }

    payload.context.rootState.Banks.bankAccounts = currentBankAccounts;
    state.advancedPayments = currentAdvancedPayments;
  },
  USE_ADVANCED_PAYMENT: (state, payload) => {
    let finalExpenses = [...state.expenses];
    let finalAdvancedPayments = [...state.advancedPayments];

    // Update expense
    for (const [singleExpenseIndex, singleExpense] of finalExpenses.entries()) {
      if (singleExpense._id === payload._id.expense._id) {
        finalExpenses[singleExpenseIndex] = payload._id.expense;
      }
    }

    for (const singleAdvancedPayment of finalAdvancedPayments) {
      if (singleAdvancedPayment._id === payload._id.advancedPayment) {
        singleAdvancedPayment.RESTA -= payload._id.payment.PAGO_PARC;
      }
    }

    state.expenses = finalExpenses;
    state.advancedPayments = finalAdvancedPayments;
    state.lastPaymentNumber = payload._id.lastPaymentNumber;
  },
  USE_ADVANCED_PAYMENT_INCOME: (state, payload) => {
    let finalIncomes = [...state.incomes];
    let currentClients = [...payload.context.rootState.Sales.clients];
    let finalAdvancedPayments = [...state.advancedPayments];

    // Update income and income details
    for (const [singleIncomeIndex, singleIncome] of finalIncomes.entries()) {
      if (singleIncome._id === payload._id.income._id) {
        finalIncomes[singleIncomeIndex] = payload._id.income;
      }
    }

    // update client credit balance if needed
    for (const [singleClientIndex, singleClient] of currentClients.entries()) {
      if (singleClient._id === payload._id.payment.CLAVE_CLIENTE) {
        if (payload._id.updatedBalance && payload._id.updatedBalance.changed) {
          if (payload._id.updatedBalance.clientCreditGroup) {
            for (const singleClientGroup of payload._id.clientCreditGroup
              .CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of currentClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  currentClients[singleCurrentClientIndex].BALANCE_CRED +=
                    payload._id.updatedBalance.total;
                }
              }
            }
          }
        } else {
          currentClients[singleClientIndex].BALANCE_CRED +=
            payload._id.updatedBalance.total;
        }
      }
    }

    for (const singleAdvancedPayment of finalAdvancedPayments) {
      if (singleAdvancedPayment._id === payload._id.advancedPayment) {
        singleAdvancedPayment.RESTA -= payload._id.payment.PAGO_PARC;
      }
    }

    state.incomes = finalIncomes;
    state.lastPaymentNumber = payload._id.lastPaymentNumber;
    payload.context.rootState.Sales.clients = currentClients;
    state.advancedPayments = finalAdvancedPayments;

    // Commit save invoice mutation
    if (payload._id.invoice) {
      payload.context.commit("SAVE_INVOICE_PAYMENT", payload);
    }
  },
  AUTHORIZE_EXPENSE: (state, payload) => {
    let currentExpenses = [...state.expenses];

    for (const [
      singleExpenseIndex,
      singleExpense,
    ] of currentExpenses.entries()) {
      if (singleExpense._id === payload._id.expenseId) {
        currentExpenses[singleExpenseIndex].STATUS = "PE";
      }
    }

    state.expenses = currentExpenses;
  },
  REMOVE_MISSING_ACCOUNTS_INCOMES: (state, payload) => {
    let currentIncomes = [...state.incomes];

    for (const [singleIncomeIndex, singleIncome] of currentIncomes.entries()) {
      for (const singlePayloadIncome of payload._id) {
        if (singleIncome._id === singlePayloadIncome._id) {
          currentIncomes[singleIncomeIndex].STATUS = "PA";
          currentIncomes[singleIncomeIndex].RESTA = 0;
        }
      }
    }

    state.incomes = currentIncomes;
  },
  REMOVE_MISSING_ACCOUNTS_EXPENSES: (state, payload) => {
    let currentExpenses = [...state.expenses];

    for (const [
      singleExpenseIndex,
      singleExpense,
    ] of currentExpenses.entries()) {
      for (const singlePayloadExpense of payload._id) {
        if (singleExpense._id === singlePayloadExpense._id) {
          currentExpenses[singleExpenseIndex].STATUS = "PA";
          currentExpenses[singleExpenseIndex].RESTA = 0;
        }
      }
    }

    state.expenses = currentExpenses;
  },
  REMOVE_MISSING_PAYMENTS_CLIENTS: (state, payload) => {
    let currentAdvancedPayments = [...state.advancedPayments];

    for (const [
      singleAdvancedPaymentIndex,
      singleAdvancedPayment,
    ] of currentAdvancedPayments.entries()) {
      for (const singlePayloadPayment of payload._id) {
        if (singleAdvancedPayment._id === singlePayloadPayment._id) {
          currentAdvancedPayments[singleAdvancedPaymentIndex].RESTA = 0;
        }
      }
    }

    state.advancedPayments = currentAdvancedPayments;
  },
  REMOVE_MISSING_PAYMENTS_PROVIDERS: (state, payload) => {
    let currentAdvancedPayments = [...state.advancedPayments];

    for (const [
      singleAdvancedPaymentIndex,
      singleAdvancedPayment,
    ] of currentAdvancedPayments.entries()) {
      for (const singlePayloadPayment of payload._id) {
        if (singleAdvancedPayment._id === singlePayloadPayment._id) {
          currentAdvancedPayments[singleAdvancedPaymentIndex].RESTA = 0;
        }
      }
    }

    state.advancedPayments = currentAdvancedPayments;
  },
  EDIT_ADVANCED_PAYMENT_MISSING_QUANTITY: (state, payload) => {
    let currentAdvancedPayments = [...state.advancedPayments];

    for (const [
      singleAdvancedPaymentIndex,
      singleAdvancedPayment,
    ] of currentAdvancedPayments.entries()) {
      if (singleAdvancedPayment._id === payload._id.advancedPayment._id) {
        currentAdvancedPayments[singleAdvancedPaymentIndex].RESTA =
          payload._id.advancedPayment.RESTA;
      }
    }

    state.advancedPayments = currentAdvancedPayments;
  },
};

export default mutations;
