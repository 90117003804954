let mutations = {
  RESTORE_BANK_STATES: (state) => {
    Object.assign(state, {
      banks: [],
      bankMovementTypes: [],
      bankAccounts: [],
      // Total balance
      totalBalance: 0,
      // Consolidated total balance
      totalBalanceCon: 0,
    });
  },
  SET_BANK_ACCOUNTS: (state, payload) => {
    let allBalance = 0;
    let allBalanceCon = 0;
    for (const singleBankAccount of payload) {
      allBalance += singleBankAccount.SALDO_ACTUAL.SALDO_ACTUAL;
      allBalanceCon += singleBankAccount.SALDO_CON_ACTUAL.SALDO_ACTUAL;
    }
    state.bankAccounts = payload;
    state.totalBalance = allBalance;
    state.totalBalanceCon = allBalanceCon;
  },
  ADD_BANK_ACCOUNTS: (state, payload) => {
    let allBalance = state.totalBalance;
    let allBalanceCon = state.totalBalanceCon;
    let currentBankAccounts = [...state.bankAccounts];
    let currentBanks = [...state.banks];
    for (const singleBank of payload._id.newBanks) {
      currentBanks.push(singleBank);
    }
    state.banks = currentBanks;
    for (let singleBankAccount of payload._id.newBankAccounts) {
      let newBankAccount = singleBankAccount.newBankAccount;
      for (const singleBank of currentBanks) {
        if (singleBank._id === newBankAccount.BANCO) {
          newBankAccount.BANCO = singleBank;
        }
      }
      newBankAccount.SALDOS = [singleBankAccount.newBankAccountBalance];
      newBankAccount.SALDO_ACTUAL = singleBankAccount.newBankAccountBalance;
      newBankAccount.SALDOS_CON = [
        singleBankAccount.newBankAccountConsolidatedBalance,
      ];
      newBankAccount.SALDO_CON_ACTUAL =
        singleBankAccount.newBankAccountConsolidatedBalance;
      allBalance += singleBankAccount.newBankAccountBalance.SALDO_ACTUAL;
      allBalanceCon +=
        singleBankAccount.newBankAccountConsolidatedBalance.SALDO_ACTUAL;
      currentBankAccounts.push(newBankAccount);
    }
    state.totalBalanceCon = allBalanceCon;
    state.totalBalance = allBalance;
    state.bankAccounts = currentBankAccounts;
  },
  UPDATE_ACCOUNT: (state, payload) => {
    const curentBankAccounts = [...state.bankAccounts];
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of curentBankAccounts.entries()) {
      if (singleBankAccount.LINK === payload.LINK) {
        curentBankAccounts[singleBankAccountIndex].ACTUALIZAR_LINK = false;
      }
    }
    state.bankAccounts = curentBankAccounts;
  },
  ADD_BANK_ACCOUNT_MOVEMENTS: (state, payload) => {
    let currentBankAccounts = [...state.bankAccounts];
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.bankAccountId) {
        currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS = [
          ...currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS,
          ...payload._id.newBankMovements,
        ];
        currentBankAccounts[singleBankAccountIndex].SALDOS = [
          ...currentBankAccounts[singleBankAccountIndex].SALDOS,
          ...payload._id.newBankBalances,
        ];
        currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
          payload._id.finalBalance;
        break;
      }
    }
    let allBalance = 0;
    for (const singleBankAccount of currentBankAccounts) {
      allBalance += singleBankAccount.SALDO_ACTUAL.SALDO_ACTUAL;
    }
    state.totalBalance = allBalance;
    state.bankAccounts = currentBankAccounts;
  },
  SYNCH_BANK_ACCOUNT_MOVEMENTS: (state, payload) => {
    const currentBankAccounts = [...state.bankAccounts];
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id._id) {
        if (!singleBankAccount.MOVIMIENTOS) {
          currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS =
            payload._id.newBankMovements;
        } else {
          for (let singleMovement of payload._id.newBankMovements) {
            currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS.push(
              singleMovement
            );
          }
        }
      }
    }
    state.bankAccounts = currentBankAccounts;
  },
  SAVE_BANK: (state, payload) => {
    const currentBanks = [...state.banks];
    currentBanks.push(payload._id);
    state.banks = currentBanks;
  },
  SET_BANKS: (state, payload) => {
    state.banks = payload;
  },
  EDIT_BANKS: (state, payload) => {
    const currentBanks = [...state.banks];
    for (const [singleBankIndex, singleBank] of currentBanks.entries()) {
      if (singleBank._id === payload._id._id) {
        currentBanks[singleBankIndex] = payload._id;
      }
    }
    state.banks = currentBanks;
  },
  SAVE_BANK_MOVEMENT_TYPE: (state, payload) => {
    const currentBanksMovementTypes = [...state.bankMovementTypes];
    currentBanksMovementTypes.push(payload._id);
    state.bankMovementTypes = currentBanksMovementTypes;
  },
  SET_BANKS_MOVEMENT_TYPE: (state, payload) => {
    state.bankMovementTypes = payload;
  },
  SYNCH_BANK_ACCOUNT_BALANCES: (state, payload) => {
    try {
      // current bank accounts
      const currentBankAccounts = [...state.bankAccounts];
      // Move through all  the bank accounts and search for the one edited
      for (const [
        singleBankAccountIndex,
        singleBankAccount,
      ] of currentBankAccounts.entries()) {
        if (singleBankAccount._id === payload._id._id) {
          // Change the balance to the one obtained in server
          if (!currentBankAccounts[singleBankAccountIndex].SALDOS) {
            currentBankAccounts[singleBankAccountIndex].SALDOS = [
              ...payload._id.newBankAccountBalance,
            ];
          } else {
            currentBankAccounts[singleBankAccountIndex].SALDOS.push(
              payload._id.newBankAccountBalance
            );
          }
          currentBankAccounts[singleBankAccountIndex].SALDO_ACTUAL =
            payload._id.newBankAccountBalance;
        }
      }
      state.bankAccounts = currentBankAccounts;
    } catch (e) {
      console.log(e);
    }
  },
  CONSOLIDATE_BANK_MOVEMENTS: (state, payload) => {
    try {
      // Current bank accounts
      const currentBankAccounts = [...state.bankAccounts];
      // Total consolidated balance
      let allBalanceCon = state.totalBalanceCon;
      // Move through all  the bank accounts and search for the one edited
      for (const [
        singleBankAccountIndex,
        singleBankAccount,
      ] of currentBankAccounts.entries()) {
        // Add the consolidated movements and update the consolidated balance
        if (singleBankAccount._id === payload._id._id) {
          if (!currentBankAccounts[singleBankAccountIndex].SALDOS_CON) {
            currentBankAccounts[singleBankAccountIndex].SALDOS_CON = [
              ...payload._id.newBankAccountBalance,
            ];
          } else {
            // Add the received balances to the bank account
            for (let singleBalance of payload._id.newBalances) {
              currentBankAccounts[singleBankAccountIndex].SALDOS_CON.push(
                singleBalance
              );
            }
          }
          // Set movements as CONSOLIDADO
          for (const [
            singleMovementIndex,
            singleMovement,
          ] of currentBankAccounts[
            singleBankAccountIndex
          ].MOVIMIENTOS.entries()) {
            if (payload._id.updateMovementsIds.includes(singleMovement._id)) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleMovementIndex
              ].CONSOLIDADO = "S";
            }
          }
          if (payload._id.newBalances.length > 0) {
            currentBankAccounts[singleBankAccountIndex].SALDO_CON_ACTUAL =
              payload._id.newBalances[payload._id.newBalances.length - 1];
            allBalanceCon +=
              payload._id.newBalances[payload._id.newBalances.length - 1]
                .CAMBIO;
          }
        }
      }
      // Update variables in state
      state.bankAccounts = currentBankAccounts;
      state.totalBalanceCon = allBalanceCon;
    } catch (e) {
      console.log(e);
    }
  },
  CANCEL_MOVEMENT: (state, payload) => {
    const currentBankAccounts = [...state.bankAccounts];
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.bankAccount) {
        for (let [
          singleMovementIndex,
          singleMovement,
        ] of singleBankAccount.MOVIMIENTOS.entries()) {
          if (singleMovement._id === payload._id.movement) {
            currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
              singleMovementIndex
            ].STATUS = "CA";
          }
        }
      }
    }
    state.bankAccounts = currentBankAccounts;
  },
  CHANGE_SHOW_IN_ACCOUNTS: (state, payload) => {
    const currentBankAccounts = [...state.bankAccounts];
    for (const [
      singleBankAccountIndex,
      singleBankAccount,
    ] of currentBankAccounts.entries()) {
      if (singleBankAccount._id === payload._id.bankAccount) {
        for (let [
          singleMovementIndex,
          singleMovement,
        ] of singleBankAccount.MOVIMIENTOS.entries()) {
          if (singleMovement._id === payload._id.movement) {
            currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
              singleMovementIndex
            ].MOSTRAR_TESORERIA = payload._id.newValue;
          }
        }
      }
    }
    state.bankAccounts = currentBankAccounts;
  },
  CHANGE_SHOW_IN_ACCOUNTS_MULTIPLE: (state, payload) => {
    const currentBankAccounts = [...state.bankAccounts];

    for (const singleChangedMovement of payload._id) {
      for (const [
        singleBankAccountIndex,
        singleBankAccount,
      ] of currentBankAccounts.entries()) {
        if (singleBankAccount._id === singleChangedMovement.data.bankAccount) {
          for (let [
            singleMovementIndex,
            singleMovement,
          ] of singleBankAccount.MOVIMIENTOS.entries()) {
            if (singleMovement._id === singleChangedMovement.data.movement) {
              currentBankAccounts[singleBankAccountIndex].MOVIMIENTOS[
                singleMovementIndex
              ].MOSTRAR_TESORERIA = singleChangedMovement.data.newValue;
            }
          }
        }
      }
    }

    state.bankAccounts = currentBankAccounts;
  },
};

export default mutations;
