let mutations = {
  RESTORE_PRODUCTIVITY_STATES: (state) => {
    Object.assign(state, {
      events: [],
    });
  },
  ADD_EVENT: (state, payload) => {
    const currentEvents = [...state.events];
    currentEvents.push(payload._id);
    state.events = currentEvents;
  },
  SET_EVENTS: (state, payload) => {
    state.events = payload;
  },
  EDIT_EVENT: (state, payload) => {
    // Current events in the state
    let currentEvents = [...state.events];
    // Move through all the events and find the one that matches the id and set it to the new object
    for (const [eventIndex, event] of currentEvents.entries()) {
      if (event._id === payload._id._id) {
        currentEvents[eventIndex] = payload._id;
      }
    }
    state.events = currentEvents;
  },
  DELETE_EVENTS: (state, payload) => {
    // Current events in the state
    let currentEvents = [...state.events];
    // Move through all the events and find the one that matches the id and set it to the new object
    for (const [eventIndex, event] of currentEvents.entries()) {
      for (const singleDeleteEvent of payload._id.deletedEvents) {
        if (event._id === singleDeleteEvent) {
          currentEvents.splice(eventIndex, 1);
        }
      }
    }
    state.events = currentEvents;
  },
};

export default mutations;
