<template>
  <b-input
    :placeholder="placeholder"
    expanded
    :icon="icon"
    v-model="displayValue"
    :disabled="disabled"
    @blur="(event) => sendData(event)"
    :required="required"
  >
  </b-input>
</template>

<script>
export default {
  name: "PercentageInput",
  components: {},
  props: ["value", "placeholder", "icon", "required", "disabled"],
  data() {
    return {};
  },
  methods: {
    sendData(event) {
      let newValue = event.target.value;
      newValue = newValue.replace("%", "");
      // Ensure that it is not NaN
      if (isNaN(newValue) || newValue === undefined || newValue === null) {
        newValue = 0;
      }
      this.$emit("input", newValue);
    },
  },
  computed: {
    displayValue: {
      get: function () {
        let formatedValue = this.value;

        if (
          isNaN(formatedValue) ||
          formatedValue === undefined ||
          formatedValue === null
        ) {
          return `0%`;
        }

        return `${formatedValue}%`;
      },
      set: function (modifiedValue) {
        return modifiedValue;
      },
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
