let getters = {
  HINT: (state) => {
    return state.hint;
  },
  CATEGORIES: (state) => {
    return state.categories;
  },
  SUBCATEGORIES: (state) => {
    return state.subCategories;
  },
  SUBSUBCATEGORIES: (state) => {
    return state.subSubCategories;
  },
  SUBSUBSUBCATEGORIES: (state) => {
    return state.subSubSubCategories;
  },
  BRANDS: (state) => {
    return state.brands;
  },
  COMPANIES_EMAILS: (state) => {
    return state.companiesEmails;
  },
  PERMISSIONS: (state) => {
    return state.permissions;
  },
  USERS: (state) => {
    return state.users.filter((singleUser) => {
      return singleUser.STATUS === "AC";
    });
  },
  USERS_ALL: (state) => {
    return state.users;
  },
  VEHICULES: (state) => {
    return state.vehicules;
  },
  ACTIVE: (state) => {
    return state.active;
  },
  FORMULAS: (state) => {
    return state.formulas;
  },
  ARTICLETRANSFORMATIONS: (state) => {
    return state.articleTransformations;
  },
  ASSETS: (state) => {
    return state.assets;
  },
};

export default getters;
