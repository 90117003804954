let getters = {
  BANKACCOUNTS: (state) => {
    return state.bankAccounts;
  },
  TOTALBALANCE: (state) => {
    return state.totalBalance;
  },
  TOTALBALANCECON: (state) => {
    return state.totalBalanceCon;
  },
  BANKS: (state) => {
    return state.banks;
  },
  BANKMOVEMENTTYPES: (state) => {
    return state.bankMovementTypes;
  },
};

export default getters;
