import { getAction, saveAction } from "@/utils/store-fns";
import { serverUrl } from "@/utils/env-variables-fns";

let actions = {
  /**
   *
   * @desc gets events from the database
   * @param context the current store context
   * @param payload the payload sended from the component
   */
  GETEVENTS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/productivity/get-events`,
        "SET_EVENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Adds an event to the database
   * @param context the current store context
   * @param payload the payload sended from the component, in this case the event we want to save
   */
  ADDEVENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/productivity/save-event`,
        "ADD_EVENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes an event in the databse
   * @param context the current store context
   * @param payload the payload sended from the component, in this case the _id of the event we want to delete
   */
  DELETEEVENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/productivity/delete-events`,
        "DELETE_EVENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an event in the database
   * @param context the current store context
   * @param payload the payload sended from the component, in this case the _id of the event we want to update
   */
  EDITEVENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/productivity/edit-event`,
        "EDIT_EVENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
