import Vue from "vue";
import Vuex from "vuex";
import Main from "./modules/main";
import Productivity from "./modules/productivity";
import HumanResources from "./modules/humanResources";
import Boards from "./modules/boards";
import Purchases from "./modules/purchases";
import Sales from "./modules/sales";
import Global from "./modules/global";
import Treasury from "./modules/treasury";
import Banks from "./modules/banks";
import Accounting from "./modules/accounting";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Main,
    Productivity,
    HumanResources,
    Boards,
    Purchases,
    Global,
    Sales,
    Treasury,
    Banks,
    Accounting,
  },
});
