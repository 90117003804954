let state = {
  categories: [],
  subCategories: [],
  subSubCategories: [],
  subSubSubCategories: [],
  brands: [],
  companiesEmails: [],
  permissions: [],
  users: [],
  vehicules: [],
  hint: "No olvides que hay campos de ayuda y recordatorios en el sistema, si tienes alguna duda no olvides consultar estas secciones",
  active: "",
  formulas: [],
  articleTransformations: [],
  assets: [],
};

export default state;
