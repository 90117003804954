let mutations = {
  RESTORE_BOARDS_STATES: (state) => {
    Object.assign(state, {
      //Boards with columns and tasks
      taskBoards: [],
    });
  },
  ADDTASKBOARDS: (state, payload) => {
    state.taskBoards.push(payload);
  },
  SETTASKBOARDS: (state, payload) => {
    state.taskBoards = payload;
  },
};

export default mutations;
