/**
 * @desc Fucntion to find item in array of objects, item must have a valid _id
 * @param {*} array array of items where we will find item
 * @param {*} item item we want to find
 */
function findItemArrayObjects(array, item) {
  let found = -1;
  for (const [index, element] of array.entries()) {
    if (element._id === item._id) {
      found = index;
      break;
    }
  }
  return found;
}

function deleteItemFromArray(array, item) {
  return null;
}

/**
 * Axios' headers used in multiple calls
 */
function returnAxiosHeaders(token) {
  let config = {
    headers: {
      "access-token": token,
    },
  };
  return config;
}

/**
 * @desc chek if an error exist on the passed required values and the passed form object
 * @param {object} objectToValidate object with all the fields to validate
 * @param {object} requiredFields object with the name of the objects that are required and the error boolean
 * @returns true if the validation is passed or false if its not
 */
function validateFields(objectToValidate, requiredFields) {
  for (const [keyValidate, valueValidate] of Object.entries(objectToValidate)) {
    for (const [keyField, valueField] of Object.entries(requiredFields)) {
      if (keyValidate === keyField) {
        if (
          valueValidate === undefined ||
          valueValidate === null ||
          valueValidate === ""
        ) {
          requiredFields[keyField] = true;
        } else {
          requiredFields[keyField] = false;
        }
        break;
      }
    }
  }
  return requiredFields;
}

/**
 * @desc Checks if an error exist on the required fields object
 * @param {*} requiredFields object with the name of the required fields and the boolean status
 * @returns true or false depending on the given object if the validation is passed it returns true
 */
function checkValidationErrors(requiredFields) {
  for (const [keyField, valueField] of Object.entries(requiredFields)) {
    if (valueField === true) {
      return false;
    }
  }
  return true;
}

/**
 *
 * @param {File} xml  transform a xml file to am object so it can be manipulated
 * @returns and object with all the information in the xml
 */
function xmlToJson(xml) {
  // Create the return object
  var obj = {};

  if (xml.nodeType == 1) {
    // element
    // do attributes
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (var j = 0; j < xml.attributes.length; j++) {
        var attribute = xml.attributes.item(j);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType == 3) {
    // text
    obj = xml.nodeValue;
  }

  // do children
  if (xml.hasChildNodes()) {
    for (var i = 0; i < xml.childNodes.length; i++) {
      var item = xml.childNodes.item(i);
      var nodeName = item.nodeName;
      if (typeof obj[nodeName] == "undefined") {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (typeof obj[nodeName].push == "undefined") {
          var old = obj[nodeName];
          obj[nodeName] = [];
          obj[nodeName].push(old);
        }
        obj[nodeName].push(xmlToJson(item));
      }
    }
  }
  return obj;
}

/**
 *
 * @param {*} num number to round
 * @param {*} decimalPlaces decimal places we want in the result
 * @returns a rounded number
 */
function round(num, decimalPlaces = 0) {
  if (num < 0) return -round(-num, decimalPlaces);
  var p = Math.pow(10, decimalPlaces);
  var n = num * p;
  var f = n - Math.floor(n);
  var e = Number.EPSILON * n;

  // Determine whether this fraction is a midpoint value.
  return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
}

/**
 * @desc Returns a random number between min (inclusive) and max (exclusive)
 */
function createRandom(min, max) {
  return Math.random() * (max - min) + min;
}

function createRandomString(length) {
  let pass = (n) =>
    [...crypto.getRandomValues(new Uint8Array(n))].map(
      (x, i) => (
        (i = ((x / 255) * 61) | 0),
        String.fromCharCode(i + (i > 9 ? (i > 35 ? 61 : 55) : 48))
      )
    ).join``;

  return pass(length);
}

5;

function createRandomStringCamps(length) {
  const list = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
  var res = "";
  for (var i = 0; i < length; i++) {
    var rnd = Math.floor(Math.random() * list.length);
    res = res + list.charAt(rnd);
  }
  return res;
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

/**
 *
 * @desc functon to do a basic rule of threee
 * result = (Y1*X2)/Y1
 * @param X1 {number}
 * @param X2 {number}
 * @param Y1 {number}
 */
function basicRuleOfThree(X1, X2, Y1) {
  return (Y1 * X2) / X1;
}

/**
 *
 * @desc Receives a permissions object and checks if the user has access to the resource in the selected module
 * @param permissions the permissions object of the current user
 * @param module the module the user wants to access
 * @param resource the resource inside the module the user wants to access
 */
function checkForPermissions(permissions, module, resource) {
  return permissions[module][resource] && permissions[module][resource] === "S";
}

/**
 *
 * @desc validates a mexican rfc
 * @param {*} rfc the rfc we want to validate
 * @param {*} aceptarGenerico if it accepts generic rfc
 * @returns the refc if is valid or false if its invalid
 */
function validateRFC(rfcInput, aceptarGenerico = true) {
  let rfc = rfcInput.toUpperCase();

  const re =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  var validado = rfc.match(re);

  if (!validado)
    // Coincide con el formato general del regex?
    return false;

  // Separar el dígito verificador del resto del RFC
  const digitoVerificador = validado.pop(),
    rfcSinDigito = validado.slice(1).join(""),
    len = rfcSinDigito.length,
    // Obtener el digito esperado
    diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
    indice = len + 1;
  var suma, digitoEsperado;

  if (len == 12) suma = 0;
  else suma = 481; // Ajuste para persona moral

  for (var i = 0; i < len; i++)
    suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  digitoEsperado = 11 - (suma % 11);
  if (digitoEsperado == 11) digitoEsperado = 0;
  else if (digitoEsperado == 10) digitoEsperado = "A";

  // El dígito verificador coincide con el esperado?
  // o es un RFC Genérico (ventas a público general)?
  if (
    digitoVerificador != digitoEsperado &&
    (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")
  )
    return false;
  else if (
    !aceptarGenerico &&
    rfcSinDigito + digitoVerificador == "XEXX010101000"
  )
    return false;
  return rfcSinDigito + digitoVerificador;
}

/**
 *
 * @desc transforms a file to base 64
 * @param file the file we wnt to transform
 * @returns the file in base 64
 */
function transformFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 *
 * @desc transforms a url to base 64
 * @param url the url we wnt to transform
 * @returns the file in base 64
 */
async function transformURLToBase64(url) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

/**
 *
 * @desc capitalized a string
 * @param {String} string the string we want to capitalize
 * @returns the string capitalized
 */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

/**
 *
 * @desc eliminates decimals until desired length
 * @param {*} num the number to fixed
 * @param {*} fixed the ammount of numbers
 * @returns
 */
function toFixedNoRound(num, fixed) {
  num = num.toString();
  if (num.indexOf(".") > 0) {
    num = num.slice(0, num.indexOf(".") + fixed);
  }
  return Number(num);
}

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export {
  findItemArrayObjects,
  deleteItemFromArray,
  returnAxiosHeaders,
  validateFields,
  checkValidationErrors,
  round,
  createRandom,
  createRandomString,
  createRandomStringCamps,
  isNumber,
  basicRuleOfThree,
  checkForPermissions,
  validateRFC,
  transformFileToBase64,
  transformURLToBase64,
  capitalizeFirstLetter,
  toFixedNoRound,
  hexToRGB,
  sleep,
};
