import {
  saveAction,
  getAction,
  genericActionNoMutation,
} from "@/utils/store-fns";
import { serverUrl } from "@/utils/env-variables-fns";

let actions = {
  /**
   *
   * @desc Gets last expense number from the database
   */
  GETPAYMENTS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/treasury/get-payments`, "SET_PAYMENTS", {
        startDate: payload.startDate,
        endDate: payload.endDate,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets last expense number from the database
   */
  GETLASTEXPENSENUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/treasury/get-last-expense-number`,
        "SET_LAST_EXPENSE_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets last expense number from the database
   */
  GETEXPENSES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/treasury/get-expenses`, "SET_EXPENSES", {
        startDate: payload.startDate,
        endDate: payload.endDate,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a expense in the database
   */
  ADDEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-expense`,
        "ADD_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Adds a manual movement to an expense in the database
   */
  ADDMANUALMOVEMENTEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-manual-movement-expense`,
        "ADD_MANUAL_MOVEMENT_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an income in the database
   */
  EDITEXPENSEBASICINFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/edit-expense-basic-information`,
        "EDIT_EXPENSE_BASIC_INFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Editas a expense in the database
   */
  EDITEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/edit-expense`,
        "EDIT_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a expense in the database
   */
  CANCELEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/cancel-expense`,
        "CANCEL_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },

  /**
   *
   * @desc Gets last payment number from the database
   */
  GETLASTPAYMENTNUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/treasury/get-last-paymentNumber`,
        "SET_LAST_PURCHASE_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  ADDPAYMENTEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-payment`,
        "ADD_PAYMENT_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELPAYMENTEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/cancel-payment`,
        "CANCEL_PAYMENT_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  ADDPAYMENTINCOME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-payment-income`,
        "ADD_PAYMENT_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  ADDPAYMENTMULTIPLEINCOME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-payment-multiple-income`,
        "ADD_PAYMENT_MULTIPLE_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  ADDPAYMENTMULTIPLEEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-payment-multiple-expense`,
        "ADD_PAYMENT_MULTIPLE_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  ADDADVANCEDPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-advanced-payment`,
        "ADD_ADVANCED_PAYMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITADVANCEDPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/edit-advanced-payment`,
        "EDIT_ADVANCED_PAYMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  USEADVANCEDPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/use-advanced-payment`,
        "USE_ADVANCED_PAYMENT"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  USEADVANCEDPAYMENTINCOME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/use-advanced-payment-income`,
        "USE_ADVANCED_PAYMENT_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELPAYMENTINCOME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/cancel-payment-income`,
        "CANCEL_PAYMENT_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETINCOMES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/treasury/get-incomes`, "SET_INCOMES", {
        startDate: payload.startDate,
        endDate: payload.endDate,
      }).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves an income in the database
   */
  ADDINCOME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-income`,
        "ADD_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Adds a manual movement to an income in the database
   */
  ADDMANUALMOVEMENTINCOME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/add-manual-movement-income`,
        "ADD_MANUAL_MOVEMENT_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an income in the database
   */
  EDITINCOMEBASICINFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/edit-income-basic-information`,
        "EDIT_INCOME_BASIC_INFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Cancels an income in the database
   */
  CANCELINCOME: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/cancel-income`,
        "CANCEL_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all advanced payments from the database
   */
  GETADVANCEDPAYMENTS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/treasury/get-advanced-payments`,
        "SET_ADVANCED_PAYMENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Cancels an advanced payments from the database
   */
  CANCELADVANCEDPAYMENT: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/cancel-advanced-payment`,
        "CANCEL_ADVANCED_INCOME"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Get the client balance report
   */
  GETCLIENTBALANCEREPORT: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/treasury/generate-client-report`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Authorizes a expense in the database
   */
  AUTHORIZEEXPENSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/authorize-expense`,
        "AUTHORIZE_EXPENSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Sets incomes bellow threshold to fully paid
   */
  REMOVEMISSINGACCOUNTSINCOMES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/remove-missing-accounts-incomes`,
        "REMOVE_MISSING_ACCOUNTS_INCOMES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Sets expenses bellow threshold to fully paid
   */
  REMOVEMISSINGACCOUNTSEXPENSES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/remove-missing-accounts-expenses`,
        "REMOVE_MISSING_ACCOUNTS_EXPENSES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Sets advanced payments bellow threshold to fully paid
   */
  REMOVEMISSINGPAYMENTSPROVIDERS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/remove-missing-payments-providers`,
        "REMOVE_MISSING_PAYMENTS_PROVIDERS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Sets advanced payments bellow threshold to fully paid
   */
  REMOVEMISSINGPAYMENTSCLIENTS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/remove-missing-payments-clients`,
        "REMOVE_MISSING_PAYMENTS_CLIENTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITADVANCEDPAYMENTMISSINGQUANTITY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/treasury/edit-advanced-payment-missing-quantity`,
        "EDIT_ADVANCED_PAYMENT_MISSING_QUANTITY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
