import { EventBus } from "../event-bus";

const initialSteps = [
  {
    target: "#welcome-container",
    content: `¡Bienvenido a <strong>Coconut Control</strong>! empezaremos por dar un recorrido a las funcionalidades básicas. Recuerda que siempre puedes acceder a este turorial desde la barra de menu, última opción`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: "#hint-container",
    content: `En todas las pantallas podrás encontrar secciones de ayuda sobre lo que puede ser realizado`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: "#filter-container",
    content: `En algunas pantallas podrás encontrar campos para filtrar la información mostrada`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: "#warehouse-option",
    content: `Puedes empezar a usar Coconut Control de distintas maneras pero nosotos recomendamos iniciar dando de alta un almacén`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        EventBus.$emit("openNavbar");
        resolve("Success");
      }),
  },
  {
    target: "#actions-options",
    content: `En algunas pantallas podrás encontrar las acciones globales que puedes realizar en esta sección o acciones individuales en los detalles de los renglones de la tablas`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: ".tabs",
    content: `En algunas pantallas podrás interactuar con más de un objeto haciendo click en las pestañas en esta sección`,
    params: {
      enableScrolling: false,
      placement: "top",
    },
  },
  {
    target: "#current-columns",
    content: `En algunas pantallas podrás ocultar y mostrar las columnas mostradas en las tabla en esta sección`,
    params: {
      enableScrolling: false,
    },
  },
  {
    target: "#articles-option",
    content: `En esta pantalla podrás agregar o importar artículos y marcas a Coconut Control`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#provider-option",
    content: `En esta pantalla podrás agregar o importar proveedores a Coconut Control, ademas de consultar el catálogo de giros comerciales`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#inventory-option",
    content: `En esta pantalla podrás visualizar el inventario de cada artículo en tus almacenes. Agregar nuevo inventario o hacer transferencias entre almacenes `,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#inventory-movements-option",
    content: `En esta pantalla podrás ver los movimientos de artículos en tus almacenes (Kardex) o confirmar movimientos pendientes `,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#provider-prices",
    content: `En esta pantalla podrás ver, agregar y actualizar los costos de compra de artículos de distintos proveedores, tmabién podrás ver estadísticas de los últimos cambios de precios `,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#purchase-orders",
    content: `En esta pantalla podrás realizar pedidos y ordenes de compra`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#material-reception",
    content: `En esta pantalla podrás recibir el material de tus ordenes de compra`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#vehicules-option",
    content: `En esta pantalla podrás controlar los vehículos en tu compañía `,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#clients-option",
    content: `En esta pantalla podrás controlar prospectos y clientes, ademas de consultar los catalogos de uso del CFDI, regimenes y giros comerciales`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#prices-list",
    content: `En esta pantalla podrás controlar las listas de precios`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#prices-articules-list",
    content: `En esta pantalla podrás controlar los precios de venta de los artículos de acuerdo a las listas de precio, recuerda que el margen predeterminado será utilizado si el producto no cuenta con un margen especifico para alguna lista de precios`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#sales-options",
    content: `En esta pantalla podrás controlar las cotizaciones, ventas, conceptos de descuentos y agregar notas de crédito o aclaraciones a las ventas y cotizaciones ya realizadas`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#expenses-icomes-accounts-options",
    content: `En esta pantalla podrás controlar las cuentas por pagar y cobrar, recuerda que también puedes agregar nuevos pagos, ver el estatus de cada gasto asi como cuanto falta por pagar y estadísticas del saldo actual`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#clients-providers-accounts-options",
    content: `En esta pantalla podrás ver el resumen del estado de cuenta de los proveedores y clientes`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#bank-account-options",
    content: `En esta pantalla podrás controlar las cuentas bancarias, recuerda que puedes conectar tus cuentas bancarias o agregarlas de manera manual, ademas de poder sincronizar movimientos de manera automática o manual. No olvides consolidar tu saldo antes de usarlo`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#sat-documents-option",
    content: `En esta pantalla podrás concectar tu cuenta del SAT ver los documentos de Ingreso, Egreso, Pago, Nómina y Traslado que el SAT tiene sobra la compañía`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#calendar-option",
    content: `En esta pantalla podrás controlar los eventos, recuerda que se puedes elegir enviar recordatorios de tus eventos, ademas de poder cambiar la fecha de estos arrastrandolos a otra fecha en el calendario, también puedes configurar el envio de recordatorios`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#personal-option",
    content: `En esta pantalla podrás controlar el personal que hay en la compañía asi como crear un organigrama de la misma`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
  {
    target: "#configuration-option",
    content: `En esta pantalla podrás ver y editar la información de la compañía, también podrás conectar nuevas direcciones de correo para enviar mensajes, agregar nuevos usuarios y controlar los perfiles de permisos en el sistema`,
    params: {
      placement: "right",
    },
    before: () =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          EventBus.$emit("openNavbar");
          resolve("Success");
        }, 10);
      }),
  },
];

export { initialSteps };
