let state = {
  //Array with all the employees
  employees: [],
  //Organization board, employees of the company
  lastOrganizationBoardNumber: 1,
  organizationBoard: {
    id: "1",
    name: "Inicia aqui",
    title: "",
    children: [],
    error: false,
  },
  bonds: [],
  buyComissions: [],
  sellComissions: [],
  employeesInventory: [],
  vacations: [],
  currentEmployee: null,
};

export default state;
