import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: false });

let mutations = {
  RESTORE_SALES_STATES: (state) => {
    Object.assign(state, {
      pricesLists: [],
      clients: [],
      lastSaleNumber: 1,
      quotes: [],
      sales: [],
      lastClarificationNumber: 1,
      discountConcepts: [],
      lastIncomeNumber: 1,
      satCfdiUses: [],
      satRegimenes: [],
      commercialBusiness: [],
      paymentTypes: [],
      pos: [],
      secrets: [],
      clientsUnlocks: [],
      warehousesUnlocks: [],
    });
  },
  SAVE_PRICE_LIST: (state, payload) => {
    const currentPriceLists = [...state.pricesLists];
    currentPriceLists.push(payload._id);
    state.pricesLists = currentPriceLists;
  },
  EDIT_PRICE_LIST: (state, payload) => {
    const currentPriceLists = [...state.pricesLists];
    for (const [
      singlePriceListIndex,
      singlePriceList,
    ] of currentPriceLists.entries()) {
      if (singlePriceList._id === payload._id._id) {
        currentPriceLists[singlePriceListIndex] = payload._id;
      }
    }
    state.pricesLists = currentPriceLists;
  },
  DELETE_PRICE_LIST: (state, payload) => {
    const currentPriceLists = [...state.pricesLists];
    for (const [
      singlePriceListIndex,
      singlePriceList,
    ] of currentPriceLists.entries()) {
      if (singlePriceList._id === payload._id.priceList) {
        currentPriceLists.splice(singlePriceListIndex, 1);
      }
    }
    state.pricesLists = currentPriceLists;
  },
  SAVE_PRICE_LIST_COSTS: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];

    for (let [singleArticleIndex, singleArticle] of finalArticles.entries()) {
      if (singleArticle._id === payload._id.article._id) {
        finalArticles[singleArticleIndex] = payload._id.article;
      }
    }

    payload.context.rootState.Purchases.articles = finalArticles;
  },
  SAVE_PRICE_LIST_DISCOUNTS: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];

    for (let [singleArticleIndex, singleArticle] of finalArticles.entries()) {
      if (singleArticle._id === payload._id.article._id) {
        finalArticles[singleArticleIndex] = payload._id.article;
      }
    }

    payload.context.rootState.Purchases.articles = finalArticles;
  },
  SET_PRICES_LIST: (state, payload) => {
    state.pricesLists = payload;
  },
  UPDATE_PRICE_LIST: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];
    for (const [singleArticleIndex, singleArticle] of finalArticles.entries()) {
      for (const singleChange of payload._id.changesMade) {
        if (singleArticle._id === singleChange.CLAVE_ART) {
          if (singleArticle.COSTO_MIN_VTA !== payload._id.COSTO_MIN_VTA) {
            finalArticles[singleArticleIndex].COSTO_MIN_VTA ===
              payload._id.COSTO_MIN_VTA;
          }
          if (singleChange.mainArticle) {
            let foundPrice = false;
            // Update article information
            for (const [
              singlePriceListIndex,
              singlePriceList,
            ] of singleArticle.LISTAS_PREC.entries()) {
              if (singlePriceList.CLAVE_LISTAP === singleChange.CLAVE_LISTAP) {
                foundPrice = true;
                finalArticles[singleArticleIndex].LISTAS_PREC[
                  singlePriceListIndex
                ].PORC_UTILID = singleChange.PORC_UTILID;
                finalArticles[singleArticleIndex].LISTAS_PREC[
                  singlePriceListIndex
                ].DESCUENTOS = singleChange.DESCUENTOS;
                finalArticles[singleArticleIndex].LISTAS_PREC[
                  singlePriceListIndex
                ].COSTOS = singleChange.COSTOS;
                break;
              }
            }
            if (!foundPrice) {
              finalArticles[singleArticleIndex].LISTAS_PREC.push({
                ...singleChange,
              });
            }
          } else {
            let foundPricePresentation = false;
            // Update presentation information
            for (const [
              singlePresentationIndex,
              singlePresentation,
            ] of singleArticle.PRESENTACIONES.entries()) {
              if (singlePresentation._id === singleChange.CLAVE_PRESEN) {
                for (let [
                  singlePresentationPriceIndex,
                  singlePresentationPrice,
                ] of singlePresentation.LISTAS_PREC.entries()) {
                  if (
                    singlePresentationPrice.CLAVE_LISTAP ===
                    singleChange.CLAVE_LISTAP
                  ) {
                    foundPricePresentation = true;
                    finalArticles[singleArticleIndex].PRESENTACIONES[
                      singlePresentationIndex
                    ].LISTAS_PREC[singlePresentationPriceIndex].PORC_UTILID =
                      singleChange.PORC_UTILID;
                    finalArticles[singleArticleIndex].PRESENTACIONES[
                      singlePresentationIndex
                    ].LISTAS_PREC[singlePresentationPriceIndex].DESCUENTOS =
                      singleChange.DESCUENTOS;
                    finalArticles[singleArticleIndex].PRESENTACIONES[
                      singlePresentationIndex
                    ].LISTAS_PREC[singlePresentationPriceIndex].COSTOS =
                      singleChange.COSTOS;
                    break;
                  }
                }
                if (!foundPricePresentation) {
                  finalArticles[singleArticleIndex].PRESENTACIONES[
                    singlePresentationIndex
                  ].LISTAS_PREC.push({
                    ...singleChange,
                  });
                }
              }
            }
          }
        }
      }
    }
    payload.context.rootState.Purchases.articles = finalArticles;
  },
  SAVE_CLIENT: (state, payload) => {
    const currentClients = [...state.clients];
    currentClients.push(payload._id);
    state.clients = currentClients;
  },
  SET_CLIENTS: (state, payload) => {
    state.clients = payload;
  },
  EDIT_CLIENT: (state, payload) => {
    const currentClients = [...state.clients];
    for (const [singleClientIndex, singleClient] of currentClients.entries()) {
      if (singleClient._id === payload._id._id) {
        currentClients[singleClientIndex] = payload._id;
      }
    }
    state.clients = currentClients;
  },
  SET_LAST_SALE_NUMBER: (state, payload) => {
    state.lastSaleNumber = payload.lastSaleNumber;
  },
  ADD_QUOTE: (state, payload) => {
    state.quotes.push(payload._id.updatedSale);
    state.lastSaleNumber = payload._id.updatedSale.CLAVE_VENTA + 1;
  },
  ADD_SALE: (state, payload) => {
    try {
      const finalSales = [...state.sales];
      const finalClients = [...state.clients];
      const finalIncomes = [...payload.context.rootState.Treasury.incomes];
      const finalClientsUnlocks = [...state.clientsUnlocks];
      const finalWarehousesUnlocks = [...state.warehousesUnlocks];
      const finalAdvancedPayments = [
        ...payload.context.rootState.Treasury.advancedPayments,
      ];

      finalSales.push(payload._id.updatedSale);
      finalIncomes.push(payload._id.newIncome);
      state.lastSaleNumber = payload._id.updatedSale.CLAVE_VENTA + 1;

      //Change status for client unlock if needed
      if (payload._id.updatedSale.DESBLOQUEO_CLIENTE) {
        for (const [
          singleClientUnlockIndex,
          singleClientUnlock,
        ] of finalClientsUnlocks.entries()) {
          if (
            singleClientUnlock._id ===
            payload._id.updatedSale.DESBLOQUEO_CLIENTE
          ) {
            finalClientsUnlocks[singleClientUnlockIndex].STATUS = "DE";
            finalClientsUnlocks[
              singleClientUnlockIndex
            ].DESCRIPCION = `Código usado en venta con folio ${payload._id.updatedSale.CLAVE_VENTA}`;
          }
        }
      }

      //Change status for client unlock if needed
      if (payload._id.updatedSale.DESBLOQUEO_ALMACEN) {
        for (const [
          singleWarehouseUnlockIndex,
          singleWarehouseUnlock,
        ] of finalWarehousesUnlocks.entries()) {
          if (
            singleWarehouseUnlock._id ===
            payload._id.updatedSale.DESBLOQUEO_ALMACEN
          ) {
            finalWarehousesUnlocks[singleWarehouseUnlockIndex].STATUS = "DE";
            finalWarehousesUnlocks[
              singleWarehouseUnlockIndex
            ].DESCRIPCION = `Código usado en venta con folio ${payload._id.updatedSale.CLAVE_VENTA}`;
          }
        }
      }

      // update client balance
      for (const [singleClientIndex, singleClient] of finalClients.entries()) {
        if (singleClient._id === payload._id.updatedSale.CLAVE_CLIENTE._id) {
          if (payload._id.newBalance) {
            finalClients[singleClientIndex].BALANCE_FIN =
              payload._id.newBalance.SALDO_F;
            if (!finalClients[singleClientIndex].BALANCES) {
              finalClients[singleClientIndex].BALANCES = [
                payload._id.newBalance,
              ];
            } else {
              finalClients[singleClientIndex].BALANCES.push(
                payload._id.newBalance
              );
            }
          }

          if (payload._id.creditUpdated && payload._id.creditUpdated.changed) {
            if (payload._id.creditUpdated.clientCreditGroup) {
              for (const singleClientGroup of payload._id.creditUpdated
                .clientCreditGroup.CLAVE_CLIENTES) {
                for (const [
                  singleCurrentClientIndex,
                  singleCurrentClient,
                ] of finalClients.entries()) {
                  if (
                    singleCurrentClient._id.toString() ===
                    singleClientGroup.toString()
                  ) {
                    finalClients[singleCurrentClientIndex].BALANCE_CRED +=
                      payload._id.creditUpdated.total;
                  }
                }
              }
            }
          } else {
            finalClients[singleClientIndex].BALANCE_CRED +=
              payload._id.creditUpdated.total;
          }
        }
      }

      // Update the advanced payment remainig value
      if (
        (payload._id.updatedSale.requireInvoice ||
          payload._id.updatedSale.requireInvoiceAfterSend) &&
        payload._id.updatedSale.useAdvancedPayment
      ) {
        for (const [
          singleAdvancedPaymentIndex,
          singleAdvancedPayment,
        ] of finalAdvancedPayments.entries()) {
          if (
            singleAdvancedPayment._id === payload._id.updatedSale.CLAVE_ANTICIPO
          ) {
            finalAdvancedPayments[singleAdvancedPaymentIndex].RESTA -=
              payload._id.updatedSale.CANTIDAD_ANTICIPO;
          }
        }
      }

      state.sales = finalSales;
      state.clients = finalClients;
      payload.context.rootState.Treasury.incomes = finalIncomes;
      state.clientsUnlocks = finalClientsUnlocks;
      payload.context.rootState.Treasury.advancedPayments =
        finalAdvancedPayments;

      // Commit kardex mutation
      payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);

      if (payload._id.creditNote) {
        payload.context.commit("SAVE_CREDIT_NOTE", {
          ...payload,
          _id: { ...payload._id.creditNote },
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  ADD_PROFESSIONAL_SERVICES_SALE: (state, payload) => {
    try {
      const finalSales = [...state.porofesionalServicesSales];
      const finalClients = [...state.clients];
      const finalIncomes = [...payload.context.rootState.Treasury.incomes];

      finalSales.push(payload._id.updatedSale);
      finalIncomes.push(payload._id.newIncome);
      state.lastSaleNumber = payload._id.updatedSale.CLAVE_VENTA + 1;

      // update client balance
      for (const [singleClientIndex, singleClient] of finalClients.entries()) {
        if (singleClient._id === payload._id.updatedSale.CLAVE_CLIENTE._id) {
          if (payload._id.newBalance) {
            finalClients[singleClientIndex].BALANCE_FIN =
              payload._id.newBalance.SALDO_F;
            if (!finalClients[singleClientIndex].BALANCES) {
              finalClients[singleClientIndex].BALANCES = [
                payload._id.newBalance,
              ];
            } else {
              finalClients[singleClientIndex].BALANCES.push(
                payload._id.newBalance
              );
            }
          }

          if (payload._id.creditUpdated && payload._id.creditUpdated.changed) {
            if (payload._id.creditUpdated.clientCreditGroup) {
              for (const singleClientGroup of payload._id.creditUpdated
                .clientCreditGroup.CLAVE_CLIENTES) {
                for (const [
                  singleCurrentClientIndex,
                  singleCurrentClient,
                ] of finalClients.entries()) {
                  if (
                    singleCurrentClient._id.toString() ===
                    singleClientGroup.toString()
                  ) {
                    finalClients[singleCurrentClientIndex].BALANCE_CRED +=
                      payload._id.creditUpdated.total;
                  }
                }
              }
            }
          } else {
            finalClients[singleClientIndex].BALANCE_CRED +=
              payload._id.creditUpdated.total;
          }
        }
      }

      state.porofesionalServicesSales = finalSales;
      state.clients = finalClients;
      payload.context.rootState.Treasury.incomes = finalIncomes;
    } catch (error) {
      console.log(error);
    }
  },
  CANCEL_SALE: (state, payload) => {
    const finalSales = [...state.sales];
    const finalClients = [...state.clients];
    const finalIncomes = [...payload.context.rootState.Treasury.incomes];
    const finalAdvancedPayments = [
      ...payload.context.rootState.Treasury.advancedPayments,
    ];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    let stampsToDecrease = 0;

    // Update sale status to cancelled
    for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
      if (singleSale._id === payload._id.saleId) {
        finalSales[singleSaleIndex].STATUS = "CA";
        finalSales[singleSaleIndex].STATUS_FAC = "CA";

        // Cancel incomes
        for (const [
          currentInvoiceIndex,
          currentInvoice,
        ] of singleSale.FACTURAS.entries()) {
          for (const singleInvoice of payload._id.invoiceCancellations) {
            if (currentInvoice._id === singleInvoice.invoice) {
              finalSales[singleSaleIndex].FACTURAS[currentInvoiceIndex].STATUS =
                "CA";
            }
          }
        }

        // Update sale incomes to cancelled
        for (const [
          singleIncomeIndex,
          singleIncome,
        ] of singleSale.CUENTAS_COB.entries()) {
          // Cancel sale incomes
          finalSales[singleSaleIndex].CUENTAS_COB[singleIncomeIndex].STATUS =
            "CA";

          // Cancel system incomes
          for (const [
            singleFinalIncomeIndex,
            singleFinalIncome,
          ] of finalIncomes.entries()) {
            if (singleFinalIncome._id === singleIncome._id) {
              finalIncomes[singleFinalIncomeIndex].STATUS = "CA";
            }
          }
        }

        // Update advanced payment information if needed
        if (
          !singleSale.requireInvoice &&
          !singleSale.requireInvoiceAfterSend &&
          singleSale.CLAVE_ANTICIPO
        ) {
          for (const [
            singleAdvancedPaymentIndex,
            singleAdvancedPayment,
          ] of finalAdvancedPayments.entries()) {
            if (
              singleAdvancedPayment._id.toString() ===
              singleSale.CLAVE_ANTICIPO.toString()
            ) {
              finalAdvancedPayments[singleAdvancedPaymentIndex].RESTA +=
                singleSale.CANTIDAD_ANTICIPO;
            }
          }
        }
      }
    }

    // update client balance
    for (const [singleClientIndex, singleClient] of finalClients.entries()) {
      if (singleClient._id === payload._id.saleInformation.CLAVE_CLIENTE._id) {
        finalClients[singleClientIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;

        finalClients[singleClientIndex].BALANCES.push(payload._id.newBalance);

        if (payload._id.saleInformation.CONDICION_P === "R") {
          if (payload._id.clientCreditGroup) {
            for (const singleClientGroup of payload._id.clientCreditGroup
              .CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of finalClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  finalClients[singleCurrentClientIndex].BALANCE_CRED -=
                    payload._id.totalReturn;
                }
              }
            }
          } else {
            finalClients[singleClientIndex].BALANCE_CRED -=
              payload._id.totalReturn;
          }
        }
      }
    }

    stampsToDecrease = payload._id.invoiceCancellations.length;

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= stampsToDecrease;
    } else {
      companyInformation.availableStamps -= stampsToDecrease;
    }

    state.sales = finalSales;
    state.clients = finalClients;
    payload.context.rootState.Treasury.incomes = finalIncomes;
    payload.context.rootState.Main.companyInformation = companyInformation;
    payload.context.rootState.Treasury.advancedPayments = finalAdvancedPayments;

    // Commit kardex mutation
    payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);
    payload.context.commit("VIRTUAL_MATERIAL_MOVEMENT", payload);

    // Commit pos manual movement if needed
    if (payload._id.posCancellation) {
      payload.context.commit("ADD_MANUAL_MOVEMENT", {
        _id: payload._id.posCancellation,
      });
    }

    if (payload._id.paymentCancellations) {
      for (const singlePaymentCancellation of payload._id
        .paymentCancellations) {
        payload.context.commit("CANCEL_PAYMENT_INCOME", {
          ...payload,
          _id: singlePaymentCancellation,
        });
      }
    }
  },
  CANCEL_PROFESSIONAL_SERVICES_SALE: (state, payload) => {
    const finalSales = [...state.porofesionalServicesSales];
    const finalClients = [...state.clients];
    const finalIncomes = [...payload.context.rootState.Treasury.incomes];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    let stampsToDecrease = 0;

    // Update sale status to cancelled
    for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
      if (singleSale._id === payload._id.saleInformation._id) {
        finalSales[singleSaleIndex].STATUS = "CA";
        finalSales[singleSaleIndex].STATUS_FAC = "CA";

        // Cancel invoices
        for (const [
          currentInvoiceIndex,
          currentInvoice,
        ] of singleSale.FACTURAS.entries()) {
          for (const singleInvoice of payload._id.invoiceCancellations) {
            if (currentInvoice._id === singleInvoice.invoice) {
              finalSales[singleSaleIndex].FACTURAS[currentInvoiceIndex].STATUS =
                "CA";
            }
          }
        }

        // Update sale incomes to cancelled
        for (const [
          singleIncomeIndex,
          singleIncome,
        ] of singleSale.CUENTAS_COB.entries()) {
          // Cancel sale incomes
          finalSales[singleSaleIndex].CUENTAS_COB[singleIncomeIndex].STATUS =
            "CA";

          // Cancel system incomes
          for (const [
            singleFinalIncomeIndex,
            singleFinalIncome,
          ] of finalIncomes.entries()) {
            if (singleFinalIncome._id === singleIncome._id) {
              finalIncomes[singleFinalIncomeIndex].STATUS = "CA";
            }
          }
        }
      }
    }

    // update client balance
    for (const [singleClientIndex, singleClient] of finalClients.entries()) {
      if (singleClient._id === payload._id.saleInformation.CLAVE_CLIENTE._id) {
        finalClients[singleClientIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;

        finalClients[singleClientIndex].BALANCES.push(payload._id.newBalance);

        if (payload._id.saleInformation.CONDICION_P === "R") {
          if (payload._id.clientCreditGroup) {
            for (const singleClientGroup of payload._id.clientCreditGroup
              .CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of finalClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  finalClients[singleCurrentClientIndex].BALANCE_CRED -=
                    payload._id.totalReturn;
                }
              }
            }
          } else {
            finalClients[singleClientIndex].BALANCE_CRED -=
              payload._id.totalReturn;
          }
        }
      }
    }

    stampsToDecrease = payload._id.invoiceCancellations.length;

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= stampsToDecrease;
    } else {
      companyInformation.availableStamps -= stampsToDecrease;
    }

    state.porofesionalServicesSales = finalSales;
    state.clients = finalClients;
    payload.context.rootState.Treasury.incomes = finalIncomes;
    payload.context.rootState.Main.companyInformation = companyInformation;

    if (payload._id.paymentCancellations) {
      for (const singlePaymentCancellation of payload._id
        .paymentCancellations) {
        payload.context.commit("CANCEL_PAYMENT_INCOME", {
          ...payload,
          _id: singlePaymentCancellation,
        });
      }
    }
  },
  SET_QUOTES: (state, payload) => {
    state.quotes = payload;
  },
  SET_SALES: (state, payload) => {
    state.sales = payload;
  },
  EDIT_SALE: (state, payload) => {
    const currentSales = [...state.sales];
    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id._id) {
        currentSales[singleSaleIndex] = payload._id;
      }
    }
    state.sales = currentSales;
  },
  EDIT_PROFESSIONAL_SERVICES_SALE: (state, payload) => {
    const currentSales = [...state.porofesionalServicesSales];
    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id._id) {
        currentSales[singleSaleIndex] = payload._id;
      }
    }
    state.porofesionalServicesSales = currentSales;
  },
  EDIT_QUOTE: (state, payload) => {
    const currentQuotes = [...state.quotes];
    for (const [singleQuoteIndex, singleQuote] of currentQuotes.entries()) {
      if (singleQuote._id === payload._id._id) {
        currentQuotes[singleQuoteIndex] = payload._id;
      }
    }
    state.quotes = currentQuotes;
  },
  DELETE_QUOTES: (state, payload) => {
    const currentQuotes = [...state.quotes];
    const finalQuotes = [];
    for (const singleQuote of currentQuotes) {
      if (!payload._id.objectsDeleted.includes(singleQuote._id)) {
        finalQuotes.push(singleQuote);
      }
    }
    state.quotes = finalQuotes;
  },
  CONFIRM_QUOTE: (state, payload) => {
    try {
      const finalQuotes = [...state.quotes];
      const finalSales = [...state.sales];
      const finalClients = [...state.clients];
      const finalIncomes = [...payload.context.rootState.Treasury.incomes];
      const finalArticles = [...payload.context.rootState.Purchases.articles];
      const finalClientsUnlocks = [...state.clientsUnlocks];
      const finalAdvancedPayments = [
        ...payload.context.rootState.Treasury.advancedPayments,
      ];

      //Change status for client unlock if needed
      if (payload._id.updatedSale.DESBLOQUEO_CLIENTE) {
        for (const [
          singleClientUnlockIndex,
          singleClientUnlock,
        ] of finalClientsUnlocks.entries()) {
          if (
            singleClientUnlock._id ===
            payload._id.updatedSale.DESBLOQUEO_CLIENTE
          ) {
            finalClientsUnlocks[singleClientUnlockIndex].STATUS = "DE";
            finalClientsUnlocks[
              singleClientUnlockIndex
            ].DESCRIPCION = `Código usado en venta con folio ${payload._id.updatedSale.CLAVE_VENTA}`;
          }
        }
      }

      // Update articles last sale date
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        for (const singleSaleArticle of payload._id.updatedSale.VENTA_DET) {
          if (singleArticle._id === singleSaleArticle.CLAVE_ART._id) {
            finalArticles[singleArticleIndex].FE_ULTVTA = new Date();
            break;
          }
        }
      }

      // Remove quote
      for (const [singleSaleKey, singleSale] of state.quotes.entries()) {
        if (singleSale._id === payload._id.updatedSale._id) {
          finalQuotes.splice(singleSaleKey, 1);
          break;
        }
      }

      // update client balance
      for (const [singleClientIndex, singleClient] of finalClients.entries()) {
        if (singleClient._id === payload._id.updatedSale.CLAVE_CLIENTE._id) {
          if (payload._id.newBalance) {
            finalClients[singleClientIndex].BALANCE_FIN =
              payload._id.newBalance.SALDO_F;
            if (!finalClients[singleClientIndex].BALANCES) {
              finalClients[singleClientIndex].BALANCES = [
                payload._id.newBalance,
              ];
            } else {
              finalClients[singleClientIndex].BALANCES.push(
                payload._id.newBalance
              );
            }
          }

          if (payload._id.creditUpdated && payload._id.creditUpdated.changed) {
            if (payload._id.creditUpdated.clientCreditGroup) {
              for (const singleClientGroup of payload._id.creditUpdated
                .clientCreditGroup.CLAVE_CLIENTES) {
                for (const [
                  singleCurrentClientIndex,
                  singleCurrentClient,
                ] of finalClients.entries()) {
                  if (
                    singleCurrentClient._id.toString() ===
                    singleClientGroup.toString()
                  ) {
                    finalClients[singleCurrentClientIndex].BALANCE_CRED +=
                      payload._id.creditUpdated.total;
                  }
                }
              }
            }
          } else {
            finalClients[singleClientIndex].BALANCE_CRED +=
              payload._id.creditUpdated.total;
          }
        }
      }

      // Update the advanced payment remainig value
      if (payload._id.updatedSale.useAdvancedPayment) {
        for (const singleAdvancedPayment of finalAdvancedPayments) {
          if (
            singleAdvancedPayment._id === payload._id.updatedSale.CLAVE_ANTICIPO
          ) {
            singleAdvancedPayment.RESTA -=
              payload._id.updatedSale.CANTIDAD_ANTICIPO;
          }
        }
      }

      finalSales.push(payload._id.updatedSale);
      finalIncomes.push(payload._id.newIncome);

      state.quotes = finalQuotes;
      state.sales = finalSales;
      state.clients = finalClients;
      payload.context.rootState.Purchases.articles = finalArticles;
      payload.context.rootState.Treasury.incomes = finalIncomes;
      payload.context.rootState.Treasury.advancedPayments =
        finalAdvancedPayments;

      // Commit kardex mutation
      payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);

      // Commit save invoice mutation
      if (payload._id.invoices) {
        payload.context.commit("SAVE_INVOICE", payload);
      }

      if (payload._id.creditNote) {
        payload.context.commit("SAVE_CREDIT_NOTE", {
          ...payload,
          _id: { ...payload._id.creditNote },
        });
      }
    } catch (error) {
      console.log(error);
    }
  },
  SEND_MATERIAL: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];
    let finalWarehouses = [...payload.context.rootState.Purchases.warehouses];
    let finalSales = [...state.sales];

    for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
          if (singleArticle.CANT_ALMACENES) {
            let found = false;
            for (const [
              singleWarehouseQuantityIndex,
              singleWarehouseQuantity,
            ] of singleArticle.CANT_ALMACENES.entries()) {
              if (singleWarehouseQuantity._id === singleArticleWarehouse._id) {
                found = true;
                finalArticles[singleArticleIndex].CANT_ALMACENES[
                  singleWarehouseQuantityIndex
                ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
                finalArticles[singleArticleIndex].CANT_ALMACENES[
                  singleWarehouseQuantityIndex
                ].CANTIDAD_VIRTUAL += Number(singleArticleWarehouse.CANTIDAD);
              }
            }
            if (found === false) {
              finalArticles[singleArticleIndex].CANT_ALMACENES.push({
                ...singleArticleWarehouse,
              });
            }
          } else {
            finalArticles[singleArticleIndex].CANT_ALMACENES = [
              { ...singleArticleWarehouse },
            ];
          }
          break;
        }
      }
    }

    let allTransfersDone = [...payload._id.transfersDone];
    for (const [
      singleTrasferIndex,
      singleTransfer,
    ] of allTransfersDone.entries()) {
      for (const [
        singleWarehouseindex,
        singleWarehouse,
      ] of finalWarehouses.entries()) {
        for (let currentWarehouse of finalWarehouses) {
          if (currentWarehouse._id === singleTransfer.ORIGEN) {
            allTransfersDone[singleTrasferIndex].ORIGEN = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentWarehouse of finalWarehouses) {
          if (currentWarehouse._id === singleTransfer.DESTINO) {
            allTransfersDone[singleTrasferIndex].DESTINO = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentArticle of finalArticles) {
          if (currentArticle._id === singleTransfer.CLAVE_ART) {
            allTransfersDone[singleTrasferIndex].CLAVE_ART = {
              _id: currentArticle._id,
              CLAVE_ART: currentArticle.CLAVE_ART,
              DESCRIPCION: currentArticle.DESCRIPCION,
            };
            break;
          }
        }

        if (
          finalWarehouses[singleWarehouseindex]._id === singleTransfer.CLAVE_ALM
        ) {
          if (finalWarehouses[singleWarehouseindex].CLAVE_TRANS) {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS.push(
              allTransfersDone[singleTrasferIndex]
            );
          } else {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS = [
              allTransfersDone[singleTrasferIndex],
            ];
          }
        }
      }
    }

    for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
      if (
        singleSale._id.toString() === payload._id.saleInformation._id.toString()
      ) {
        finalSales[singleSaleIndex] = payload._id.saleInformation;
      }
    }

    payload.context.rootState.Purchases.articles = finalArticles;
    payload.context.rootState.Purchases.warehouses = finalWarehouses;
    payload.context.rootState.Purchases.lastWarehouseTransferNumber =
      payload._id.lastWarehouseTransferNumber;

    state.sales = finalSales;
  },
  DELIVER_ALL_MATERIAL: (state, payload) => {
    let currentSales = [...state.sales];

    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id.toString() === payload._id.sale.toString()) {
        currentSales[singleSaleIndex].STATUS_ENV = payload._id.type;
      }
    }

    state.sales = currentSales;
  },
  DELIVER_ALL_DRIVER_MATERIAL: (state, payload) => {
    let currentSales = [...state.sales];

    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id.sale) {
        for (const [
          singleDeliveriesIndex,
          singleDeliveries,
        ] of singleSale.ENVIOS_MATERIAL.entries()) {
          if (singleDeliveries._id === payload._id.materialDelivery) {
            currentSales[singleSaleIndex].ENVIOS_MATERIAL[
              singleDeliveriesIndex
            ].STATUS_ENV = payload._id.type;
          }
        }
      }
    }

    state.sales = currentSales;
  },
  SAVE_MATERIAL_DELIVERY_SIGN: (state, payload) => {
    let currentSales = [...state.sales];

    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id.sale) {
        for (const [
          singleMaterialDeliveryIndex,
          singleMaterialDelivery,
        ] of singleSale.ENVIOS_MATERIAL.entries()) {
          if (singleMaterialDelivery._id === payload._id.materialDelivery) {
            currentSales[singleSaleIndex].ENVIOS_MATERIAL[
              singleMaterialDeliveryIndex
            ].FIRMA = payload._id.signData;
          }
        }
      }
    }

    state.sales = currentSales;
  },
  SAVE_DRIVER_MATERIAL_DELIVERY: (state, payload) => {
    let currentSales = [...state.sales];

    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id.sale) {
        for (const [
          singleMaterialDeliveryIndex,
          singleMaterialDelivery,
        ] of singleSale.ENVIOS_MATERIAL.entries()) {
          if (singleMaterialDelivery._id === payload._id.materialDelivery) {
            currentSales[singleSaleIndex].ENVIOS_MATERIAL[
              singleMaterialDeliveryIndex
            ].FIRMA = payload._id.signData;
            currentSales[singleSaleIndex].ENVIOS_MATERIAL[
              singleMaterialDeliveryIndex
            ].FIRMA_CLI = payload._id.signDataClient;
            currentSales[singleSaleIndex].ENVIOS_MATERIAL[
              singleMaterialDeliveryIndex
            ].OBSERVA = payload._id.observations;
            currentSales[singleSaleIndex].ENVIOS_MATERIAL[
              singleMaterialDeliveryIndex
            ].OBSERVA_CLI = payload._id.observationsClient;
          }
        }
      }
    }

    state.sales = currentSales;
  },
  SALE_MATERIAL_MOVEMENT: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];
    let finalWarehouses = [...payload.context.rootState.Purchases.warehouses];

    // Save all single article warehouse in the correct aricles
    if (payload._id.articlesWarehousesSave) {
      for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of finalArticles.entries()) {
          if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
            if (singleArticle.CANT_ALMACENES) {
              let found = false;
              for (const [
                singleWarehouseQuantityIndex,
                singleWarehouseQuantity,
              ] of singleArticle.CANT_ALMACENES.entries()) {
                if (
                  singleWarehouseQuantity._id === singleArticleWarehouse._id
                ) {
                  found = true;
                  if (singleArticleWarehouse.CANTIDAD) {
                    finalArticles[singleArticleIndex].CANT_ALMACENES[
                      singleWarehouseQuantityIndex
                    ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
                  }

                  if (singleArticleWarehouse.CANTIDAD_VENTAS) {
                    finalArticles[singleArticleIndex].CANT_ALMACENES[
                      singleWarehouseQuantityIndex
                    ].CANTIDAD_VENTAS += Number(
                      singleArticleWarehouse.CANTIDAD_VENTAS
                    );
                  }
                }
              }
              if (found === false) {
                finalArticles[singleArticleIndex].CANT_ALMACENES.push([
                  { ...singleArticleWarehouse },
                ]);
              }
            } else {
              finalArticles[singleArticleIndex].CANT_ALMACENES = [
                { ...singleArticleWarehouse },
              ];
            }
            break;
          }
        }
      }
    }

    // Save transfers done
    if (payload._id.transfersDone) {
      let allTransfersDone = [...payload._id.transfersDone];
      // Save transfers in correct warehouse
      for (const [
        singleTrasferIndex,
        singleTransfer,
      ] of allTransfersDone.entries()) {
        for (const [
          singleWarehouseindex,
          singleWarehouse,
        ] of finalWarehouses.entries()) {
          // Add origin warehouse to the one in transfers array
          for (let currentWarehouse of finalWarehouses) {
            if (currentWarehouse._id === singleTransfer.ORIGEN) {
              allTransfersDone[singleTrasferIndex].ORIGEN = {
                _id: currentWarehouse._id,
                CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
                NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
                DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
                TIPO: currentWarehouse.TIPO,
                UBICACION: currentWarehouse.UBICACION,
                CP_ALM: currentWarehouse.CP_ALM,
              };
              break;
            }
          }

          // Add destination warehoue to the one in transfers array
          for (let currentWarehouse of finalWarehouses) {
            if (currentWarehouse._id === singleTransfer.DESTINO) {
              allTransfersDone[singleTrasferIndex].DESTINO = {
                _id: currentWarehouse._id,
                CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
                NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
                DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
                TIPO: currentWarehouse.TIPO,
                UBICACION: currentWarehouse.UBICACION,
                CP_ALM: currentWarehouse.CP_ALM,
              };
              break;
            }
          }

          // Add article object tot the one in trasnfers array
          for (let currentArticle of finalArticles) {
            if (currentArticle._id === singleTransfer.CLAVE_ART) {
              allTransfersDone[singleTrasferIndex].CLAVE_ART = {
                _id: currentArticle._id,
                CLAVE_ART: currentArticle.CLAVE_ART,
                DESCRIPCION: currentArticle.DESCRIPCION,
              };
              break;
            }
          }

          if (
            finalWarehouses[singleWarehouseindex]._id ===
            singleTransfer.CLAVE_ALM
          ) {
            if (finalWarehouses[singleWarehouseindex].CLAVE_TRANS) {
              finalWarehouses[singleWarehouseindex].CLAVE_TRANS.push(
                allTransfersDone[singleTrasferIndex]
              );
            } else {
              finalWarehouses[singleWarehouseindex].CLAVE_TRANS = [
                allTransfersDone[singleTrasferIndex],
              ];
            }
          }
        }
      }
    }

    payload.context.rootState.Purchases.warehouses = finalWarehouses;
    payload.context.rootState.Purchases.articles = finalArticles;

    if (payload._id.lastWarehouseTransferNumber) {
      payload.context.rootState.Purchases.lastWarehouseTransferNumber =
        payload._id.lastWarehouseTransferNumber;
    }
  },
  VIRTUAL_MATERIAL_MOVEMENT: (state, payload) => {
    let finalArticles = [...payload.context.rootState.Purchases.articles];
    let finalWarehouses = [...payload.context.rootState.Purchases.warehouses];

    // Save all single article warehouse in the correct articles
    if (payload._id.vitualWarehouseMovements) {
      for (const singleArticleWarehouse of payload._id.vitualWarehouseMovements
        .articlesWarehousesSave) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of finalArticles.entries()) {
          if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
            if (singleArticle.CANT_ALMACENES) {
              let found = false;
              for (const [
                singleWarehouseQuantityIndex,
                singleWarehouseQuantity,
              ] of singleArticle.CANT_ALMACENES.entries()) {
                if (
                  singleWarehouseQuantity._id === singleArticleWarehouse._id
                ) {
                  found = true;
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD_VIRTUAL += Number(
                    singleArticleWarehouse.CANTIDAD_VIRTUAL
                  );
                }
              }
              if (found === false) {
                finalArticles[singleArticleIndex].CANT_ALMACENES.push([
                  { ...singleArticleWarehouse },
                ]);
              }
            } else {
              finalArticles[singleArticleIndex].CANT_ALMACENES = [
                { ...singleArticleWarehouse },
              ];
            }
            break;
          }
        }
      }
    }

    payload.context.rootState.Purchases.warehouses = finalWarehouses;
    payload.context.rootState.Purchases.articles = finalArticles;
  },
  SET_LAST_INCOME_NUMBER: (state, payload) => {
    state.lastIncomeNumber = payload.lastIncomeNumber;
  },
  SET_LAST_CLARIFICATION_NUMBER: (state, payload) => {
    state.lastClarificationNumber = payload.lastClarificationNumber;
  },
  SET_LAST_CREDIT_NOTE_NUMBER: (state, payload) => {
    state.lastCreditNoteNumber = payload.lastCreditNoteNumber;
  },
  SAVE_DISCOUNT_CONCEPTS: (state, payload) => {
    const currentDiscountConcepts = [...state.discountConcepts];
    currentDiscountConcepts.push(payload._id);
    state.discountConcepts = currentDiscountConcepts;
  },
  SET_DISCOUNT_CONCEPTS: (state, payload) => {
    state.discountConcepts = payload;
  },
  SAVE_CLARIFICATION: (state, payload) => {
    const finalSales = [...state.sales];
    const finalClients = [...state.clients];
    const finalIncomes = [...payload.context.rootState.Treasury.incomes];
    const finalAdvancedPayments = [
      ...payload.context.rootState.Treasury.advancedPayments,
    ];

    // Update final advanced payment if needed
    if (payload._id.saleInformation.CLAVE_ANTICIPO) {
      for (const [
        singleAdvancedPaymentIndex,
        singleAdvancedPayment,
      ] of finalAdvancedPayments.entries()) {
        if (
          singleAdvancedPayment._id.toString() ===
          payload._id.saleInformation.CLAVE_ANTICIPO.toString()
        ) {
          finalAdvancedPayments[singleAdvancedPaymentIndex].RESTA +=
            payload._id.updatedClarification.TOTAL_GTO;
        }
      }
    }

    // Update sale detail information
    for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
      // Find sale with the same _id
      if (singleSale._id === payload._id.saleInformation._id) {
        finalSales[singleSaleIndex] = payload._id.saleInformation;
      }
    }

    // Update income
    if (payload._id.updatedIncomeInformation) {
      for (const [singleIncomeIndex, singleIncome] of finalIncomes.entries()) {
        if (singleIncome._id === payload._id.updatedIncomeInformation._id) {
          finalIncomes[singleIncomeIndex] =
            payload._id.updatedIncomeInformation;
        }
      }
    }

    // update client balance
    for (const [singleClientIndex, singleClient] of finalClients.entries()) {
      if (singleClient._id === payload._id.saleInformation.CLAVE_CLIENTE._id) {
        finalClients[singleClientIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!finalClients[singleClientIndex].BALANCES) {
          finalClients[singleClientIndex].BALANCES = [payload._id.newBalance];
        } else {
          finalClients[singleClientIndex].BALANCES.push(payload._id.newBalance);
        }

        if (payload._id.creditUpdated && payload._id.creditUpdated.changed) {
          if (payload._id.creditUpdated.clientCreditGroup) {
            for (const singleClientGroup of payload._id.creditUpdated
              .clientCreditGroup.CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of finalClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  finalClients[singleCurrentClientIndex].BALANCE_CRED +=
                    payload._id.creditUpdated.total;
                }
              }
            }
          }
        } else {
          finalClients[singleClientIndex].BALANCE_CRED +=
            payload._id.creditUpdated.total;
        }
      }
    }

    state.lastClarificationNumber =
      Number(payload._id.updatedClarification.CLAVE_ACLARACION) + 1;
    state.sales = finalSales;
    state.clients = finalClients;
    payload.context.rootState.Treasury.incomes = finalIncomes;
    payload.context.rootState.Treasury.advancedPayments = finalAdvancedPayments;

    // Commit kardex mutation
    if (payload._id.updatedClarification.TIPO === "D") {
      payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);
      payload.context.commit("VIRTUAL_MATERIAL_MOVEMENT", payload);
    }

    // Commit pos manual movement if needed
    if (payload._id.posCancellation) {
      payload.context.commit("ADD_MANUAL_MOVEMENT", {
        _id: payload._id.posCancellation,
      });
    }
  },
  CANCEL_CLARIFICATION: (state, payload) => {
    try {
      const finalSales = [...state.sales];
      const finalClients = [...state.clients];
      const finalIncomes = [...payload.context.rootState.Treasury.incomes];

      // Update sale detail information
      for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
        // Find sale with the same _id
        if (singleSale._id === payload._id.saleInformation._id) {
          finalSales[singleSaleIndex] = payload._id.saleInformation;
        }
      }

      // Update income
      if (payload._id.updatedIncomeInformation) {
        for (const [
          singleIncomeIndex,
          singleIncome,
        ] of finalIncomes.entries()) {
          if (singleIncome._id === payload._id.updatedIncomeInformation._id) {
            finalIncomes[singleIncomeIndex] =
              payload._id.updatedIncomeInformation;
          }
        }
      }

      // update client balance
      for (const [singleClientIndex, singleClient] of finalClients.entries()) {
        if (
          singleClient._id === payload._id.saleInformation.CLAVE_CLIENTE._id
        ) {
          finalClients[singleClientIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!finalClients[singleClientIndex].BALANCES) {
            finalClients[singleClientIndex].BALANCES = [payload._id.newBalance];
          } else {
            finalClients[singleClientIndex].BALANCES.push(
              payload._id.newBalance
            );
          }

          if (payload._id.saleInformation.CONDICION_P === "R") {
            if (payload._id.clientCreditGroup) {
              for (const singleClientGroup of payload._id.clientCreditGroup
                .CLAVE_CLIENTES) {
                for (const [
                  singleCurrentClientIndex,
                  singleCurrentClient,
                ] of finalClients.entries()) {
                  if (
                    singleCurrentClient._id.toString() ===
                    singleClientGroup.toString()
                  ) {
                    finalClients[singleCurrentClientIndex].BALANCE_CRED +=
                      payload._id.updatedClarification.TOTAL_GTO;
                  }
                }
              }
            } else {
              finalClients[singleClientIndex].BALANCE_CRED +=
                payload._id.updatedClarification.TOTAL_GTO;
            }
          }
        }
      }

      state.sales = finalSales;
      state.clients = finalClients;
      payload.context.rootState.Treasury.incomes = finalIncomes;

      // Commit kardex mutation
      if (payload._id.updatedClarification.TIPO === "D") {
        payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);
        payload.context.commit("VIRTUAL_MATERIAL_MOVEMENT", payload);
      }
    } catch (error) {
      console.log(error);
    }
  },
  SAVE_CREDIT_NOTE: (state, payload) => {
    const finalSales = [...state.sales];
    const finalClients = [...state.clients];
    const finalIncomes = [...payload.context.rootState.Treasury.incomes];
    const finalAdvancedPayments = [
      ...payload.context.rootState.Treasury.advancedPayments,
    ];

    // Update sale detail information
    for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
      // Find sale with the same _id
      if (singleSale._id === payload._id.saleInformation._id) {
        finalSales[singleSaleIndex] = payload._id.saleInformation;
      }
    }

    // Update income
    if (payload._id.updatedIncomeInformation) {
      for (const [singleIncomeIndex, singleIncome] of finalIncomes.entries()) {
        if (singleIncome._id === payload._id.updatedIncomeInformation._id) {
          finalIncomes[singleIncomeIndex] =
            payload._id.updatedIncomeInformation;
        }
      }
    }

    // Update advanced payment
    if (payload._id.updatedAdvancedPaymentInformation) {
      for (const [
        singleAdvancedPaymentIndex,
        singleAdvancedPayment,
      ] of finalAdvancedPayments.entries()) {
        if (
          singleAdvancedPayment._id ===
          payload._id.updatedAdvancedPaymentInformation._id
        ) {
          finalAdvancedPayments[singleAdvancedPaymentIndex] =
            payload._id.updatedAdvancedPaymentInformation;
        }
      }
    }

    // Update client balance
    for (const [singleClientIndex, singleClient] of finalClients.entries()) {
      if (singleClient._id === payload._id.saleInformation.CLAVE_CLIENTE._id) {
        finalClients[singleClientIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!finalClients[singleClientIndex].BALANCES) {
          finalClients[singleClientIndex].BALANCES = [payload._id.newBalance];
        } else {
          finalClients[singleClientIndex].BALANCES.push(payload._id.newBalance);
        }

        if (payload._id.creditUpdated && payload._id.creditUpdated.changed) {
          if (payload._id.creditUpdated.clientCreditGroup) {
            for (const singleClientGroup of payload._id.creditUpdated
              .clientCreditGroup.CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of finalClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  finalClients[singleCurrentClientIndex].BALANCE_CRED +=
                    payload._id.creditUpdated.total;
                }
              }
            }
          }
        } else {
          finalClients[singleClientIndex].BALANCE_CRED +=
            payload._id.creditUpdated.total;
        }
      }
    }

    state.lastCreditNoteNumber =
      Number(payload._id.updatedCreditNote.CLAVE_NOTAC) + 1;
    state.sales = finalSales;
    state.clients = finalClients;
    payload.context.rootState.Treasury.incomes = finalIncomes;
    payload.context.rootState.Treasury.advancedPayments = finalAdvancedPayments;

    // Commit kardex mutation
    if (payload._id.updatedCreditNote.TIPO === "D") {
      payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);
      payload.context.commit("VIRTUAL_MATERIAL_MOVEMENT", payload);
    }
    // Commit save invoice credit note mutation
    if (payload._id.invoice) {
      payload.context.commit("SAVE_INVOICE_CREDIT_NOTE", payload);
    }

    // Commit pos manual movement if needed
    if (payload._id.posCancellation) {
      payload.context.commit("ADD_MANUAL_MOVEMENT", {
        _id: payload._id.posCancellation,
      });
    }
  },
  SAVE_GLOBAL_CREDIT_NOTE: (state, payload) => {
    const currentDailyInvoices = [
      ...payload.context.rootState.Accounting.dailyInvoices,
    ];
    const creditNotesInvoices = [
      ...payload.context.rootState.Accounting.creditNotesInvoices,
    ];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    if (payload._id.creditNoteInvoice) {
      creditNotesInvoices.push(payload._id.creditNoteInvoice);
      for (const [
        singleDailyInvoiceIndex,
        singleDailyInvoice,
      ] of currentDailyInvoices.entries()) {
        if (singleDailyInvoice._id === payload._id.invoiceId) {
          const newCreditNote = payload._id.updatedCreditNote;
          newCreditNote.STATUS_FAC = "TI";
          currentDailyInvoices[singleDailyInvoiceIndex].NOTAC.push(
            payload._id.updatedCreditNote
          );
        }
      }

      // Decrease one stamp
      if (companyInformation.availableMonthlyStamps > 0) {
        companyInformation.availableMonthlyStamps -= 1;
      } else {
        companyInformation.availableStamps -= 1;
      }
    }

    payload.context.rootState.Accounting.creditNotesInvoices =
      creditNotesInvoices;
    payload.context.rootState.Accounting.dailyInvoices = currentDailyInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  CANCEL_CREDIT_NOTE_DOCUMENT: (state, payload) => {
    const finalSales = [...state.sales];
    const finalClients = [...state.clients];
    const finalIncomes = [...payload.context.rootState.Treasury.incomes];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };
    const finalAdvancedPayments = [
      ...payload.context.rootState.Treasury.advancedPayments,
    ];

    let stampsToDecrease = 0;

    // Update sale detail information
    for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
      // Find sale with the same _id
      if (singleSale._id === payload._id.saleInformation._id) {
        finalSales[singleSaleIndex] = payload._id.saleInformation;

        if (payload._id.updatedCreditNote.TIPO === "A") {
          for (const [
            singleAdvancedPaymentIndex,
            singleAdvancedPayment,
          ] of finalAdvancedPayments.entries()) {
            if (
              singleAdvancedPayment._id.toString() ===
              singleSale.CLAVE_ANTICIPO.toString()
            ) {
              finalAdvancedPayments[singleAdvancedPaymentIndex].RESTA +=
                payload._id.updatedCreditNote.TOTAL_GTO;
            }
          }
        }
      }
    }

    // Update income
    if (payload._id.updatedIncomeInformation) {
      for (const [singleIncomeIndex, singleIncome] of finalIncomes.entries()) {
        if (singleIncome._id === payload._id.updatedIncomeInformation._id) {
          finalIncomes[singleIncomeIndex] =
            payload._id.updatedIncomeInformation;
        }
      }
    }

    // update client balance
    for (const [singleClientIndex, singleClient] of finalClients.entries()) {
      if (singleClient._id === payload._id.saleInformation.CLAVE_CLIENTE._id) {
        finalClients[singleClientIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!finalClients[singleClientIndex].BALANCES) {
          finalClients[singleClientIndex].BALANCES = [payload._id.newBalance];
        } else {
          finalClients[singleClientIndex].BALANCES.push(payload._id.newBalance);
        }

        if (payload._id.saleInformation.CONDICION_P === "R") {
          if (payload._id.clientCreditGroup) {
            for (const singleClientGroup of payload._id.clientCreditGroup
              .CLAVE_CLIENTES) {
              for (const [
                singleCurrentClientIndex,
                singleCurrentClient,
              ] of finalClients.entries()) {
                if (
                  singleCurrentClient._id.toString() ===
                  singleClientGroup.toString()
                ) {
                  finalClients[singleCurrentClientIndex].BALANCE_CRED +=
                    payload._id.updatedCreditNote.TOTAL_GTO;
                }
              }
            }
          } else {
            finalClients[singleClientIndex].BALANCE_CRED +=
              payload._id.updatedCreditNote.TOTAL_GTO;
          }
        }
      }
    }

    stampsToDecrease = payload._id.invoiceCancellations.length;

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= stampsToDecrease;
    } else {
      companyInformation.availableStamps -= stampsToDecrease;
    }

    state.sales = finalSales;
    state.clients = finalClients;
    payload.context.rootState.Treasury.incomes = finalIncomes;
    payload.context.rootState.Main.companyInformation = companyInformation;
    payload.context.rootState.Treasury.advancedPayments = finalAdvancedPayments;

    // Commit kardex mutation
    if (payload._id.updatedCreditNote.TIPO === "D") {
      payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);
      payload.context.commit("VIRTUAL_MATERIAL_MOVEMENT", payload);
    }
  },
  CANCEL_GLOBAL_CREDIT_NOTE_DOCUMENT: (state, payload) => {
    const currentDailyInvoices = [
      ...payload.context.rootState.Accounting.dailyInvoices,
    ];
    const creditNotesInvoices = [
      ...payload.context.rootState.Accounting.creditNotesInvoices,
    ];
    const companyInformation = {
      ...payload.context.rootState.Main.companyInformation,
    };

    for (const [
      singleDailyInvoiceIndex,
      singleDailyInvoice,
    ] of currentDailyInvoices.entries()) {
      if (singleDailyInvoice._id === payload._id.invoiceId) {
        for (const [
          singleCreditNoteIndex,
          singleCreditNote,
        ] of singleDailyInvoice.NOTAC.entries()) {
          if (singleCreditNote._id === payload._id.creditNote) {
            currentDailyInvoices[singleDailyInvoiceIndex].NOTAC[
              singleCreditNoteIndex
            ].STATUS = "CA";
          }
        }
      }
    }

    // Decrease one stamp
    if (companyInformation.availableMonthlyStamps > 0) {
      companyInformation.availableMonthlyStamps -= 1;
    } else {
      companyInformation.availableStamps -= 1;
    }

    payload.context.rootState.Accounting.creditNotesInvoices =
      creditNotesInvoices;
    payload.context.rootState.Accounting.dailyInvoices = currentDailyInvoices;
    payload.context.rootState.Main.companyInformation = companyInformation;
  },
  SET_SAT_CFDI_USES: (state, payload) => {
    state.satCfdiUses = payload;
  },
  SET_SAT_REGIMENES: (state, payload) => {
    state.satRegimenes = payload;
  },
  SAVE_COMMERCIAL_BUSINESS: (state, payload) => {
    const currentCommercialBusiness = [...state.commercialBusiness];
    currentCommercialBusiness.push(payload._id);
    state.commercialBusiness = currentCommercialBusiness;
  },
  SET_COMMERCIAL_BUSINESS: (state, payload) => {
    state.commercialBusiness = payload;
  },
  EDIT_COMMERCIAL_BUSINESS: (state, payload) => {
    const currentCommercialBusiness = [...state.commercialBusiness];
    for (const [
      singleCommercialBusinessIndex,
      singleCommercialBusiness,
    ] of currentCommercialBusiness.entries()) {
      if (singleCommercialBusiness._id === payload._id._id) {
        currentCommercialBusiness[singleCommercialBusinessIndex] = payload._id;
      }
    }
    state.commercialBusiness = currentCommercialBusiness;
  },
  SET_PAYMENT_TYPES: (state, payload) => {
    state.paymentTypes = payload;
  },
  SAVE_PAYMENT_TYPE: (state, payload) => {
    const currentPaymentTypes = [...state.paymentTypes];
    currentPaymentTypes.push(payload._id);
    state.paymentTypes = currentPaymentTypes;
  },
  SET_POS: (state, payload) => {
    state.pos = payload;
  },
  SAVE_POS: (state, payload) => {
    const currentUserInformation = { ...payload.context.rootState.Main.user };
    const currentUsers = [...payload.context.rootState.Global.users];
    const currentPos = [...state.pos];

    // Push new pos to current array
    currentPos.push(payload._id);

    // Move through all the users in the sytem
    for (const singlePosUser of payload._id.USUARIOS) {
      // Check if user is the one changed
      if (currentUserInformation.email === singlePosUser.email) {
        currentUserInformation.pointOfSale = payload._id;

        // Update current user local storage variable
        ls.set(
          currentUserInformation.companyMainName + "ERPFCBUser",
          JSON.stringify(currentUserInformation)
        );
      }

      for (const [singleUserIndex, singleUser] of currentUsers.entries()) {
        // If user its the same as one of the ones used in the pos then add the pos to user
        if (singleUser.email === singlePosUser.email) {
          currentUsers[singleUserIndex].pointOfSale = payload._id;
        }
      }
    }

    payload.context.rootState.Main.user = currentUserInformation;
    payload.context.rootState.Global.users = currentUsers;
    state.pos = currentPos;
  },
  EDIT_POS: (state, payload) => {
    try {
      const currentUserInformation = { ...payload.context.rootState.Main.user };
      const currentUsers = [...payload.context.rootState.Global.users];
      const currentPos = [...state.pos];

      // Move through all the users in the sytem and delete pos from old users
      for (const singlePosUser of payload._id.pos.USUARIOS) {
        // Check if user is the one changed
        if (currentUserInformation.email === singlePosUser.email) {
          currentUserInformation.pointOfSale = undefined;
        }

        for (const [singleUserIndex, singleUser] of currentUsers.entries()) {
          if (singlePosUser === singleUser._id) {
            currentUsers[singleUserIndex].pointOfSale = undefined;
          }
        }
      }

      // Move trough all the pos in the system and update the one needed
      for (const [singlePosIndex, singlePos] of currentPos.entries()) {
        if (singlePos._id === payload._id.updatedPos._id) {
          currentPos[singlePosIndex] = payload._id.updatedPos;
        }
      }

      // Move through all the users in the sytem and add pos to new users
      for (const singlePosUser of payload._id.updatedPos.USUARIOS) {
        // Check if user is the one changed
        if (currentUserInformation.email === singlePosUser.email) {
          currentUserInformation.pointOfSale = payload._id.updatedPos;
        }

        for (const [singleUserIndex, singleUser] of currentUsers.entries()) {
          // If user its the same as one of the ones used in the pos then add the pos to user
          if (singleUser.email === singlePosUser.email) {
            currentUsers[singleUserIndex].pointOfSale = payload._id.updatedPos;
          }
        }
      }

      // Update current user local storage variable
      ls.set(
        currentUserInformation.companyMainName + "ERPFCBUser",
        JSON.stringify(currentUserInformation)
      );

      payload.context.rootState.Main.user = currentUserInformation;
      payload.context.rootState.Global.users = currentUsers;
      state.pos = currentPos;
    } catch (error) {
      console.log(error);
    }
  },
  OPEN_POS: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and update pos
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id === payload._id._id) {
        currentPos[singlePosIndex].STATUS = "AB";
        currentPos[singlePosIndex].SALDO_INICIAL = payload._id.SALDO_INICIAL;
        currentPos[singlePosIndex].CORTES = payload._id.CORTES;
      }
    }

    state.pos = currentPos;
  },
  OPEN_AGAIN_POS: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and update pos
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id === payload._id.posId) {
        currentPos[singlePosIndex].STATUS = "AB";
        currentPos[singlePosIndex].CORTES[singlePos.CORTES.length - 1].STATUS =
          "AB";
      }
    }

    state.pos = currentPos;
  },
  SAVE_POS_SALE: (state, payload) => {
    try {
      const currentPos = [...state.pos];

      // Move through all the pos in the system to find the used one
      for (const [singlePosIndex, singlePos] of currentPos.entries()) {
        if (singlePos._id === payload._id.pos) {
          // Move trough all the pending sales to find the saved sale
          for (const [singleSaleIndex, singleSale] of singlePos.CORTES[
            singlePos.CORTES.length - 1
          ].VENTAS_PENDIENTES.entries()) {
            // Remove missing sale from array
            if (singleSale._id === payload._id.ticket) {
              // Remove missing sale
              currentPos[singlePosIndex].CORTES[
                singlePos.CORTES.length - 1
              ].VENTAS_PENDIENTES.splice(singleSaleIndex, 1);
            }
          }
        }
      }

      // Commit kardex mutation
      payload.context.commit("SALE_MATERIAL_MOVEMENT", payload);

      state.lastSaleNumber = payload._id.updatedSale.CLAVE_VENTA + 1;
      state.pos = currentPos;
    } catch (error) {
      console.log(error);
    }
  },
  CLOSE_POS: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and update pos and corte
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id === payload._id.posId) {
        currentPos[singlePosIndex].STATUS = "CE";
        currentPos[singlePosIndex].SALDO_INICIAL = 0;
        for (const [
          singleCorteIndex,
          singleCorte,
        ] of singlePos.CORTES.entries()) {
          if (singleCorte._id === payload._id.corte._id) {
            currentPos[singlePosIndex].CORTES[singleCorteIndex] =
              payload._id.corte;
          }
        }
      }
    }

    state.pos = currentPos;
  },
  AUTHORIZE_CORTE: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and update pos and corte
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id.toString() === payload._id.posId.toString()) {
        for (const [
          singleCorteIndex,
          singleCorte,
        ] of singlePos.CORTES.entries()) {
          if (singleCorte._id.toString() === payload._id.corte.toString()) {
            currentPos[singlePosIndex].CORTES[singleCorteIndex].STATUS = "AU";
          }
        }
      }
    }

    state.pos = currentPos;
  },
  NO_AUTHORIZE_CORTE: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and update pos and corte
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id.toString() === payload._id.posId.toString()) {
        for (const [
          singleCorteIndex,
          singleCorte,
        ] of singlePos.CORTES.entries()) {
          if (singleCorte._id.toString() === payload._id.corte.toString()) {
            currentPos[singlePosIndex].CORTES[singleCorteIndex].STATUS = "NA";
          }
        }
      }
    }

    state.pos = currentPos;
  },
  ADD_MANUAL_MOVEMENT: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and add the manual movement
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id === payload._id.posId) {
        for (const [
          singleCorteIndex,
          singleCorte,
        ] of singlePos.CORTES.entries()) {
          if (singleCorte._id === payload._id.corte) {
            if (payload._id.manualMovement) {
              currentPos[singlePosIndex].CORTES[
                singleCorteIndex
              ].MOVIMIENTOS_MANUALES.push(payload._id.manualMovement);
            }
          }
        }
      }
    }

    state.pos = currentPos;
  },
  SAVE_MISSING_SALE: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and add the manual movement
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id === payload.posId) {
        for (const [
          singleCorteIndex,
          singleCorte,
        ] of singlePos.CORTES.entries()) {
          if (singleCorte._id === payload.corte) {
            currentPos[singlePosIndex].CORTES[
              singleCorteIndex
            ].VENTAS_PENDIENTES.push(payload.missingSale);
          }
        }
      }
    }

    state.pos = currentPos;
  },
  SAVE_QUOTE_POS: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and add the manual movement
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id === payload.posId) {
        currentPos[singlePosIndex].COTIZACIONES.push(payload.missingSale);
      }
    }

    state.pos = currentPos;
  },
  DELETE_QUOTE_POS: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and add the manual movement
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      if (singlePos._id === payload.posId) {
        for (const [singleQuoteIndex, singleQuote] of currentPos[
          singlePosIndex
        ].COTIZACIONES.entries()) {
          if (singleQuote._id === payload.quoteId) {
            currentPos[singlePosIndex].COTIZACIONES.splice(singleQuoteIndex, 1);
          }
        }
      }
    }

    state.pos = currentPos;
  },
  CONFIRM_QUOTE_POS: (state, payload) => {
    const currentPos = [...state.pos];

    // Move through all the users in the sytem and add the manual movement
    for (const [singlePosIndex, singlePos] of currentPos.entries()) {
      // find pos id editted in server
      if (singlePos._id === payload.posId) {
        // Move trough the quotes
        for (const [singleQuoteIndex, singleQuote] of currentPos[
          singlePosIndex
        ].COTIZACIONES.entries()) {
          // Find the quotes editted in the server
          if (singleQuote._id === payload.quoteId) {
            // Move trough the cortes
            for (const [singleCorteIndex, singleCorte] of currentPos[
              singlePosIndex
            ].CORTES.entries()) {
              // Find the corte editted in the server
              if (singleCorte._id === payload.corteId) {
                currentPos[singlePosIndex].COTIZACIONES.splice(
                  singleQuoteIndex,
                  1
                );
                currentPos[singlePosIndex].CORTES[
                  singleCorteIndex
                ].VENTAS_PENDIENTES.push(payload.quote);
              }
            }
          }
        }
      }
    }

    state.pos = currentPos;
  },
  DELETE_MISSING_SALE: (state, payload) => {
    try {
      const currentPos = [...state.pos];

      // Move through all the users in the sytem and add the manual movement
      for (const singlePos of currentPos) {
        if (singlePos._id === payload._id.pos) {
          for (const [singleSaleIndex, singleSale] of singlePos.CORTES[
            singlePos.CORTES.length - 1
          ].VENTAS_PENDIENTES.entries()) {
            if (singleSale._id === payload._id.missingSale) {
              singlePos.CORTES[
                singlePos.CORTES.length - 1
              ].VENTAS_PENDIENTES.splice(singleSaleIndex, 1);
            }
          }
        }
      }

      state.pos = currentPos;
    } catch (error) {
      console.log(error);
    }
  },
  SAVE_SECRET: (state, payload) => {
    const currentSecrets = [...state.secrets];

    currentSecrets.push(payload._id.secret);

    state.secrets = currentSecrets;
  },
  SET_SECRETS: (state, payload) => {
    state.secrets = payload;
  },
  EDIT_SECRET: (state, payload) => {
    const currentSecrets = [...state.secrets];

    for (const [singleSecretIndex, singleSecret] of currentSecrets.entries()) {
      if (singleSecret._id === payload._id.secret._id) {
        currentSecrets[singleSecretIndex] = payload._id.secret;
      }
    }

    state.secrets = currentSecrets;
  },
  DELETE_SECRETS: (state, payload) => {
    const currentSecrets = [...state.secrets];

    for (const singleSecretDelete of payload._id.objectsDeleted) {
      for (const [
        singleSecretIndex,
        singleSecret,
      ] of currentSecrets.entries()) {
        if (singleSecret._id === singleSecretDelete) {
          currentSecrets.splice(singleSecretIndex, 1);
          break;
        }
      }
    }

    state.secrets = currentSecrets;
  },
  PRINT_OR_SEND_SALE_QUOTE: (state, payload) => {
    let currentQuotes = [...state.quotes];
    let currentSales = [...state.sales];

    // Add one to the IMPRESO variable inside of a quote
    for (const [singleQuoteIndex, singleQuote] of currentQuotes.entries()) {
      if (singleQuote._id === payload._id) {
        currentQuotes[singleQuoteIndex].IMPRESO += 1;
      }
    }

    // Add one to the IMPRESO variable inside of a sale
    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id) {
        currentSales[singleSaleIndex].IMPRESO += 1;
      }
    }

    state.quotes = currentQuotes;
    state.sales = currentSales;
  },
  SAVE_CLIENT_UNLOCK: (state, payload) => {
    const currentClientsUnlocks = [...state.clientsUnlocks];

    currentClientsUnlocks.push(payload._id.clientUnlock);

    state.clientsUnlocks = currentClientsUnlocks;
  },
  SET_CLIENTS_UNLOCKS: (state, payload) => {
    state.clientsUnlocks = payload;
  },
  DELETE_CLIENTS_UNLOCKS: (state, payload) => {
    const currentClientsUnlocks = [...state.clientsUnlocks];

    for (const singleSecretDelete of payload._id.objectsDeleted) {
      for (const [
        singleSecretIndex,
        singleSecret,
      ] of currentClientsUnlocks.entries()) {
        if (singleSecret._id === singleSecretDelete) {
          currentClientsUnlocks.splice(singleSecretIndex, 1);
          break;
        }
      }
    }

    state.clientsUnlocks = currentClientsUnlocks;
  },
  SAVE_WAREHOUSE_UNLOCK: (state, payload) => {
    const currentWarehousesUnlocks = [...state.warehousesUnlocks];

    currentWarehousesUnlocks.push(payload._id.warehouseUnlock);

    state.warehousesUnlocks = currentWarehousesUnlocks;
  },
  SET_WAREHOUSES_UNLOCKS: (state, payload) => {
    state.warehousesUnlocks = payload;
  },
  DELETE_WAREHOUSES_UNLOCKS: (state, payload) => {
    const currentWarehousesUnlocks = [...state.warehousesUnlocks];

    for (const singleSecretDelete of payload._id.objectsDeleted) {
      for (const [
        singleSecretIndex,
        singleSecret,
      ] of currentWarehousesUnlocks.entries()) {
        if (singleSecret._id === singleSecretDelete) {
          currentWarehousesUnlocks.splice(singleSecretIndex, 1);
          break;
        }
      }
    }

    state.warehousesUnlocks = currentWarehousesUnlocks;
  },
  EDIT_MATERIAL_DELIVERY_SALE: (state, payload) => {
    const currentSales = [...state.sales];
    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id.sale) {
        currentSales[singleSaleIndex].FECHA_ENVIO =
          payload._id.newInformation.FECHA_ENVIO;
        currentSales[singleSaleIndex].clientPickup =
          payload._id.newInformation.clientPickup;
        currentSales[singleSaleIndex].CLAVE_VEHICULO =
          payload._id.newInformation.CLAVE_VEHICULO;
        if (payload._id.newInformation.VEHICULO_INFO) {
          currentSales[singleSaleIndex].VEHICULO_INFO =
            payload._id.newInformation.VEHICULO_INFO;
        }
      }
    }
    state.sales = currentSales;
  },
  EDIT_PARTIAL_SALE: (state, payload) => {
    const currentSales = [...state.sales];

    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id.sale) {
        currentSales[singleSaleIndex].VENTA_PARCIAL =
          payload._id.newPartialValue.VENTA_PARCIAL;
      }
    }

    state.sales = currentSales;
  },
  EDIT_PARTIAL_SALE_ORDER: (state, payload) => {
    const currentSales = [...state.sales];

    for (const [singleSaleIndex, singleSale] of currentSales.entries()) {
      if (singleSale._id === payload._id.sale) {
        currentSales[singleSaleIndex].VENTA_PARCIAL_ORDEN =
          payload._id.newPartialValue.VENTA_PARCIAL_ORDEN;
      }
    }

    state.sales = currentSales;
  },
  SET_PROFESSIONAL_SERVICES_SALES: (state, payload) => {
    state.porofesionalServicesSales = payload;
  },
  SAVE_SERVICE: (state, payload) => {
    const currentServices = [...state.services];
    currentServices.push(payload._id);
    state.services = currentServices;
  },
  SET_SERVICES: (state, payload) => {
    state.services = payload;
  },
  EDIT_SERVICE: (state, payload) => {
    const currentServices = [...state.services];
    for (const [
      currentServiceIndex,
      currentService,
    ] of currentServices.entries()) {
      if (currentService._id === payload._id._id) {
        currentServices[currentServiceIndex] = payload._id;
      }
    }
    state.services = currentServices;
  },
  ADD_CLIENT_BALANCE_ENTRY: (state, payload) => {
    const finalClients = [...state.clients];

    for (const [singleClientIndex, singleClient] of finalClients.entries()) {
      if (singleClient._id === payload._id.clientId) {
        if (payload._id.newBalance) {
          finalClients[singleClientIndex].BALANCE_FIN =
            payload._id.newBalance.SALDO_F;
          if (!finalClients[singleClientIndex].BALANCES) {
            finalClients[singleClientIndex].BALANCES = [payload._id.newBalance];
          } else {
            finalClients[singleClientIndex].BALANCES.push(
              payload._id.newBalance
            );
          }
        }
      }
    }

    state.clients = finalClients;
  },
  EDIT_CLIENT_CREDIT: (state, payload) => {
    const finalClients = [...state.clients];

    for (const [singleClientIndex, singleClient] of finalClients.entries()) {
      if (payload._id.clientCreditGroup) {
        for (const singleClientGroup of payload._id.clientCreditGroup
          .CLAVE_CLIENTES) {
          for (const [
            singleCurrentClientIndex,
            singleCurrentClient,
          ] of finalClients.entries()) {
            if (
              singleCurrentClient._id.toString() ===
              singleClientGroup.toString()
            ) {
              finalClients[singleCurrentClientIndex].BALANCE_CRED =
                payload._id.balance;
            }
          }
        }
      } else {
        if (singleClient._id === payload._id.clientId) {
          finalClients[singleClientIndex].BALANCE_CRED = payload._id.balance;
        }
      }
    }

    state.clients = finalClients;
  },
  SET_CLIENT_CREDIT_GROUP: (state, payload) => {
    state.clientsCreditGroups = payload;
  },
  SAVE_CLIENT_CREDIT_GROUP: (state, payload) => {
    const finalClientsCreditGroups = [...state.clientsCreditGroups];

    finalClientsCreditGroups.push(payload._id.clientCreditGroup);

    state.clientsCreditGroups = finalClientsCreditGroups;
  },
  UPDATE_CLIENT_CREDIT_GROUP: (state, payload) => {
    const finalClients = [...state.clients];
    const finalClientsCreditGroups = [...state.clientsCreditGroups];

    for (const singleOldClient of payload._id.oldInformation.CLAVE_CLIENTES) {
      for (const [singleClientIndex, singleClient] of finalClients.entries()) {
        if (singleClient._id.toString() === singleOldClient.toString()) {
          finalClients[singleClientIndex].CLAVE_AGRUPACION = undefined;
          finalClients[singleClientIndex].LIMITE_CRED = 0;
          finalClients[singleClientIndex].BALANCE_CRED = 0;
        }
      }
    }

    for (const singleCreditGroupClient of payload._id.clientCreditGroup
      .CLAVE_CLIENTES) {
      for (const [singleClientIndex, singleClient] of finalClients.entries()) {
        if (
          singleClient._id.toString() === singleCreditGroupClient._id.toString()
        ) {
          finalClients[singleClientIndex].CLAVE_AGRUPACION =
            payload._id.clientCreditGroup._id;
          finalClients[singleClientIndex].LIMITE_CRED =
            payload._id.clientCreditGroup;
          finalClients[singleClientIndex].BALANCE_CRED = 0;
        }
      }
    }

    for (const [
      singleClientCreditGroupIndex,
      singleClientCreditGroup,
    ] of finalClientsCreditGroups.entries()) {
      if (
        singleClientCreditGroup._id.toString() ===
        payload._id.clientCreditGroup._id.toString()
      ) {
        finalClientsCreditGroups[singleClientCreditGroupIndex] =
          payload._id.clientCreditGroup;
      }
    }

    state.clientsCreditGroups = finalClientsCreditGroups;
    state.clients = finalClients;
  },
  DELETE_CLIENT_CREDIT_GROUP: (state, payload) => {
    const finalClients = [...state.clients];
    const finalClientsCreditGroups = [...state.clientsCreditGroups];

    for (const singleOldClient of payload._id.objectDeleted.CLAVE_CLIENTES) {
      for (const [singleClientIndex, singleClient] of finalClients.entries()) {
        if (singleClient._id.toString() === singleOldClient.toString()) {
          finalClients[singleClientIndex].CLAVE_AGRUPACION = undefined;
          finalClients[singleClientIndex].LIMITE_CRED = 0;
          finalClients[singleClientIndex].BALANCE_CRED = 0;
        }
      }
    }

    for (const [
      singleClientCreditGroupIndex,
      singleClientCreditGroup,
    ] of finalClientsCreditGroups.entries()) {
      if (
        singleClientCreditGroup._id.toString() ===
        payload._id.objectDeleted._id.toString()
      ) {
        finalClientsCreditGroups.splice(singleClientCreditGroupIndex, 1);
      }
    }

    state.clients = finalClients;
    state.clientsCreditGroups = finalClientsCreditGroups;
  },
  VALIDATE_SALE_MATERIAL_SUBSTRACTION: (state, payload) => {},
};

export default mutations;
