import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);
const routes = [
  // Purchases module and routes
  {
    path: "/Purchases",
    name: "Purchases",
    component: () => import("../modules/Purchases/views/PurchasesList"),
    children: [
      {
        path: "Articles",
        component: () =>
          import("../modules/Purchases/views/ArticlesComponentsContainer"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allArticles",
          },
        },
      },
      {
        path: "Prediction",
        component: () =>
          import(
            "../modules/Purchases/views/PredictionsComponentsContianer.vue"
          ),
        meta: {
          permissions: {
            module: "main",
            resource: "makePrediction",
          },
        },
      },
      {
        path: "Presentation",
        component: () =>
          import("../modules/Purchases/components/ArticlesPresentation"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allArticles",
          },
        },
      },
      {
        path: "Providers",
        component: () => import("../modules/Purchases/views/AllProvidersList"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allProviders",
          },
        },
      },
      {
        path: "ProvidersListsArticles",
        component: () =>
          import("../modules/Purchases/components/ProvidersListArticles"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allProviders",
          },
        },
      },
      {
        path: "PurchaseOrders",
        component: () =>
          import(
            "../modules/Purchases/views/PurchaseOrdersComponentsContainer"
          ),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allPurchaseOrders",
          },
        },
      },
      {
        path: "ReceiveMaterial",
        component: () =>
          import("../modules/Purchases/views/MaterialReceptionComponents"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "receiveMaterial",
          },
        },
      },
      {
        path: "PurchasesRevision",
        component: () =>
          import("../modules/Purchases/views/PurchasesRevisionLists"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allPurchaseRevision",
          },
        },
      },
      {
        path: "*",
        redirect: "Articles",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // Sales modules and routes
  {
    path: "/Sales",
    name: "Sales",
    component: () => import("../modules/Sales/views/SalesList"),
    children: [
      {
        path: "PurchasesList",
        component: () => import("../modules/Sales/components/PricesLists"),
        meta: {
          permissions: {
            module: "sales",
            resource: "allPricesLists",
          },
        },
      },
      {
        path: "PricesListsArticles",
        component: () =>
          import("../modules/Sales/components/PricesListsArticles"),
        meta: {
          permissions: {
            module: "sales",
            resource: "allArticlesPrices",
          },
        },
      },
      {
        path: "ClientsLists",
        component: () => import("../modules/Sales/views/AllClientsList"),
        meta: {
          permissions: {
            module: "sales",
            resource: "allClients",
          },
        },
      },
      {
        path: "SalesLists",
        component: () => import("../modules/Sales/views/AllSalesList"),
        permissions: {
          module: "sales",
          resource: "getSales",
        },
      },
      {
        path: "SalesDeliveries",
        component: () => import("../modules/Sales/views/AllDeliveriesList"),
        permissions: {
          module: "sales",
          resource: "getSaleMaterialDeliveries",
        },
      },
      {
        path: "DriversDeliveries",
        component: () =>
          import("../modules/Sales/views/AllDriversDeliveriesList"),
        permissions: {
          module: "sales",
          resource: "allDriverMaterialDelivery",
        },
      },
      {
        path: "DiscountConcepts",
        component: () =>
          import("../modules/Sales/components/DiscountConceptsList"),
      },
      {
        path: "AllPosList",
        component: () => import("../modules/Sales/views/AllPosList"),
        permissions: {
          module: "sales",
          resource: "getPos",
        },
      },
      {
        path: "AllProfessionalServices",
        component: () =>
          import("../modules/Sales/views/AllProfessionalServices"),
      },
      {
        path: "*",
        redirect: "PurchasesList",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // General module and routes
  {
    path: "/General",
    name: "General",
    component: () => import("../modules/Global/views/GeneralList"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "Categories",
        name: "Categories",
        component: () => import("../modules/Global/components/CategoriesList"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allArticles",
          },
        },
      },
      {
        path: "Warehouses",
        component: () =>
          import("../modules/Global/views/WarehouseComponentsContainer"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allWarehouses",
          },
        },
      },
      {
        path: "WarehousesArticles",
        component: () =>
          import("../modules/Global/views/WarehousesArticlesComponents"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allWarehouses",
          },
        },
      },
      {
        path: "WarehousesTransfers",
        component: () =>
          import("../modules/Global/views/WarehouseTransfersComponents"),
        meta: {
          permissions: {
            module: "purchase",
            resource: "allWarehouses",
          },
        },
      },
      {
        path: "PorteCards",
        component: () =>
          import("../modules/Global/views/PorteCardsComponents.vue"),
        meta: {
          permissions: {
            module: "accounting",
            resource: "savePorteCard",
          },
        },
      },
      {
        path: "Vehicules",
        component: () => import("../modules/Global/views/VehiculesComponents"),
        meta: {
          permissions: {
            module: "global",
            resource: "allVehicules",
          },
        },
      },
      {
        path: "ArticlesTransformation",
        component: () =>
          import("../modules/Global/views/ArticlesTransformationComponents"),
        meta: {
          permissions: {
            module: "global",
            resource: "allTransformations",
          },
        },
      },
      {
        path: "*",
        redirect: "Categories",
      },
    ],
  },
  // Treasury module and routes
  {
    path: "/treasury",
    name: "Treasury",
    component: () => import("../modules/Treasury/views/TreasuryView"),
    children: [
      {
        path: "Accounts",
        component: () => import("../modules/Treasury/views/Accounts"),
        meta: {
          permissions: {
            module: "treasury",
            resource: "allAccounts",
          },
        },
      },
      {
        path: "Balances",
        component: () => import("../modules/Treasury/views/Balances"),
      },
      {
        path: "AdvancedPayments",
        component: () => import("../modules/Treasury/views/AdvancedPayments"),
        meta: {
          permissions: {
            module: "treasury",
            resource: "getAdvancedPayments",
          },
        },
      },
      {
        path: "*",
        redirect: "Accounts",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // Accounting module and routes
  {
    path: "/accounting",
    name: "Accounting",
    component: () => import("../modules/Accounting/views/AccountingView"),
    children: [
      {
        path: "Documents",
        component: () => import("../modules/Accounting/views/DocumentsList"),
        meta: {
          permissions: {
            module: "accounting",
            resource: "allSatDocument",
          },
        },
      },
      {
        path: "Reports",
        component: () =>
          import("../modules/Accounting/views/AccountingReports.vue"),
        meta: {
          permissions: {
            module: "accounting",
            resource: "allAccountingReports",
          },
        },
      },
      {
        path: "ContableAccounts",
        component: () =>
          import("../modules/Accounting/views/ContableAccountsList.vue"),
      },
      {
        path: "GlobalInvoices",
        component: () =>
          import("../modules/Accounting/views/GlobalInvoicesLists"),
        meta: {
          requiresAuth: true,
          permissions: {
            module: "accounting",
            resource: "getDailyInvoices",
          },
        },
      },
      {
        path: "*",
        redirect: "Documents",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // Bank module and routes
  {
    path: "/Bank",
    name: "Bank",
    component: () => import("../modules/Bank/views/BankList"),
    children: [
      {
        path: "AllBalancesBank",
        component: () => import("../modules/Bank/views/AccountsComponents"),
        meta: {
          permissions: {
            module: "banks",
            resource: "allAccounts",
          },
        },
      },
      {
        path: "*",
        redirect: "AllBalancesBank",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // Configuration module and routes
  {
    path: "/Configuration",
    name: "Configuration",
    component: () =>
      import("../modules/Configuration/views/ConfigurationMainView"),
    children: [
      {
        path: "CompanyConfiguration",
        component: () =>
          import("../modules/Configuration/views/ConfigurationComponents"),
        meta: {
          permissions: {
            module: "global",
            resource: "allConfiguration",
          },
        },
      },
      {
        path: "*",
        redirect: "CompanyConfiguration",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // Productivity module
  {
    path: "/Calendar",
    name: "Calendar",
    component: () => import("../modules/Productivity/views/Calendar.vue"),
    meta: {
      requiresAuth: true,
      permissions: {
        module: "productivity",
        resource: "allCalendar",
      },
    },
  },
  {
    path: "/PointOfSale",
    name: "PointOfSale",
    component: () => import("../modules/Global/views/PointOfSaleTabs"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Board",
    name: "Board",
    component: () => import("../modules/Boards/views/Boards.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Human resources
  {
    path: "/HumanResources",
    name: "HumanResources",
    component: () =>
      import("../modules/HumanResources/views/HumanResourcesView"),
    children: [
      {
        path: "Personal",
        component: () =>
          import("../modules/HumanResources/views/AllPersonalList"),
        meta: {
          permissions: {
            module: "humanResources",
            resource: "allPersonal",
          },
        },
      },
      {
        path: "Bonds",
        component: () => import("../modules/HumanResources/views/AllBondsList"),
        meta: {
          requiresAuth: true,
          permissions: {
            module: "humanResources",
            resource: "getBonds",
          },
        },
      },
      {
        path: "Comissions",
        component: () =>
          import("../modules/HumanResources/views/AllComissionsList"),
        meta: {},
      },
      {
        path: "*",
        redirect: "Personal",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // Dashboards
  {
    path: "/Dashboards",
    name: "Dashboards",
    component: () => import("../modules/Global/views/DashboardsList"),
    children: [
      {
        path: "Initial",
        component: () =>
          import(
            "../modules/Global/components/initial-dashboard/InitialDashboard"
          ),
      },
      {
        path: "Value",
        component: () => import("../modules/Global/components/CompanyValue"),
      },
      {
        path: "Assets",
        component: () => import("../modules/Global/views/CompanyAssetsLists"),
        meta: {
          permissions: {
            module: "main",
            resource: "getAssets",
          },
        },
      },

      {
        path: "*",
        redirect: "Initial",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  // Analytics
  {
    path: "/Analytics",
    component: () => import("../modules/Global/views/AnalyticsLists"),
    meta: {
      requiresAuth: true,
      permissions: {
        module: "main",
        resource: "allAnalytics",
      },
    },
  },
  // Login
  {
    path: "/Login",
    name: "Login",
    component: () => import("../modules/Global/views/Login.vue"),
    meta: {
      redirectIfAthenticated: true,
    },
  },
  // Logout specific user
  {
    path: "/LogoutSpecificUser",
    name: "LogoutSpecificUser",
    component: () => import("../modules/Global/views/LogoutSpecificUser.vue"),
    meta: {
      redirectIfAthenticated: true,
    },
  },
  //Register
  {
    path: "/Register",
    name: "Register",
    component: () => import("../modules/Global/views/Register.vue"),
    meta: {
      redirectIfAthenticated: true,
    },
  },
  //Payment Success
  {
    path: "/PaymentSuccess",
    name: "PaymentSuccess",
    component: () => import("../modules/Global/views/PaymentSuccess.vue"),
    meta: {
      requiresAuth: true,
      dontRedirect: true,
      dontRedirectPayment: true,
    },
  },
  //Payment Success
  {
    path: "/PaymentCancelled",
    name: "PaymentCancelled",
    component: () => import("../modules/Global/views/PaymentCancelled.vue"),
    meta: {
      requiresAuth: true,
      dontRedirect: true,
      dontRedirectPayment: true,
    },
  },
  // Validate email
  {
    path: "/ValidateEmail",
    name: "ValidateEmail",
    component: () => import("../modules/Global/views/ValidateEmail.vue"),
    meta: {
      requiresAuth: false,
      dontRedirect: true,
      dontRedirectPayment: true,
    },
  },
  // Not validated email
  {
    path: "/EmailNotValidated",
    name: "EmailNotValidated",
    component: () => import("../modules/Global/views/EmailNotValidated.vue"),
    meta: {
      requiresAuth: true,
      dontRedirect: true,
      dontRedirectPayment: true,
    },
  },
  // No payment
  {
    path: "/NoPayment",
    name: "NoPayment",
    component: () => import("../modules/Global/views/NoPayment.vue"),
    meta: {
      requiresAuth: true,
      dontRedirect: true,
      dontRedirectPayment: true,
    },
  },
  // Password recovery
  {
    path: "/PasswordRecovery",
    name: "PasswordRecovery",
    component: () => import("../modules/Global/views/PasswordRecovery.vue"),
    meta: {
      redirectIfAthenticated: true,
    },
  },
  // Reset password
  {
    path: "/ResetPassword",
    name: "ResetPassword",
    component: () => import("../modules/Global/views/ResetPassword.vue"),
    meta: {
      redirectIfAthenticated: true,
    },
  },
  // Not found
  {
    path: "*",
    redirect: "/Dashboards/Initial",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
  routes,
});

/**
 * @desc Router guard to avoid users in pages that need authorization
 */
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let authenticated = true;
    let permissions = true;
    let emailValidated = true;
    let paymentValidated = true;

    // Load localstorage information and validate token, if application was not loaded
    if (store.getters.LOADED === false) {
      let result = await store.dispatch("CHECKUSER");
      if (result !== "Success") {
        authenticated = false;
      }
      await store.dispatch("GETCOMPANYINFORMATION");
    }

    // Check if user is logged in
    if (!store.getters.LOGGED_IN) {
      authenticated = false;
    }

    // Check for email validation
    if (!store.getters.USER.validatedEmail) {
      emailValidated = false;
    }

    // Check for payment confirmed
    if (!store.getters.COMPANY_INFORMATION.suscribedPayment) {
      paymentValidated = false;
    }

    // Check for permissions
    if (to.meta.permissions) {
      if (
        !store.getters.USER.permissions ||
        !store.getters.USER.permissions.permissions[to.meta.permissions.module][
          to.meta.permissions.resource
        ] ||
        store.getters.USER.permissions.permissions[to.meta.permissions.module][
          to.meta.permissions.resource
        ] !== "S"
      ) {
        permissions = false;
      }
    }

    // If the user is authenticated and has the right permission go next if not go to login
    if (!authenticated) {
      next("/Login");
      return;
    }

    // If the company has no payment method
    if (!paymentValidated && !to.meta.dontRedirectPayment) {
      next("/NoPayment");
      return;
    }

    // If the user email is not validated
    if (!emailValidated && !to.meta.dontRedirect) {
      next("/EmailNotValidated");
      return;
    }

    if (!permissions) {
      next("/Dashboards/Initial");
      return;
    }

    next();
    return;
  } else if (to.matched.some((record) => record.meta.redirectIfAthenticated)) {
    // Load localstorage information and validate token, if application was not loaded
    if (store.getters.LOADED === false) {
      let result = await store.dispatch("CHECKUSER");
      if (result === "Success") {
        next("/Dashboards/Initial");
        return;
      }
    }

    // Check if user is logged in
    if (store.getters.LOGGED_IN) {
      next("/Dashboards/Initial");
      return;
    }

    next();
    return;
  } else {
    next();
    return;
  }
});

router.afterEach(() => {
  const allModals = document.querySelectorAll(".modal");
  for (const singleModal of allModals) {
    singleModal.__vue__.$vnode.context.close();
  }
});

export default router;
