let state = {
  lastPaymentNumber: 0,
  lastExpenseNumber: 0,
  expenses: [],
  incomesSales: [],
  incomes: [],
  advancedPayments: [],
  payments: [],
};

export default state;
