let getters = {
  INCOMEDOCUMENTS: (state) => {
    return state.incomeDocuments;
  },
  PAYMENTDOCUMENTS: (state) => {
    return state.paymentDocuments;
  },
  SALARYDOCUMENTS: (state) => {
    return state.salaryDocuments;
  },
  EXPENSEDOCUMENTS: (state) => {
    return state.expenseDocuments;
  },
  TRANSFERDOCUMENTS: (state) => {
    return state.transferDocuments;
  },
  INVOICES: (state) => {
    return state.invoices;
  },
  CREDITNOTESINVOICES: (state) => {
    return state.creditNotesInvoices;
  },
  PAYMENTINVOICES: (state) => {
    return state.paymentInvoices;
  },
  PORTECARD: (state) => {
    return state.porteCards;
  },
  WEIGHTUNITS: (state) => {
    return state.weightUnits;
  },
  DANGEROUSMATERIALS: (state) => {
    return state.dangerousMaterials;
  },
  PACKAGINGS: (state) => {
    return state.packagings;
  },
  TRANSPORTATIONPERMISSIONS: (state) => {
    return state.transportationPermissions;
  },
  TRANSPORTATIONCONFIGURATION: (state) => {
    return state.transportationConfiguration;
  },
  TRAILERTYPES: (state) => {
    return state.trailerTypes;
  },
  DAILYINVOICES: (state) => {
    return state.dailyInvoices;
  },
  SALESXMLDIFFERENCES: (state) => {
    return state.salesXMLDifferences;
  },
  CONTABLEACCOUNTS: (state) => {
    return state.contableAccounts;
  },
  CONTABLEGUIDELINES: (state) => {
    return state.contableGuidelines;
  },
};

export default getters;
