let getters = {
  USER: (state) => {
    return state.user;
  },
  LOGGED_IN: (state) => {
    return state.loggedIn;
  },
  LOADED: (state) => {
    return state.loaded;
  },
  LOADINGMESSAGE: (state) => {
    return state.loadingMessage;
  },
  LOADING: (state) => {
    return state.loading;
  },
  COMPANY_INFORMATION: (state) => {
    return state.companyInformation;
  },
  SAT_LINK: (state) => {
    return state.satLink;
  },
  MEASURES: (state) => {
    return state.measures;
  },
  NEXTPAYMENTDATE: (state) => {
    return state.nextPaymentDate;
  },
  COMPANYTOTALVALUE: (state) => {
    return state.companyTotalValue;
  },
  NOTIFICATIONS: (state) => {
    return state.notifications;
  },
  COMPANY_NAMES: (state) => {
    return state.companyNames;
  },
  CAUSECODES: (state) => {
    return state.causeCodes;
  },
};

export default getters;
