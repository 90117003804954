<template>
  <b-input
    :placeholder="placeholder"
    expanded
    :icon="icon"
    v-model="displayValue"
    :disabled="disabled"
    @blur="(event) => sendData(event)"
    :required="required"
  >
  </b-input>
</template>

<script>
export default {
  name: "MoneyInput",
  components: {},
  props: [
    "value",
    "placeholder",
    "icon",
    "required",
    "digits",
    "currency",
    "disabled",
  ],
  data() {
    return {};
  },
  methods: {
    sendData(event) {
      let newValue = event.target.value;
      newValue = newValue.replace("$", "");
      newValue = newValue.replace(new RegExp(",", "g"), "");
      newValue = parseFloat(newValue);
      // Ensure that it is not NaN
      if (isNaN(newValue)) {
        newValue = 0;
      }
      this.$emit("input", newValue);
    },
  },
  computed: {
    // Format the value received in money depending on the currenct and digits passed as props
    displayValue: {
      get: function () {
        let formatedValue = this.value;
        switch (this.currency) {
          case "MXN":
            if (!this.value) {
              formatedValue = "$ 0";
              return formatedValue;
            }
            formatedValue = new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              minimumFractionDigits: 2,
              maximumFractionDigits: this.digits,
            }).format(this.value);
            break;
          default:
            break;
        }
        return formatedValue;
      },
      set: function (modifiedValue) {
        return modifiedValue;
      },
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
