const serverUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.coconutcontrol.com"
    : "https://api-dev.coconutcontrol.com";

const stripeKey =
  process.env.NODE_ENV === "production"
    ? "pk_live_51KarqOJNdvxtIdWZIyfEysHgfxAQMPH2iYcaYzT1NjtYptO7fyd9keVl9GCrLvgr5cxQFj0fr18HXIv4Dy0WGC0s00bGvfN0hi"
    : "pk_test_51KarqOJNdvxtIdWZru9ZIqAv2V4hZgC9D5kSlf3n7MDSm1dk5GCfgeNzDf79IwA6qQN66zbw41e0WbgkvSRf5nst00ZRFZxuzy";

const invoicePlanMoth =
  process.env.NODE_ENV === "production"
    ? "price_1MvYbtJNdvxtIdWZZEdnpEy3"
    : "price_1MvYcTJNdvxtIdWZb1S3n85q";

const basicPlanMonth =
  process.env.NODE_ENV === "production"
    ? "price_1MvSpPJNdvxtIdWZeHSdbx0H"
    : "price_1L9BllJNdvxtIdWZddu54kT8";

const profesionalPlanMonth =
  process.env.NODE_ENV === "production"
    ? "price_1LJQ7HJNdvxtIdWZnXMLt5Mg"
    : "price_1L9BoVJNdvxtIdWZ5rIHxHnh";

const proPlusPlanMonth =
  process.env.NODE_ENV === "production"
    ? "price_1LJQ7fJNdvxtIdWZ3v2sjsEc"
    : "price_1L9BqwJNdvxtIdWZC3jhZFTv";

const masterPlanMonth =
  process.env.NODE_ENV === "production"
    ? "price_1LJQ8FJNdvxtIdWZkEGLVTjo"
    : "price_1L9BuAJNdvxtIdWZumxT3Bu3";

const basicServicesPlanMonth =
  process.env.NODE_ENV === "production"
    ? "price_1MpoDlJNdvxtIdWZMEIktCMO"
    : "price_1MpoFvJNdvxtIdWZgMzCm1Yd";

const proPlusServicesPlanMonth =
  process.env.NODE_ENV === "production"
    ? "price_1MpoEMJNdvxtIdWZgKXGMKk3"
    : "price_1MpoGUJNdvxtIdWZHu6tdwYC";

const paymentTypeEfectivo = "626b08aff4fd2b4c411c2d1a";
const paymentTypeDebitCard = "626b08b0f4fd2b4c411c2d2b";
const paymentTypeCreditCard = "626b08aff4fd2b4c411c2d1d";
const paymentTypePorDefinir = "626b08b1f4fd2b4c411c2d2d";

const cfdiUsePorDefinir = "622d3705849d2c190347813c";
const cfdiUseDevoluciones = "622d3704849d2c1903478128";
const regimenSinEfecto = "622d3706849d2c1903478148";
const regimenPersonaMoral = "622d3705849d2c190347813d";
const regimenResico = "622d3707849d2c190347814f";
const regimenPersonaFisica = "622d3706849d2c1903478145";

export {
  serverUrl,
  stripeKey,
  invoicePlanMoth,
  basicPlanMonth,
  profesionalPlanMonth,
  proPlusPlanMonth,
  masterPlanMonth,
  basicServicesPlanMonth,
  proPlusServicesPlanMonth,
  paymentTypeEfectivo,
  paymentTypeDebitCard,
  paymentTypeCreditCard,
  paymentTypePorDefinir,
  cfdiUsePorDefinir,
  regimenSinEfecto,
  cfdiUseDevoluciones,
  regimenPersonaMoral,
  regimenResico,
  regimenPersonaFisica,
};
