let mutations = {
  RESTORE_HUMAN_RESOURCES_STATES: (state) => {
    Object.assign(state, {
      //Array with all the employees
      employees: [],
      //Organization board, employees of the company
      lastOrganizationBoardNumber: 1,
      organizationBoard: {
        id: "1",
        name: "Inicia aqui",
        title: "",
        children: [],
        error: false,
      },
      bonds: [],
      buyComissions: [],
      sellComissions: [],
      currentPersonal: null,
    });
  },
  SET_ORGANIZATIONBOARD: (state, payload) => {
    if (payload.organizationBoard) {
      state.organizationBoard = JSON.parse(payload.organizationBoard);
    }
    state.lastOrganizationBoardNumber = payload.lastOrganizationBoardNumber;
  },
  RESTART_ORGANIZATION_BOARD: (state) => {
    state.organizationBoard = {
      id: "1",
      name: "",
      title: "",
      children: [],
      error: false,
    };
    state.lastOrganizationBoardNumber = 1;
  },
  EDIT_ORGANIZATIONBOARD: (state, payload) => {
    if (payload._id.organizationBoard) {
      state.organizationBoard = JSON.parse(payload._id.organizationBoard);
    }
    state.lastOrganizationBoardNumber = payload._id.lastOrganizationBoardNumber;
  },
  ADD_EMPLOYEE: (state, payload) => {
    const currentEmployees = [...state.employees];
    currentEmployees.push(payload._id);
    state.employees = currentEmployees;
  },
  EDIT_PERSONAL: (state, payload) => {
    const currentEmployees = [...state.employees];
    for (const [
      singleEmployeeIndex,
      singleEmployee,
    ] of currentEmployees.entries()) {
      if (singleEmployee._id === payload._id._id) {
        currentEmployees[singleEmployeeIndex] = payload._id;
      }
    }
    state.employees = currentEmployees;
  },
  DELETE_EMPLOYEES: (state, payload) => {
    // Current employees in the state
    let currentEmployees = [...state.employees];
    // Move through all the employees and find the one that matches the id and set it to the new object
    for (const [employeeIndex, employee] of currentEmployees.entries()) {
      for (const singleDeleteObject of payload._id.objectsDeleted) {
        if (employee._id === singleDeleteObject) {
          currentEmployees.splice(employeeIndex, 1);
        }
      }
    }
    state.employees = currentEmployees;
  },
  SET_EMPLOYEES: (state, payload) => {
    state.employees = payload;
  },
  SET_BONDS: (state, payload) => {
    state.bonds = payload;
  },
  ADD_BOND: (state, payload) => {
    const currentBonds = [...state.bonds];
    currentBonds.push(payload._id);
    state.bonds = currentBonds;
  },
  EDIT_BOND: (state, payload) => {
    const currentBonds = [...state.bonds];
    for (const [singleBondIndex, singleBond] of currentBonds.entries()) {
      if (singleBond._id === payload._id._id) {
        currentBonds[singleBondIndex] = payload._id;
      }
    }
    state.bonds = currentBonds;
  },
  DELETE_BONDS: (state, payload) => {
    // Current bonds in the state
    const currentBonds = [...state.bonds];
    // Move through all the bonds and find the one that matches the id and set it to the new object
    for (const [bondIndex, bond] of currentBonds.entries()) {
      for (const singleDeleteObject of payload._id.objectsDeleted) {
        if (bond._id === singleDeleteObject) {
          currentBonds.splice(bondIndex, 1);
        }
      }
    }
    state.bonds = currentBonds;
  },
  DISABLE_BOND: (state, payload) => {
    const currentBonds = [...state.bonds];
    for (const [singleBondIndex, singleBond] of currentBonds.entries()) {
      if (singleBond._id === payload._id.bond) {
        currentBonds[singleBondIndex].STATUS = "DE";
      }
    }
    state.bonds = currentBonds;
  },
  ACTIVATE_BOND: (state, payload) => {
    const currentBonds = [...state.bonds];
    for (const [singleBondIndex, singleBond] of currentBonds.entries()) {
      if (singleBond._id === payload._id.bond) {
        currentBonds[singleBondIndex].STATUS = "AC";
      }
    }
    state.bonds = currentBonds;
  },
  SET_COMISSIONS: (state, payload) => {
    if (payload.type === 0) {
      state.buyComissions = payload.comissions;
    } else if (payload.type === 1) {
      state.sellComissions = payload.comissions;
    }
  },
  ADD_EMPLOYEE_INVENTORY: (state, payload) => {
    const currentEmployeeInventory = [...state.employeesInventory];
    currentEmployeeInventory.push(payload._id);
    state.employeesInventory = currentEmployeeInventory;
  },
  EDIT_EMPLOYEE_INVENTORY: (state, payload) => {
    const currentEmployeeInventory = [...state.employeesInventory];
    for (const [
      singleEmployeeInventoryIndex,
      singleEmployeeInventory,
    ] of currentEmployeeInventory.entries()) {
      if (singleEmployeeInventory._id === payload._id._id) {
        currentEmployeeInventory[singleEmployeeInventoryIndex] = payload._id;
      }
    }
    state.employeesInventory = currentEmployeeInventory;
  },
  DELETE_EMPLOYEES_INVENTORY: (state, payload) => {
    // Current employees in the state
    let currentEmployeeInventory = [...state.employeesInventory];

    for (const [
      singleEmployeeInventoryIndex,
      singleEmployeeInventory,
    ] of currentEmployeeInventory.entries()) {
      for (const singleDeleteObject of payload._id.objectsDeleted) {
        if (singleEmployeeInventory._id === singleDeleteObject) {
          currentEmployeeInventory.splice(singleEmployeeInventoryIndex, 1);
        }
      }
    }

    state.employeesInventory = currentEmployeeInventory;
  },
  SET_EMPLOYEES_INVENTORY: (state, payload) => {
    state.employeesInventory = payload;
  },
  EDIT_PERSONAL_LOAN_PAYMENTS: (state, payload) => {
    const currentEmployees = [...state.employees];
    for (const [
      singleEmployeeIndex,
      singleEmployee,
    ] of currentEmployees.entries()) {
      if (singleEmployee._id === payload._id.employeeId) {
        for (const [
          singleLoanId,
          singleLoan,
        ] of singleEmployee.PRESTAMOS.entries()) {
          if (singleLoan._id === payload._id.loadId) {
            currentEmployees[singleEmployeeIndex].CANTIDAD_PAGADA +=
              payload._id.payment.CANTIDAD;

            currentEmployees[singleEmployeeIndex].PRESTAMOS[
              singleLoanId
            ].PAGOS.push(payload._id.payment);
            currentEmployees[singleEmployeeIndex].PRESTAMOS[
              singleLoanId
            ].CANTIDAD_PAGADA += payload._id.payment.CANTIDAD;

            if (
              payload._id.loan.CANTIDAD_PAGADA >=
              payload._id.loan.CANTIDAD_PRESTADA
            ) {
              currentEmployees[singleEmployeeIndex].CANTIDAD_PRESTADA +=
                payload._id.loan.CANTIDAD_PRESTADA;
              currentEmployees[singleEmployeeIndex].CANTIDAD_PAGADA +=
                payload._id.loan.CANTIDAD_PAGADA;

              currentEmployees[singleEmployeeIndex].PRESTAMOS[
                singleLoanId
              ].STATUS = "PA";
            }
          }
        }
      }
    }
    state.employees = currentEmployees;
  },
  EDIT_PERSONAL_LOAN_STATUS: (state, payload) => {
    const currentEmployees = [...state.employees];
    for (const [
      singleEmployeeIndex,
      singleEmployee,
    ] of currentEmployees.entries()) {
      if (singleEmployee._id === payload._id.employeeId) {
        for (const [
          singleLoanId,
          singleLoan,
        ] of singleEmployee.PRESTAMOS.entries()) {
          if (singleLoan._id === payload._id.loadId) {
            currentEmployees[singleEmployeeIndex].CANTIDAD_PRESTADA =
              payload._id.loan.CANTIDAD_PRESTADA;
            currentEmployees[singleEmployeeIndex].CANTIDAD_PAGADA =
              payload._id.loan.CANTIDAD_PAGADA;

            currentEmployees[singleEmployeeIndex].PRESTAMOS[
              singleLoanId
            ].STATUS = "PA";
            currentEmployees[singleEmployeeIndex].PRESTAMOS[
              singleLoanId
            ].CANTIDAD_PAGADA = payload._id.loan.CANTIDAD_PRESTADA;
          }
        }
      }
    }
    state.employees = currentEmployees;
  },
  EDIT_PERSONAL_EXTRA_HOURS: (state, payload) => {
    const currentEmployees = [...state.employees];
    for (const [
      singleEmployeeIndex,
      singleEmployee,
    ] of currentEmployees.entries()) {
      if (singleEmployee._id.toString() === payload._id.employeeId.toString()) {
        if (!currentEmployees[singleEmployeeIndex].HORAS_EXTRA) {
          currentEmployees[singleEmployeeIndex].HORAS_EXTRA = [
            payload._id.extraHours,
          ];
        } else {
          currentEmployees[singleEmployeeIndex].HORAS_EXTRA.push(
            payload._id.extraHours
          );
        }
      }
    }
    state.employees = currentEmployees;
  },
  DELETE_PERSONAL_EXTRA_HOURS: (state, payload) => {
    // Current employees in the state
    let currentEmployees = [...state.employees];

    for (const [
      singleEmployeeInventoryIndex,
      singleEmployeeInventory,
    ] of currentEmployees.entries()) {
      if (singleEmployeeInventory._id === payload._id.employeeId) {
        for (const [
          singleExtraHourIndex,
          singleExtraHour,
        ] of singleEmployeeInventory.HORAS_EXTRA.entries()) {
          if (
            singleExtraHour._id.toString() ===
            payload._id.extraHourId.toString()
          ) {
            currentEmployees[singleEmployeeInventoryIndex].HORAS_EXTRA.splice(
              singleExtraHourIndex,
              1
            );
          }
        }
      }
    }

    state.employees = currentEmployees;
  },
  EDIT_PERSONAL_LATE_HOURS: (state, payload) => {
    const currentEmployees = [...state.employees];

    for (const [
      singleEmployeeIndex,
      singleEmployee,
    ] of currentEmployees.entries()) {
      if (singleEmployee._id.toString() === payload._id.employeeId.toString()) {
        if (!currentEmployees[singleEmployeeIndex].HORAS_RETARDO) {
          currentEmployees[singleEmployeeIndex].HORAS_RETARDO = [
            payload._id.lateHours,
          ];
        } else {
          currentEmployees[singleEmployeeIndex].HORAS_RETARDO.push(
            payload._id.lateHours
          );
        }
      }
    }
    state.employees = currentEmployees;
  },
  DELETE_PERSONAL_LATE_HOURS: (state, payload) => {
    // Current employees in the state
    let currentEmployees = [...state.employees];

    for (const [
      singleEmployeeInventoryIndex,
      singleEmployeeInventory,
    ] of currentEmployees.entries()) {
      if (singleEmployeeInventory._id === payload._id.employeeId) {
        for (const [
          singleExtraHourIndex,
          singleExtraHour,
        ] of singleEmployeeInventory.HORAS_RETARDO.entries()) {
          if (
            singleExtraHour._id.toString() === payload._id.lateHourId.toString()
          ) {
            currentEmployees[singleEmployeeInventoryIndex].HORAS_RETARDO.splice(
              singleExtraHourIndex,
              1
            );
          }
        }
      }
    }

    state.employees = currentEmployees;
  },
  EDIT_PERSONAL_MISSING_HOURS: (state, payload) => {
    const currentEmployees = [...state.employees];

    for (const [
      singleEmployeeIndex,
      singleEmployee,
    ] of currentEmployees.entries()) {
      if (singleEmployee._id.toString() === payload._id.employeeId.toString()) {
        if (!currentEmployees[singleEmployeeIndex].HORAS_PERDIDAS) {
          currentEmployees[singleEmployeeIndex].HORAS_PERDIDAS = [
            payload._id.missingHours,
          ];
        } else {
          currentEmployees[singleEmployeeIndex].HORAS_PERDIDAS.push(
            payload._id.missingHours
          );
        }
      }
    }
    state.employees = currentEmployees;
  },
  DELETE_PERSONAL_MISSING_HOURS: (state, payload) => {
    // Current employees in the state
    let currentEmployees = [...state.employees];

    for (const [
      singleEmployeeInventoryIndex,
      singleEmployeeInventory,
    ] of currentEmployees.entries()) {
      if (singleEmployeeInventory._id === payload._id.employeeId) {
        for (const [
          singleExtraHourIndex,
          singleExtraHour,
        ] of singleEmployeeInventory.HORAS_PERDIDAS.entries()) {
          if (
            singleExtraHour._id.toString() ===
            payload._id.missingHourId.toString()
          ) {
            currentEmployees[
              singleEmployeeInventoryIndex
            ].HORAS_PERDIDAS.splice(singleExtraHourIndex, 1);
          }
        }
      }
    }

    state.employees = currentEmployees;
  },

  SAVE_VACATION: (state, payload) => {
    const currentVacations = [...state.vacations];
    currentVacations.push(payload._id);
    state.vacations = currentVacations;
  },
  SET_VACATIONS: (state, payload) => {
    state.vacations = payload;
  },
  DELETE_VACATION: (state, payload) => {
    const currentVacations = [...state.vacations];

    for (const [
      singleVacationIndex,
      singleVacation,
    ] of currentVacations.entries()) {
      if (singleVacation._id === payload._id._id) {
        currentVacations[singleVacationIndex].STATUS = "C";
        break;
      }
    }

    state.vacations = currentVacations;
  },
  SET_CURRENT_EMPLOYEE: (state, payload) => {
    state.currentEmployee = payload;
  },
};

export default mutations;
