let state = {
  pricesLists: [],
  clients: [],
  lastSaleNumber: 1,
  quotes: [],
  sales: [],
  porofesionalServicesSales: [],
  lastClarificationNumber: 1,
  discountConcepts: [],
  lastIncomeNumber: 1,
  satCfdiUses: [],
  satRegimenes: [],
  commercialBusiness: [],
  paymentTypes: [],
  pos: [],
  secrets: [],
  clientsUnlocks: [],
  warehousesUnlocks: [],
  services: [],
  clientsCreditGroups: [],
};

export default state;
