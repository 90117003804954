let getters = {
  PRICES_LISTS: (state) => {
    return state.pricesLists;
  },
  CLIENTS: (state) => {
    return state.clients;
  },
  LASTSALENUMBER: (state) => {
    return state.lastSaleNumber;
  },
  QUOTES: (state) => {
    return state.quotes;
  },
  SALES: (state) => {
    return state.sales;
  },
  LASTCLARIFICATIONNUMBER: (state) => {
    return state.lastClarificationNumber;
  },
  LASTCREDITNOTENUMBER: (state) => {
    return state.lastCreditNoteNumber;
  },
  DISCOUNTCONCEPTS: (state) => {
    return state.discountConcepts;
  },
  LASTINCOMENUMBER: (state) => {
    return state.lastIncomeNumber;
  },
  SATCFDIUSES: (state) => {
    return state.satCfdiUses;
  },
  SATREGIMENES: (state) => {
    return state.satRegimenes;
  },
  COMMERCIALBUSINESS: (state) => {
    return state.commercialBusiness;
  },
  PAYMENTTYPES: (state) => {
    return state.paymentTypes;
  },
  POS: (state) => {
    return state.pos;
  },
  SECRETS: (state) => {
    return state.secrets;
  },
  CLIENTSUNLOCKS: (state) => {
    return state.clientsUnlocks;
  },
  WAREHOUSESUNLOCKS: (state) => {
    return state.warehousesUnlocks;
  },
  PROFESSIONALSERVICESSALES: (state) => {
    return state.porofesionalServicesSales;
  },
  SERVICES: (state) => {
    return state.services;
  },
  CLIENTSCREDITGROUPS: (state) => {
    return state.clientsCreditGroups;
  },
};

export default getters;
