import {
  saveAction,
  getAction,
  genericAction,
  genericActionNoMutation,
} from "@/utils/store-fns";
import { serverUrl } from "@/utils/env-variables-fns";

let actions = {
  CHANGENEWHINT: (context, payload) => {
    context.commit("SET_HINT", payload);
  },
  CHANGEACTIVE: (context, payload) => {
    context.commit("SET_ACTIVE", payload);
  },
  SAVEBRAND: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-brand`,
        "SAVE_BRAND"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETBRANDS: (context) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/global/get-brands`, "SET_BRANDS").then(
        (response) => {
          resolve(response);
        }
      );
    });
  },
  /**
   *
   * @desc Edits a brand in the database
   */
  EDITBRAND: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/edit-brand`,
        "EDIT_BRAND"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEMAINCATEGORY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-main-category`,
        "SAVE_CATEGORY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCATEGORIES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-categories`,
        "SET_CATEGORIES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITMAINCATEGORY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/edit-category`,
        "EDIT_CATEGORY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEMAINSCATEGORY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-main-scategory`,
        payload.saveAction
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETSCATEGORIES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-scategories`,
        "SET_SCATEGORIES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITSCATEGORY: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/edit-scategory`,
        "EDIT_SCATEGORY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETALLSCATEGORIES: (context) => {
    return new Promise((resolve) => {
      (async () => {
        try {
          let responseSCategory = await getAction(
            context,
            `${serverUrl}/global/get-scategories`,
            "SET_SCATEGORIES",
            { type: 1 }
          );
          let responseSSCategory = await getAction(
            context,
            `${serverUrl}/global/get-scategories`,
            "SET_SSCATEGORIES",
            { type: 2 }
          );
          let responseSSSCategory = await getAction(
            context,
            `${serverUrl}/global/get-scategories`,
            "SET_SSSCATEGORIES",
            { type: 3 }
          );
          resolve({
            responseSCategory,
            responseSSCategory,
            responseSSSCategory,
          });
        } catch (err) {
          resolve(err);
        }
      })();
    });
  },
  SENDPDFFILE: (context, payload) => {
    return new Promise((resolve) => {
      genericAction(
        context,
        payload,
        `${serverUrl}/global/send-email-purchase-order`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDPDFFILESALEQUOTE: (context, payload) => {
    return new Promise((resolve) => {
      genericAction(
        context,
        payload,
        `${serverUrl}/global/send-email-sale-quote`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDPDFFILEPROFESSIONALSERVICESSALES: (context, payload) => {
    return new Promise((resolve) => {
      genericAction(
        context,
        payload,
        `${serverUrl}/global/send-email-professional-services-sale`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDPDFFILEMATERIALDELIVERY: (context, payload) => {
    return new Promise((resolve) => {
      genericAction(
        context,
        payload,
        `${serverUrl}/global/send-material-delivery`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SENDPDFFILEMATERIALMOVEMENT: (context, payload) => {
    return new Promise((resolve) => {
      genericAction(
        context,
        payload,
        `${serverUrl}/global/send-material-movement`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETCOMPANYEMAILS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-comanies-emails`,
        "SET_COMPANIES_EMAILS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVECOMPANYEMAIL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-company-email`,
        "SAVE_COMPANY_EMAIL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  DELETECOMPANYEMAIL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/delete-company-email`,
        "DELETE_COMPANY_EMAIL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETPERMISSIONS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-permissions`,
        "SET_PERMISSIONS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEPERMISSIONS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-permissions`,
        "SAVE_PERMISSIONS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  UPDATEPERMISSIONS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/update-permissions`,
        "UPDATE_PERMISSIONS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  DELETEPERMISSIONS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/delete-permissions`,
        "DELETE_PERMISSIONS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETUSERS: (context) => {
    return new Promise((resolve) => {
      getAction(context, `${serverUrl}/global/get-users`, "SET_USERS").then(
        (response) => {
          resolve(response);
        }
      );
    });
  },
  SAVEUSER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-user`,
        "SAVE_USER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Activates a user
   */
  ACTIVATEUSER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/activate-user`,
        "ACTIVATE_USER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Disables a user
   */
  DISABLEUSER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/disable-user`,
        "DISABLE_USER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  UPDATEUSER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/update-user`,
        "UPDATE_USER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  DELETEUSERS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/delete-user`,
        "DELETE_USER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all vehicules from the company in the database
   * @returns an array of vehicules
   */
  GETVEHICULES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-vehicules`,
        "SET_VEHICULES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new vehicule in the database
   * @returns an object with the new vehicule
   */
  SAVEVEHICULE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-vehicule`,
        "SAVE_VEHICULES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITVEHICULE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/edit-vehicule`,
        "EDIT_VEHICULE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETFORMULAS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-formulas`,
        "SET_FORMULAS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEFORMULA: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-formula`,
        "SAVE_FORMULA"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  EDITFORMULA: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/edit-formula`,
        "EDIT_FORMULA"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  DELETEFORMULA: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/delete-formula`,
        "DELETE_FORMULA"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETARTICLESTRANSFORMATION: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-articles-transformation`,
        "SET_ARTICLES_TRANSFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEARTICLETRANSFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-article-transformation`,
        "SAVE_ARTICLE_TRANSFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELARTICLETRANSFORMATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/cancel-article-transformation`,
        "CANCEL_ARTICLE_TRANSFORMATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new asset in the database
   */
  SAVEASSET: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/save-company-asset`,
        "SAVE_ASSET"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all assets from the database
   */
  GETASSETS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/global/get-company-assets`,
        "SET_ASSETS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an asset in the database
   */
  EDITASSET: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/edit-company-asset`,
        "EDIT_ASSET"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes an asset in the database
   */
  DELETEASSET: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/global/delete-company-asset`,
        "DELETE_ASSET"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  TRANSFORMXML2JSON: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/global/transform-xml-2-json`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
