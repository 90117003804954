let getters = {
  LASTPAYMENTNUMBER: (state) => {
    return state.lastPaymentNumber;
  },
  LASTEXPENSENUMBER: (state) => {
    return state.lastExpenseNumber;
  },
  EXPENSES: (state) => {
    return state.expenses;
  },
  INCOMES: (state) => {
    return state.incomes;
  },
  ADVANCEDPAYMENTS: (state) => {
    return state.advancedPayments;
  },
  PAYMENTS: (state) => {
    return state.payments;
  },
};

export default getters;
