let getters = {
  LASTORGANIZATIONBOARDNUMBER: (state) => {
    return state.lastOrganizationBoardNumber;
  },
  ORGANIZATIONBOARD: (state) => {
    return state.organizationBoard;
  },
  EMPLOYEES: (state) => {
    return state.employees;
  },
  BONDS: (state) => {
    return state.bonds;
  },
  BUYCOMISSIONS: (state) => {
    return state.buyComissions;
  },
  SELLCOMISSIONS: (state) => {
    return state.sellComissions;
  },
  EMPLOYEESINVENTORY: (state) => {
    return state.employeesInventory;
  },
  VACATIONS: (state) => {
    return state.vacations;
  },
  CURRENTEMPLOYEE: (state) => {
    return state.currentEmployee;
  },
};

export default getters;
