/* eslint-disable no-console */
import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // App ready
    },
    registered() {
      // Registered
    },
    cached() {
      // Cached
    },
    updatefound() {
      // Downloading
    },
    updated(registration) {
      let confirmationResult = confirm(
        "Nuevo contenido disponible, ¿Desea refrescar la página?"
      );
      if (confirmationResult)
        registration.waiting.postMessage({ action: "skipWaiting" });
    },
    offline() {
      // Offline
    },
    error(error) {
      // Error
    },
  });

  let refreshing;
  navigator.serviceWorker.addEventListener("controllerchange", (e) => {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
  });
}
